import * as R from 'ramda';
import React from 'react';
import { connect } from 'react-redux';
import { compose, withHandlers } from 'react-recompose';
// components
import { openModal, closeModal } from '../components/modal/actions';
// helpers/constants
import * as G from '../helpers';
import * as GC from '../constants';
// forms
import ChargeCommentsForm from '../forms/forms/charge-comments-form';
//////////////////////////////////////////////////

const withChargeComments = (type: string) => compose(
  connect(
    null,
    { openModal, closeModal },
  ),
  withHandlers({
    handleOpenChargeComment: (props: Object) => (charge: Object, value: string) => {
      const comments = R.or(R.prop(GC.FIELD_CHARGE_COMMENTS, charge), value);

      const { openModal, closeModal, handleAddChargeComment } = props;

      const initialValues = {
        [GC.FIELD_CHARGE_COMMENTS]: comments,
      };

      const modal = {
        p: 15,
        component: (
          <ChargeCommentsForm
            type={type}
            charge={charge}
            closeModal={closeModal}
            initialValues={initialValues}
            fieldsGroupWidth='min-content'
            handleAddChargeComment={handleAddChargeComment}
          />
        ),

        options: {
          width: 330,
          height: 'auto',
          maxHeight: '95vh',
          title: G.getWindowLocale('titles:comments', 'Comments'),
        },
      };

      openModal(modal);
    },
  }),
);

export default withChargeComments;
