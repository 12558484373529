import * as R from 'ramda';
// theme
import Theme from '../theme';
// helpers
import { ifElse } from './helpers';
//////////////////////////////////////////////////

const getTheme = (path: string, orValue: string) => R.pathOr(orValue || '', R.split('.', path), Theme);

const getThemeByCond = (condValue: any, path1: string, path2: string) => getTheme(
  ifElse(condValue, path1, path2),
);

const getThemeColor = (
  key: string = '',
  defaultValue: string = '#930000',
) => R.pathOr(R.or(defaultValue, ''), R.split('.', key), Theme);

const isThemeSecondVersion = (version: any) => R.equals(version, 2);

export {
  getTheme,
  getThemeColor,
  getThemeByCond,
  isThemeSecondVersion,
};
