import { compose, withState, withPropsOnChange } from 'react-recompose';
//////////////////////////////////////////////////

export const withRerenderComponent = (propsToCheck: Array) => compose(
  withState('showComponent', 'setShowComponent', true),
  withPropsOnChange(
    propsToCheck,
    (props: Object) => {
      props.setShowComponent(false);
      setTimeout(() => {
        props.setShowComponent(true);
      }, 0);
    },
  ),
);
