import { datadogLogs } from '@datadog/browser-logs';
// helpers
import { isDevelopment } from './env';
import { handleException } from './ajax';
import { getAmousCurrentBranchFromWindow, getAmousCurrentUserLoginIdFromWindow } from './window';
//////////////////////////////////////////////////

// log system
const logToSystem = (message: string, attributes: object, status: string = 'info') => {
  try {
    if (isDevelopment) {
      console.log('//////////////////////////////////////////////////-logToSystem-message', message); // eslint-disable-line
    }

    const user = getAmousCurrentUserLoginIdFromWindow();

    const { parentName, enterpriseName } = getAmousCurrentBranchFromWindow();

    const attributesToUse = {
      ...attributes,
      user,
      branch: enterpriseName,
      parentBranch: parentName,
    };

    // TODO: check using only on prod
    // if (isProduction) {
    datadogLogs.logger.log(message, attributesToUse, status);
    // }
  } catch (error) {
    handleException(error, 'logToSystem');
  }
};

export {
  logToSystem,
};
