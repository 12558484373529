import { connect } from 'react-redux';
import { compose, withHandlers } from 'react-recompose';
// components
import { openLoader, closeLoader } from '../components/loader/actions';
// helpers/constants
import * as G from '../helpers';
// utilities
import endpointsMap from '../utilities/endpoints';
import { sendRequestWithQSParamsSerializer } from '../utilities/http';
//////////////////////////////////////////////////

const getEndpoint = (payload: string) => G.ifElse(
  G.isLoadTypeClo(payload),
  endpointsMap.cloDocumentDownloadFile,
  endpointsMap.telDocumentDownloadFile,
);

export const withLoadFilePreview = compose(
  connect(null, { openLoader, closeLoader }),
  withHandlers({
    handleOpenFilePreview: (props: Object) => async (payload: Object) => {
      const { openLoader, closeLoader } = props;
      openLoader();
      const { documentFilename, primaryObjectGuid } = payload;
      const options = {
        resType: 'arraybuffer',
        params: {
          primaryObjectGuid,
          fileName: documentFilename,
        },
      };
      const endpoint = getEndpoint(payload);
      const res = await sendRequestWithQSParamsSerializer('get', endpoint, options);
      const { status } = res;
      if (G.isResponseSuccess(status)) {
        G.openFileInWindowFromArrayBufferResponse(res, { defaultFileName: documentFilename });
      } else {
        G.handleFailResponseSimple(res);
      }
      closeLoader();
    },
  }),
);

export default withLoadFilePreview;
