import * as R from 'ramda';
import * as P from 'plow-js';
import React, { Component } from 'react';
// helpers/constants
import * as G from '../helpers';
// utilities
import { sendRequest } from '../utilities/http';
import endpointsMap from '../utilities/endpoints';
//////////////////////////////////////////////////

class CloEvents extends Component {
  constructor(props: Object) {
    super(props);
    this.state = { asyncEvents: null };
    this.getCloEventsRequest = this.getCloEventsRequest.bind(this);
  }

  componentDidMount() {
    this.getCloEventsRequest(this.props);
  }

  async getCloEventsRequest(props: Object) {
    const { cloGuid } = props;
    const params = { cloGuid };
    const res = await sendRequest('get', endpointsMap.cloEventList, { params });
    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      const newState = P.$set('asyncEvents', R.or(data, []), this.state);
      this.setState(newState);
    } else {
      G.handleFailResponseSimple(
        res,
        false,
        'withAsyncCloEvents -> getCloEventsRequest',
      );
    }
  }

  render() {
    return <div>{this.props.render(this.state, this.getCloEventsRequest)}</div>;
  }
}

export const withAsyncCloEvents = (Component: any) => {
  return class extends React.Component {
    render() {
      return (
        <CloEvents
          cloGuid={this.props.cloGuid}
          branchGuid={this.props.branchGuid}
          render={(parentState: Object) =>
            <Component
              {...this.props}
              asyncEvents={parentState.asyncEvents} />
          } />
      );
    }
  };
};
