import * as R from 'ramda';
import { connect } from 'react-redux';
import { pure, compose, lifecycle, withHandlers } from 'react-recompose';
// components
import { openLoader, closeLoader } from '../components/loader/actions';
// helpers/constants
import * as G from '../helpers';
// utilities
import { sendRequest } from '../utilities/http';
import endpointsMap from '../utilities/endpoints';
//////////////////////////////////////////////////

const getShouldNotCall = (props: Object) => {
  const { loadGuid, loadType, eventCloGuid } = props;
  if (G.isNotNilAndNotEmpty(eventCloGuid)) return G.hasWindowAmousLoadDivision(eventCloGuid);
  if (R.or(G.isNilOrEmpty(loadGuid), G.hasWindowAmousLoadDivision(loadGuid))) return true;
  if (R.not(G.isLoadTypeClo(loadType))) return true;
};

export const withAsyncLoadDivisionGuid = () => compose(
  connect(null, { openLoader, closeLoader }),
  withHandlers({
    handleGetDivisionGuid: (props: Object) => async () => {
      const { loadGuid, openLoader, closeLoader, eventCloGuid } = props;

      if (getShouldNotCall(props)) return;

      openLoader();
      const res = await sendRequest('get', endpointsMap.getCloDivisionGuid(R.or(eventCloGuid, loadGuid)));
      const { data, status } = res;
      if (G.isResponseSuccess(status)) {
        G.setWindowAmousLoadDivision(loadGuid, data);
      } else {
        G.handleFailResponseSimple(res);
      }
      closeLoader();
    },
  }),
  lifecycle({
    componentDidMount() {
      this.props.handleGetDivisionGuid();
    },
  }),
  pure,
);
