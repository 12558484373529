import * as R from 'ramda';
import { compose, withState, lifecycle, withHandlers } from 'react-recompose';
// helpers/constants
import * as G from '../helpers';
import * as GC from '../constants';
// utilities
import { sendRequest } from '../utilities/http';
import endpointsMap from '../utilities/endpoints';
/////////////////////////////////////////////////

const getTelBranchGuid = (props: Object) => R.or(
  R.or(props.branchGuid, R.path([GC.FIELD_TEL, GC.FIELD_BRANCH_GUID], props)),
  R.path([GC.FIELD_TEL, GC.FIELD_BRANCH, GC.FIELD_GUID], props),
);

const getIndexedMarginViolationRules = (rules: Array) => {
  let indexed = {};

  rules.forEach((rule: Object) => {
    const { transportationModeGuids } = rule;

    transportationModeGuids.forEach((guid: string) => {
      indexed = R.assoc(guid, rule, indexed);
    });
  });

  return indexed;
};

export const withAsyncMarginViolationRules = compose(
  withState('marginViolationRules', 'setMarginViolationRules', null),
  withHandlers({
    getMarginViolationRules: (props: Object) => async () => {
      const { openLoader, closeLoader, setMarginViolationRules } = props;

      const currentUserBranchGuid = G.getAmousCurrentUserBranchGuidFromWindow();
      const options = {
        params: {
          [GC.BRANCH_GUID]: R.or(getTelBranchGuid(props), currentUserBranchGuid),
        },
      };
      G.callFunction(openLoader);

      const res = await sendRequest('get', endpointsMap.marginViolationRuleList, options);

      const { data, status } = res;

      if (R.and(G.isResponseSuccess(status), G.isNotNilAndNotEmpty(data))) {
        setMarginViolationRules(getIndexedMarginViolationRules(data));
      } else {
        G.handleFailResponseSimple(
          res,
          false,
          'withAsyncCarrierRates -> getMarginViolationRules',
        );
      }

      G.callFunction(closeLoader);
    },
  }),
  lifecycle({
    componentDidMount() {
      this.props.getMarginViolationRules();
    },
  }),
);
