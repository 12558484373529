import * as R from 'ramda';
import upperCase from 'voca/upper_case';
import titleCase from 'voca/title_case';
import lowerCase from 'voca/lower_case';
// helpers/constants
import * as H from './helpers';
import { enumLocaleMap } from '../constants/enums';
//////////////////////////////////////////////////

const THREE_DOTS_STRING = '...';

const systemFieldsLocaleMap = {
  // hours
  'cycleTomorrow': 'titles:cycle-tomorrow',
  'cycleRemaining': 'titles:cycle-remaining',
  'shiftRemaining': 'titles:shift-remaining',
  'timeUntilBreak': 'titles:time-until-break',
  'dutyStatusCode': 'titles:duty-status-code',
  'outOfDutyReason': 'titles:out-of-duty-reason',
  'shiftDriveRemaining': 'titles:shift-drive-remaining',
  'timeInCurrentStatus': 'titles:time-in-current-status',
};

const uomLocaleMap = {
  'MILE': {
    plural: 'titles:miles',
    singular: 'titles:mile',
    abbreviation: 'titles:mi-abbr',
  },
  'KM': {
    plural: 'titles:kilometers',
    singular: 'titles:kilometer',
    abbreviation: 'titles:km-abbr',
  },
  'KG': {
    abbreviation: 'titles:kg-abbr',
    plural: 'uoms:uom-kilogram-plural',
    singular: 'uoms:uom-kilogram-singular',
  },
  'LB': {
    abbreviation: 'titles:lb-abbr',
    plural: 'uoms:uom-pound-plural',
    singular: 'uoms:uom-pound-singular',
  },
  'METRIC_TON': {
    plural: 'uoms:uom-metric-ton-plural',
    singular: 'uoms:uom-metric-ton-singular',
  },
  'TON_US': {
    plural: 'uoms:uom-ton-us-plural',
    singular: 'uoms:uom-ton-us-singular',
  },
  'FOOT': {
    plural: 'titles:feet',
    abbreviation: 'titles:ft-abbr',
  },
  'INCH': {
    plural: 'titles:inches',
    abbreviation: 'titles:in-abbr',
  },
  'METER': {
    plural: 'titles:meters',
    abbreviation: 'titles:m-abbr',
  },
  'CENTIMETER': {
    plural: 'titles:centimeters',
    abbreviation: 'titles:cm-abbr',
  },
  'DAYS': {
    plural: 'titles:days',
  },
  'HOURS': {
    plural: 'titles:hours',
  },
  'MILES': {
    plural: 'titles:miles',
    abbreviation: 'titles:mi-abbr',
  },
  'KILOMETERS': {
    plural: 'titles:kilometers',
    abbreviation: 'titles:km-abbr',
  },
  'GALLON_LIQUID': {
    plural: 'titles:gallons-liquid',
  },
  'CUBIC_M': {
    plural: 'titles:cubic-metres',
  },
  'CUBIC_FT': {
    plural: 'titles:cubic-feet',
  },
};

// locale
const getLocale = (
  key: string,
  defaultValue: string = 'no-locale',
  locale: Object,
  options: Object,
) => {
  const caseActionMap = { titleCase, upperCase, lowerCase };
  const text = H.getOrElse(locale, key, defaultValue);

  if (H.isObject(options)) {
    const { caseAction } = options;
    const caseActionFn = caseActionMap[caseAction];

    if (R.and(caseAction, H.isFunction(caseActionFn))) return caseActionFn(text);
  }

  return text;
};

const locale = JSON.parse(R.pathOr('null', ['localStorage', 'amousLocale'], window));

const getWindowLocale = (
  key: string,
  defValue: string,
  options: Object,
) => {
  const defaultValue = R.or(defValue, THREE_DOTS_STRING);
  const caseActionMap = { titleCase, upperCase, lowerCase };
  const text = H.getOrElse(locale, key, defaultValue);

  if (H.isObject(options)) {
    const { caseAction } = options;
    const caseActionFn = caseActionMap[caseAction];

    if (R.and(caseAction, H.isFunction(caseActionFn))) return caseActionFn(text);
  }

  return text;
};

const getWindowLocaleWithoutDefault = (
  key: string,
  options: Object,
) => {
  const caseActionMap = { titleCase, upperCase, lowerCase };
  const value = R.path([key], locale);

  if (H.isObject(options)) {
    const { caseAction } = options;
    const caseActionFn = caseActionMap[caseAction];

    if (R.and(caseAction, H.isFunction(caseActionFn))) return caseActionFn(value);
  }

  return value;
};

const getWindowLocaleArr = (
  keys: any,
  defValue: string,
  options: Object,
) => {
  let text = '';
  const defaultValue = R.or(defValue, THREE_DOTS_STRING);
  const caseActionMap = { titleCase, upperCase, lowerCase };

  if (H.isArray(keys)) {
    R.forEach(
      (key: string) => text = `${text} ${getWindowLocale(key)}`,
      keys,
    );
  } else {
    text = H.getOrElse(locale, keys, defaultValue);
  }

  if (H.isObject(options)) {
    const { caseAction } = options;
    const caseActionFn = caseActionMap[caseAction];

    if (R.and(caseAction, H.isFunction(caseActionFn))) return caseActionFn(text);
  }

  return text;
};

const getSystemFieldLocale = (
  fieldNameString: string,
  options: Object,
) => {
  const localeKey = R.pathOr(null, [fieldNameString], systemFieldsLocaleMap);

  return getLocale(localeKey, fieldNameString, locale, options);
};

const getEnumLocale = (
  enumString: string,
  options: Object,
) => {
  const localeKey = R.pathOr(null, [enumString], enumLocaleMap);

  return getLocale(localeKey, enumString, locale, options);
};

const getEnumLocaleFromStringOrArray = (data: Array | string = '', joiner: string = ', ') => {
  if (H.isArray(data)) {
    return R.compose(
      R.join(joiner),
      R.map(getEnumLocale),
    )(data);
  }

  return getEnumLocale(data);
};

const getWindowLocaleFromStringOrArray = (data: Array | string = '', joiner: string = ', ') => {
  if (H.isArray(data)) {
    return R.compose(
      R.join(joiner),
      R.map(getWindowLocale),
    )(data);
  }

  return getWindowLocale(data);
};

const getUomLocale = (enumString: string, nounType: string = 'plural') => {
  const noLocaleString = '...';

  if (H.isNilOrEmpty(enumString)) {
    return noLocaleString;
  }

  const localeKey = R.pathOr(null, [R.toUpper(enumString), nounType], uomLocaleMap);

  if (R.isNil(localeKey)) {
    return noLocaleString;
  }

  return H.getOrElse(locale, localeKey, noLocaleString);
};

const getAddTitle = (titleArr: Array) =>
  `${getWindowLocale('titles:add', 'Add')} ${getWindowLocale(...titleArr)}`;

const getEditTitle = (titleArr: Array) =>
  `${getWindowLocale('titles:edit', 'Edit')} ${getWindowLocale(...titleArr)}`;

export {
  getAddTitle,
  getEditTitle,
  getUomLocale,
  getEnumLocale,
  getWindowLocale,
  getWindowLocaleArr,
  getSystemFieldLocale,
  getWindowLocaleWithoutDefault,
  getEnumLocaleFromStringOrArray,
  getWindowLocaleFromStringOrArray,
};
