import * as R from 'ramda';
import styled from 'styled-components';
import Popover from '@mui/material/Popover';
import { OuterClick } from 'react-outer-click';
import React, { useState, useEffect, useCallback } from 'react';
// helpers/constants
import * as G from '../helpers';
//////////////////////////////////////////////////

export const InnerPopover = styled.div`
  right: 5px;
  display: flex;
  padding: 5px 0;
  margin-top: 4px;
  user-select: none;
  align-items: start;
  position: relative;
  border-radius: 2px;
  word-break: break-all;
  flex-direction: column;
  justify-content: space-between;
  background: ${G.getTheme('colors.white')};
  box-shadow: 0px 1px 8px 0px ${G.getTheme('colors.boxShadowGrey')};
`;

const useFixedPopover = (props: Object = {}) => {
  const {
    popoverOverflow,
    shouldNotCloseOnScroll,
  } = props;

  const [opened, setOpened] = useState(false);
  const [component, setComponent] = useState(null);

  const closeFixedPopup = useCallback(() => {
    setComponent(null);
    setOpened(false);
    // eslint-disable-next-line no-use-before-define
    removeScrollHandler();
  }, []);

  const removeScrollHandler = useCallback(
    () => window.removeEventListener('scroll', closeFixedPopup, true),
    [closeFixedPopup],
  );

  useEffect(() => () => removeScrollHandler(), [removeScrollHandler]);

  const openFixedPopup = useCallback((props: Object) => {
    const {
      el,
      content,
    } = props;

    if (R.not(shouldNotCloseOnScroll)) {
      window.addEventListener('scroll', closeFixedPopup, true);
    }

    const PopupComponent = (
      <Popover
        open={true}
        anchorEl={el}
        hideBackdrop={true}
        onClose={closeFixedPopup}
        style={{ pointerEvents: 'none' }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        PaperProps={{
          style: {
            borderRadius: 0,
            overflow: 'unset',
            boxShadow: 'none',
            pointerEvents: 'all',
            backgroundColor: 'transparent',
          },
        }}
      >
        <OuterClick onOuterClick={closeFixedPopup} id='fixed-popover-wrapper'>
          <InnerPopover>
            {content}
          </InnerPopover>
        </OuterClick>
      </Popover>
    );

    setComponent(PopupComponent);
    setOpened(true);
  }, [closeFixedPopup, shouldNotCloseOnScroll]);

  return {
    openFixedPopup,
    closeFixedPopup,
    PopoverComponent: (
      <div style={{ overflow: R.or(popoverOverflow, 'unset') }}>
        {opened ? component : null}
      </div>
    ),
  };
};

export default useFixedPopover;

