import * as R from 'ramda';
import { pure, compose, withState, withHandlers } from 'react-recompose';
// helpers/constants
import * as G from '../helpers';
import * as GC from '../constants';
// utilities
import { sendRequest } from '../utilities/http';
import endpointsMap from '../utilities/endpoints';
//////////////////////////////////////////////////

async function distanceCalcRequest(props: Object) {
  const { stopPoints, branchGuid, setDistance } = props;
  if (G.isNilOrEmpty(stopPoints)) return;
  if (G.isNilOrEmpty(branchGuid)) return;
  const options = {
    data: {
      stopPoints,
      [GC.FIELD_BRANCH_GUID]: branchGuid,
    },
  };
  const res = await sendRequest('post', endpointsMap.distanceCalculation, options);
  const { data, status } = res;
  const stopResults = R.prop(['stopResults'], data);
  if (R.isNil(stopResults)) return;
  if (G.isResponseSuccess(status)) {
    setDistance(data);
  } else {
    G.handleFailResponseSimple(
      res,
      false,
      'withAsyncDistanceCalc -> distanceCalcRequest',
    );
  }
}

async function distanceCalcRequestWithCallback(props: Object) {
  const { callback, stopPoints, branchGuid } = props;
  if (G.isNilOrEmpty(stopPoints)) return;
  if (G.isNilOrEmpty(branchGuid)) return;
  const options = {
    data: {
      stopPoints,
      [GC.FIELD_BRANCH_GUID]: branchGuid,
    },
  };
  const res = await sendRequest('post', endpointsMap.distanceCalculation, options);
  const { data, status } = res;
  const stopResults = R.prop(['stopResults'], data);
  if (R.isNil(stopResults)) return;
  if (G.isResponseSuccess(status)) {
    if (G.isFunction(callback)) callback({ data });
  } else {
    G.handleFailResponseSimple(
      res,
      false,
      'withAsyncDistanceCalc -> distanceCalcRequestWithCallback',
    );
  }
}

export const withAsyncDistanceCalc = compose(
  withState('asyncDistance', 'setDistance', null),
  withHandlers({
    getAsyncDistanceCalc: (props: Object) => (gettingProps: Object) => {
      const allProps = R.mergeRight(props, gettingProps);
      return distanceCalcRequest(allProps);
    },
    getAsyncDistanceCalcWithCallback: (props: Object) => (gettingProps: Object) => {
      const allProps = R.mergeRight(props, gettingProps);
      return distanceCalcRequestWithCallback(allProps);
    },
  }),
  pure,
);

export default withAsyncDistanceCalc;
