import * as R from 'ramda';
import * as Yup from 'yup';
import validator from 'validator';
// helpers/constants
import * as GC from '../constants';
import { getWindowLocale } from './locale';
import {
  ifElse,
  isNotNil,
  isNotNaN,
  getOrElse,
  isNilOrEmpty,
  isAllNilOrEmpty,
  isNotNilAndNotEmpty,
} from './helpers';
//////////////////////////////////////////////////

// TODO: refactor with export {} and direct import from here not from index

export const isValidEmail = (email: string) => GC.EMAIL_REGEXP.test(email);

export const getShouldBeFromToLocaleTxt = (from: number = 0, to: number = 200) => `
  ${
    getWindowLocale('validation:field-length-from', 'Field length should be from')
  } ${from} ${
    getWindowLocale('validation:field-length-to', 'to')
  } ${to}
`;

export const getShouldBeFromToCharLocaleTxt = (
  from: number = 0,
  to: number = 20,
) => `
  ${
    getWindowLocale('validation:field-length-from', 'Field length should be from')
  } ${from} ${
    getWindowLocale('validation:field-length-to', 'to')
  } ${to} ${
    getWindowLocale('titles:characters', 'characters')
  }
`;

export const getShouldBePickOnlyLocaleTxt = (value: number) => `
  ${getWindowLocale('validation:you-can-pick-only', 'You can pick only')} ${value}
`;

export const getShouldBeLessOrEqualLocaleTxt = (maxValue: number) => `
  ${getWindowLocale('validation:should-be-less-or-equal', 'Should be less or equal')} ${maxValue}
`;

export const getShouldBeLessLocaleTxt = (value: any) => `
  ${getWindowLocale('validation:should-be-less', 'Should be less than')} ${value}
`;

export const getShouldBeBiggerLocaleTxt = (value: any) => `
  ${getWindowLocale('validation:should-be-bigger', 'Should be bigger than')} ${value}
`;

export const getRequiredLocaleTxt = () => getWindowLocale(
  'validation:field-required',
  'Field is Required',
);

export const getFieldIsNotValidLocaleTxt = () => getWindowLocale(
  'validation:field-is-not-valid',
  'Field is not valid',
);

export const getFieldIsNotValidEmailTxt = () => getWindowLocale(
  'validation:email-is-not-valid',
  'Enter a valid Email',
);

export const getShouldBeNumericLocaleTxt = () => getWindowLocale(
  'validation:should-be-numeric',
  'Should be a numeric value',
);

export const getShouldBePositiveLocaleTxt = () => getWindowLocale(
  'validation:should-be-positive',
  'Should be an positive value',
);

export const getShouldBeIntegerLocaleTxt = () => getWindowLocale(
  'validation:should-be-a-number',
  'Should be a number',
);

export const getShouldBeEmailLocaleTxt = () => getWindowLocale(
  'validation:should-be-email',
  'Should be a valid Email',
);

export const getShouldBePhoneLocaleTxt = () => getWindowLocale(
  'validation:should-be-phone',
  'Should be a phone number value',
);

export const getShouldBeFileLocaleTxt = () => getWindowLocale(
  'validation:should-be-file',
  'Should be a file',
);

export const getShouldBeUrlLocaleTxt = () => getWindowLocale(
  'validation:should-be-url',
  'Should be a valid URL value',
);

export const getShouldBeFileOrUrlLocaleTxt = () => getWindowLocale(
  'validation:should-be-file-or-url',
  'Should be a file or file URL',
);

export const getShouldBeValidDateLocaleTxt = () => getWindowLocale(
  'validation:should-be-a-valid-date',
  'Should be a valid Date',
);

export const getEmailOrPrimaryPhoneMustBeFilled = () => getWindowLocale(
  'validation:email-or-primary-phone-must-be-filled',
  'Email or Primary phone number must be filled',
);

export const getSelectLocationLocaleTxt = () => getWindowLocale(
  'validation:select-a-location',
  'Please, select a location',
);

// yup
export const yupStringNotRequired = Yup.string().nullable(true).notRequired();

export const yupStringRequired = Yup.string().nullable(true).required(getRequiredLocaleTxt());

export const yupArrayNotRequired = Yup.array().nullable(true).notRequired();

export const yupArrayRequired = Yup.array()
  .nullable(true)
  .min(1, getRequiredLocaleTxt())
  .required(getRequiredLocaleTxt());

export const yupNumberRequired = Yup.number()
  .nullable(true)
  .required(getRequiredLocaleTxt())
  .typeError(getShouldBeNumericLocaleTxt())
  .transform((value: number) => ifElse(R.isEmpty(value), null, value));

export const yupNumberNotRequired = Yup.number()
  .notRequired()
  .nullable(true)
  .typeError(getShouldBeNumericLocaleTxt())
  .transform((value: number) => ifElse(R.isEmpty(value), null, value));
// yup

export const getMaximumItemsToPickLocaleText = (value: number) => `${
  getWindowLocale('validation:maximum-items-to-pick', 'Maximum items to pick is')} ${value}`;

export const required = (value: string = '') => ifElse(
  isNotNilAndNotEmpty(value),
  undefined,
  getRequiredLocaleTxt(),
);

export const requiredWithoutMessage = (value: string = '') => ifElse(
  isNotNilAndNotEmpty(value),
  undefined,
  ' ',
);

export const isNumeric = (value: string = '') => ifElse(
  validator.isNumeric(ifElse(isNotNil(value), String(value), '')),
  undefined,
  getShouldBeNumericLocaleTxt(),
);

export const isMinMax = (min: number, max: number, value: string) => {
  const defaultValue = R.or(value, '');
  const length = R.propOr(undefined, 'length', String(defaultValue));
  return ifElse(
    R.and(R.gte(length, min), R.lte(length, max)),
    undefined,
    getShouldBeFromToCharLocaleTxt(min, max),
  );
};

export const curriedIsMinMax = R.curry(isMinMax);

export const isPositiveValue = (value: string) => ifElse(
  R.isEmpty(R.or(value, '')),
  undefined,
  ifElse(
    R.gte(value, 0),
    undefined,
    getShouldBePositiveLocaleTxt(),
  ),
);

export const isEmptyOrInteger = (value: number) => ifElse(
  R.isEmpty(R.or(value, '')),
  undefined,
  ifElse(
    validator.isInt(ifElse(
      isNotNil(value),
      String(value),
      '',
    )),
    undefined,
    getShouldBeIntegerLocaleTxt(),
  ),
);


export const isEmptyOrNumeric = (value: string) => ifElse(
  R.isEmpty(R.or(value, '')),
  undefined,
  ifElse(
    validator.isNumeric(ifElse(
      isNotNil(value),
      String(value),
      '',
    )),
    undefined,
    getShouldBeNumericLocaleTxt(),
  ),
);

export const isEmptyOrFloat = (value: string) => ifElse(
  R.isEmpty(R.or(value, '')),
  undefined,
  ifElse(
    validator.isFloat(ifElse(
      isNotNil(value),
      String(value),
      '',
    )),
    undefined,
    getShouldBeNumericLocaleTxt(),
  ),
);

export const isEmptyOrEmail = (value: string = '') => ifElse(
  R.isEmpty(R.or(value, '')),
  undefined,
  ifElse(
    validator.isEmail(R.or(value, '')),
    undefined,
    getShouldBeEmailLocaleTxt(),
  ),
);

export const isEmptyOrMinMax = (min: number, max: number, value: string) => ifElse(
  isNilOrEmpty(value),
  undefined,
  ifElse(
    R.and(
      R.lte(getOrElse(String(value), 'length', undefined), max),
      R.gte(getOrElse(String(value), 'length', undefined), min),
    ),
    undefined,
    getShouldBeFromToCharLocaleTxt(min, max),
  ),
);

export const curriedIsEmptyOrMinMax = R.curry(isEmptyOrMinMax);

export const isLessOrEqual = (maxValue: number, value: string) => ifElse(
  isNilOrEmpty(value),
  undefined,
  ifElse(
    R.lte(value, maxValue),
    undefined,
    getShouldBeLessOrEqualLocaleTxt(maxValue),
  ),
);

export const curriedIsLessOrEqual = R.curry(isLessOrEqual);

export const isEmptyOrMinMaxNumber = (min: number, max: number, value: string) => ifElse(
  isNilOrEmpty(value),
  undefined,
  ifElse(
    R.and(R.gte(value, min), R.lte(value, max)),
    undefined,
    getShouldBeFromToLocaleTxt(min, max),
  ),
);

export const curriedIsEmptyOrMinMaxNumber = R.curry(isEmptyOrMinMaxNumber);

export const isPhoneNumber = (value: string = '') => ifElse(
  R.or(R.isEmpty(R.or(value, '')), R.isNil(R.or(value, ''))),
  undefined,
  ifElse(
    GC.PHONE_NUMBER_REGEXP.test(R.or(value, '')),
    undefined,
    getShouldBePhoneLocaleTxt(),
  ),
);

export const isIntOrFloat = (value: string = '') => ifElse(
  R.or(R.isEmpty(R.or(value, '')), R.isNil(R.or(value, ''))),
  undefined,
  ifElse(
    GC.FLOAT_REGEXP.test(R.or(value, '')),
    undefined,
    getShouldBeNumericLocaleTxt(),
  ),
);

export const isURL = (value: string = '') => ifElse(
  validator.isURL(R.or(value, '')),
  undefined,
  getShouldBeUrlLocaleTxt(),
);

export const isEmail = (value: string = '') => ifElse(
  validator.isEmail(R.or(value, '')),
  undefined,
  getShouldBeEmailLocaleTxt(),
);

export const isEmptyOrURL = (value: string = '', fields: Object) => ifElse(
  R.or(
    validator.isURL(R.or(value, '')),
    R.equals(R.path(['length'], value), 0),
  ),
  ifElse(
    R.and(
      isNilOrEmpty(value),
      isNilOrEmpty(R.prop('file', fields)),
    ),
    getShouldBeFileOrUrlLocaleTxt(),
    undefined,
  ),
  getShouldBeUrlLocaleTxt(),
);

export const isEmptyFileAndURL = (value: string = '', fields: Object) => ifElse(
  isAllNilOrEmpty([
    value,
    R.prop('url', fields),
    R.prop('file', fields),
    R.prop('documentFilename', fields),
  ]),
  getShouldBeFileOrUrlLocaleTxt(),
  undefined,
);

export const validateStringForRequiredAndMinMax = (min: number, max: number) =>
  R.compose(
    R.ifElse(
      R.either(R.isNil, R.isEmpty),
      R.always(getRequiredLocaleTxt()),
      R.ifElse(
        R.compose(R.both(R.gte(R.__, min), R.lte(R.__, max)), R.prop('length')),
        R.always(null),
        R.always(getShouldBeFromToCharLocaleTxt(min, max)),
      ),
    ),
    R.ifElse(
      R.is(Number),
      R.toString,
      R.identity,
    ),
    R.ifElse(
      R.is(String),
      R.trim,
      R.identity,
    ),
    R.defaultTo(''),
  );

export const validateStringForMinMax = (min: number, max: number) =>
  R.ifElse(
    R.anyPass([
      R.isNil,
      R.isEmpty,
      R.is(Number),
    ]),
    R.always(null),
    R.ifElse(
      R.allPass([
        R.is(String),
        R.compose(
          R.both(
            R.gte(R.__, min),
            R.lte(R.__, max),
          ),
          R.prop('length'),
        ),
      ]),
      R.always(null),
      R.always(getShouldBeFromToCharLocaleTxt(min, max)),
    ),
  );

