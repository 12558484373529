import * as R from 'ramda';
import { pure, compose, withHandlers } from 'react-recompose';
import { connect } from 'react-redux';
// components
import { openLoader, closeLoader } from '../components/loader/actions';
// helpers/constants
import * as G from '../helpers';
import * as GC from '../constants';
// utilities
import { sendRequest } from '../utilities/http';
import endpointsMap from '../utilities/endpoints';
//////////////////////////////////////////////////

export const withAsyncPinReport = compose(
  connect(null, { openLoader, closeLoader }),
  withHandlers({
    handlePinOrUnpinReport: (props: Object) => async (data: any) => {
      const {
        reportList,
        setReports,
        selectedReport,
      } = props;

      let requestData;

      if (G.isArray(data)) {
        requestData = data;
      } else {
        const { reportGuid, pinnedReport } = data;

        const pinnedReportGuids = R.compose(
          R.map(G.getGuidFromObject),
          R.sortBy(R.prop(GC.FIELD_PINNED_REPORT_INDEX)),
          R.filter(({ pinnedReport }: Object) => pinnedReport),
        )(reportList);

        requestData = G.ifElse(
          pinnedReport,
          R.without(R.of(Array, reportGuid), pinnedReportGuids),
          R.append(reportGuid, pinnedReportGuids),
        );
      }

      const options = {
        data: requestData,
        params: { [GC.REPORT_TYPE]: G.getPropFromObject(GC.FIELD_TYPE, selectedReport) },
      };

      const res = await sendRequest('put', endpointsMap.changePinnedReports, options);

      const { status } = res;

      if (G.isResponseSuccess(status)) {
        const indexedPinnedReportData = R.compose(
          R.indexBy(R.prop(GC.FIELD_GUID)),
          G.mapIndexed((guid: string, i: number) => ({
            guid,
            [GC.FIELD_PINNED_REPORT_INDEX]: i,
          })),
        )(requestData);
        const mappedReports = R.map((item: Object) => {
          const { guid } = item;

          const match = G.getPropFromObject(guid, indexedPinnedReportData);

          if (G.isNotNil(match)) {
            return {
              ...item,
              ...match,
              [GC.FIELD_PINNED_REPORT]: true,
            };
          }

          return {
            ...item,
            [GC.FIELD_PINNED_REPORT]: false,
            [GC.FIELD_PINNED_REPORT_INDEX]: null,
          };
        }, reportList);

        G.callFunctionWithArgs(setReports, mappedReports);
      } else {
        G.handleException('error', 'withAsyncPinReport exception');
      }
    },
  }),
  pure,
);

