import { toastr } from 'react-redux-toastr';
// helpers
import { getWindowLocale } from './locale';
//////////////////////////////////////////////////

// use with sagas
function* showToastrMessage(type: string, message: string, toastrOptions: any = {}) {
  const msg = getWindowLocale(message, message);

  toastr[type](msg, toastrOptions);
}

// use with wait/async
const showToastrMessageSimple = (type: string, message: string, toastrOptions: any = {}) => (
  toastr[type](message, toastrOptions)
);

const showToastrMessageSimpleSuccessDefault = () => (
  showToastrMessageSimple(
    'success',
    getWindowLocale('messages:success:200-201', 'The request has succeeded'),
  )
);

const showToastrMessageSimpleCatchDefault = () => (
  showToastrMessageSimple(
    'error',
    getWindowLocale(
      'messages:error:unknown',
      'Oops! The system is experiencing a problem. The issue has been reported.',
    ),
  )
);

const showToastrMessageFromLocale = (type: string, localeKey: string) => (
  toastr[type](getWindowLocale(localeKey))
);

export {
  showToastrMessage,
  showToastrMessageSimple,
  showToastrMessageFromLocale,
  showToastrMessageSimpleCatchDefault,
  showToastrMessageSimpleSuccessDefault,
};
