import * as R from 'ramda';
import React from 'react';
import { compose, withHandlers } from 'react-recompose';
// components
import { ConfirmComponent } from '../components/confirm';
// helpers/constants
import * as G from '../helpers';
//////////////////////////////////////////////////

export const emptyMassRemoveListMsg = () => G.showToastrMessageSimple(
  'info',
  G.getWindowLocale('messages:select-item', 'Please, select at least one item'),
);

export const getMassRemoveListConfirmation = () => (
  <ConfirmComponent
    textLocale={G.getWindowLocale(
      'messages:confirm-delete-entities',
      'Are you sure you want to delete these entities?',
    )} />
);

export const withMassEntitiesRemove = (options: Object) => compose(
  withHandlers({
    handleClickMassDeleteItems: (props: Object) => () => {
      const { openModal, closeModal, selectedList, massDeleteItemsRequest } = props;
      if (R.isEmpty(selectedList)) return emptyMassRemoveListMsg();
      const modal = {
        component: getMassRemoveListConfirmation(),
        options: {
          width: '600px',
          controlButtons: [
            {
              type: 'button',
              name: G.getWindowLocale('actions:delete', 'Delete'),
              action: () => {
                closeModal();
                massDeleteItemsRequest({ props, options });
              },
            },
          ],
        },
      };
      openModal(modal);
    },
  }),
);
