import * as R from 'ramda';
import createHistory from 'history/createBrowserHistory';
// constants
import * as GC from '../constants';
// utilities
import routesMap from '../utilities/routes';
// helpers
import { ifElse, notContain } from './helpers';
import { getAmousConfigByNameFromWindow } from './window';
//////////////////////////////////////////////////

const history = createHistory();

const goToRoute = (route: string) => history.push(route);

const getCurrentPathname = () => history.location.pathname;

const historyGoBack = () => history.goBack();

const getWindowLocationPathname = () => window.location.pathname;

const windowLocationReload = () => window.location.reload();

const isEqualCurrentPathnameAndRouteName = (routeName: string) => R.equals(getCurrentPathname(), routeName);

const isNotOldTelDetails = () => notContain('route/tel', getCurrentPathname());

const getSplittedUrlLocation = (divider: string) => (
  window.location.host.split(divider)
);

const equalLocationPathname = R.equals(window.location.pathname);

const getLoadRouteByConfigAndLoadType = (routesMap: Object, guid: string, isClo: boolean = false) => ifElse(
  isClo,
  routesMap.dispatchDetailsOrder,
  routesMap.dispatchDetailsLoad,
)(guid);

const goToLoadDetailsByConfigAndLoadType = (routesMap: Object, guid: string, isClo: boolean = false) => {
  const route = getLoadRouteByConfigAndLoadType(routesMap, guid, isClo);

  goToRoute(route);
};

const getCarrierProfileRoute = (guid: string) => {
  const config = getAmousConfigByNameFromWindow(GC.UI_USE_NEW_CARRIER_PROFILE);
  const routeName = ifElse(config, 'carrierProfile', 'carrierEdit');

  return routesMap[routeName](guid);
};

const goToCarrierProfileByConfig = (guid: string) => goToRoute(getCarrierProfileRoute(guid));

// pages
const isLoginPage = R.equals(GC.ROUTE_PATH_LOGIN);
const isPageDispatchPlanner = R.equals(GC.PAGE_DISPATCH_PLANNER);
const isPageDispatchBoardNew = R.equals(GC.PAGE_DISPATCH_BOARD_NEW);
const isPageDispatchPlannerEvents = R.equals(GC.PAGE_DISPATCH_PLANNER_EVENTS);
const isPageDispatchDetailsNewLoad = R.equals(GC.PAGE_DISPATCH_DETAILS_NEW_LOAD);
const isPageDispatchDetailsNewOrder = R.equals(GC.PAGE_DISPATCH_DETAILS_NEW_ORDER);

export {
  history,
  goToRoute,
  historyGoBack,
  getCurrentPathname,
  isNotOldTelDetails,
  windowLocationReload,
  equalLocationPathname,
  getCarrierProfileRoute,
  getSplittedUrlLocation,
  getWindowLocationPathname,
  goToCarrierProfileByConfig,
  getLoadRouteByConfigAndLoadType,
  goToLoadDetailsByConfigAndLoadType,
  isEqualCurrentPathnameAndRouteName,
  // pages
  isLoginPage,
  isPageDispatchPlanner,
  isPageDispatchBoardNew,
  isPageDispatchPlannerEvents,
  isPageDispatchDetailsNewLoad,
  isPageDispatchDetailsNewOrder,
};
