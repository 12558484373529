import * as R from 'ramda';
import * as P from 'plow-js';
import React, { Component } from 'react';
// helpers/constants
import * as G from '../helpers';
import * as GC from '../constants';
// utilities
import { sendRequest } from '../utilities/http';
import endpointsMap from '../utilities/endpoints';
//////////////////////////////////////////////////

export class SearchStatusMessages extends Component {
  constructor(props: Object) {
    super(props);
    this.state = { statusMessagesConfigs: null };
    this.getStatusConfigsRequest = this.getStatusConfigsRequest.bind(this);
  }

  componentDidMount() {
    this.getStatusConfigsRequest(this.props);
  }

  async getStatusConfigsRequest(props: Object) {
    const branchGuid = R.prop('branchGuid', props);
    if (G.isNilOrEmpty(branchGuid)) return;
    const params = { [GC.FIELD_BRANCH_GUID]: branchGuid };
    const res = await sendRequest('get', endpointsMap.statusCodeList, { params });
    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      if (G.isNotEmpty(data)) {
        const newState = P.$set('statusMessagesConfigs', data, this.state);
        this.setState(newState);
      }
    } else {
      G.handleFailResponseSimple(
        res,
        false,
        'withAsyncStatusMessages -> getStatusConfigsRequest',
      );
    }
  }

  render() {
    return <div>{this.props.render(this.state, this.getConfigsRequest)}</div>;
  }
}

export const withAsyncStatusMessages = (Component: any) => {
  return class extends React.Component {
    render() {
      return (
        <SearchStatusMessages
          branchGuid={this.props.branchGuid}
          render={(parentState: Object, getStatusConfigsRequest: Function) =>
            <Component
              {...this.props}
              getStatusConfigsRequest={getStatusConfigsRequest}
              statusMessagesConfigs={parentState.statusMessagesConfigs} />
          } />
      );
    }
  };
};
