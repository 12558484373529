// localStorage
const getCurrentBranchGuid = () => localStorage.getItem('currentBranchGuid'); // eslint-disable-line
const removeCurrentBranchGuid = () => localStorage.removeItem('currentBranchGuid'); // eslint-disable-line
const setStorage = (data: Object) => localStorage.setItem('storage', JSON.stringify(data)); // eslint-disable-line
const setCurrentBranchGuid = (guid: string) => localStorage.setItem('currentBranchGuid', guid); // eslint-disable-line
// set/get/remove item
const removeItemFromLocalStorage = (name: string) => localStorage.removeItem(name); // eslint-disable-line
const getItemFromLocalStorage = (name: string) => JSON.parse(localStorage.getItem(name)); // eslint-disable-line
const setItemToLocalStorage = (name: string, data: any) =>
  localStorage.setItem(name, JSON.stringify(data)); // eslint-disable-line
// sessionStorage
const removeAuthTokenFromSession = () => sessionStorage.removeItem('theamoustoken'); // eslint-disable-line
const removeRefreshTokenFromSession = () => sessionStorage.removeItem('refreshToken'); // eslint-disable-line
const getAuthTokenFromSession = () => JSON.parse(sessionStorage.getItem('theamoustoken')); // eslint-disable-line
const getRefreshTokenFromSession = () => JSON.parse(sessionStorage.getItem('refreshToken')); // eslint-disable-line
const setAuthTokenToSession = (token: string) => sessionStorage.setItem('theamoustoken', JSON.stringify(token)); // eslint-disable-line
const setRefreshTokenToSession = (token: string) => sessionStorage.setItem('refreshToken', JSON.stringify(token)); // eslint-disable-line

const approximateTimeout = 60;
const setAuthTokenExpirationToSession = (expiresIn: number) => {
  const expirationTime = new Date().getTime() + ((expiresIn - approximateTimeout) * 1000);

  sessionStorage.setItem('authTokenExpiration', expirationTime); // eslint-disable-line
};

const isAuthTokenExpired = () => sessionStorage.getItem('authTokenExpiration') <= new Date().getTime(); // eslint-disable-line

export {
  setStorage,
  isAuthTokenExpired,
  setCurrentBranchGuid,
  getCurrentBranchGuid,
  setItemToLocalStorage,
  setAuthTokenToSession,
  getItemFromLocalStorage,
  removeCurrentBranchGuid,
  getAuthTokenFromSession,
  setRefreshTokenToSession,
  removeItemFromLocalStorage,
  removeAuthTokenFromSession,
  getRefreshTokenFromSession,
  removeRefreshTokenFromSession,
  setAuthTokenExpirationToSession,
};
