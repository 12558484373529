import * as R from 'ramda';
import { toastr } from 'react-redux-toastr';
import { put, all, call } from 'redux-saga/effects';
// common
import { sendLogOutRequest } from '../common/actions';
// constants
import { ARRAY_BUFFER_BYTE_LENGTH } from '../constants';
// helpers
import * as H from './helpers';
import { isProduction } from './env';
import { getWindowLocale } from './locale';
import { getPropFromObject } from './getter';
import { showToastrMessage, showToastrMessageSimple } from './toastr';
//////////////////////////////////////////////////
const qs = require('qs');
//////////////////////////////////////////////////

const parseQueryString = () => qs.parse(window.location.search, { ignoreQueryPrefix: true }); // eslint-disable-line

const getMockResponse = (status: number, data: any) => ({
  status,
  data,
});

const isResponseFail = (status: number) => R.or(R.gte(status, 300), R.lt(status, 200));

const isOptimisticLocking = R.equals(409);

function isResponseSuccess(status: number, data: Object = {}, msg: string, partialSuccessMsg: string = '') {
  if (R.and(R.gte(status, 200), R.lt(status, 300))) {
    switch (status) {
      case 200: {
        if (H.isNotNilAndNotEmpty(msg)) {
          showToastrMessageSimple('success', msg);
        }

        break;
      }
      case 202: {
        if (H.isNotNilAndNotEmpty(data.errors)) {
          showToastrMessageSimple(
            'error',
            `${R.values(data.errors).join(', ')}: ${partialSuccessMsg}`,
          );
        } else if (H.isNotNil(data.errors)) {
          // NOTE: this is for load search. If need info about each TEL discuss with back-end.
          R.values(R.omit(['errors'], data)).forEach((item: Array) => toastr.success(item.join(', ')));
        } else if (H.isObject(data)) {
          const handleItemInfo = (item: Array) => toastr.info(item.join(', '));

          R.values(data).forEach(handleItemInfo);
        } else {
          showToastrMessageSimple('success', msg);
        }

        break;
      }
      default: {
        break;
      }
    }

    return true;
  }

  return false;
}

const isPartiallySuccess = (status: number) => {
  if (R.equals(status, 202)) return true;

  return false;
};

const isNotPartiallySuccess = (status: number) => {
  if (R.equals(status, 202)) return false;

  return true;
};

const logError = (msg: string, error: string) => {
  console.error(`============================== ${msg} ==============================`, error);
};

const handleException = (error: any, msg: string) => logError(R.or(msg, 'exception'), error);

// use with sagas
function* showValidationErrors(data: Object) {
  const { errors, message, invalidFields, error_description } = data;

  if (H.isNotNil(invalidFields)) {
    yield all(
      invalidFields.map((field: Object) => (
        call(showToastrMessage, 'error', `${field.fieldName}: ${field.message}`)
      )),
    );
  } else if (H.isNotNil(message)) {
    if (H.isNotNilAndNotEmpty(errors)) {
      yield all(
        errors.map((error: Object) => (
          call(showToastrMessage, 'error', error)
        )),
      );
    } else {
      yield call(showToastrMessage, 'error', message);
    }
  } else if (H.isNotNil(error_description)) {
    yield call(showToastrMessage, 'error', error_description);
  }
}

// use with sagas
function* handleFailResponse(
  res: Object,
  msg: string,
  showMsg: boolean = true,
  clearConsole: boolean = false,
) {
  const { data, status } = res;

  let dataToUse = data;

  const isTypeArrayBuffer = H.isNotNilAndNotEmpty(getPropFromObject(ARRAY_BUFFER_BYTE_LENGTH, dataToUse));

  if (R.and(isTypeArrayBuffer, R.gte(status, 400))) {
    dataToUse = JSON.parse(
      new window.TextDecoder('utf-8').decode(new Uint8Array(dataToUse)),
    );
  }

  switch (status) {
    case 400:
    case 409: {
      if (showMsg) {
        yield call(showValidationErrors, dataToUse);
      }

      logError(status, dataToUse);

      break;
    }
    case 401: {
      if (showMsg) {
        yield call(showToastrMessage, 'error', 'messages:error:401');

        yield put(sendLogOutRequest());
      }

      logError(status, dataToUse);

      break;
    }
    case 402: {
      if (showMsg) {
        switch (dataToUse) {
          default: {
            yield call(showToastrMessage, 'error', 'messages:error:402');

            break;
          }
        }
      }

      logError(status, dataToUse);

      break;
    }
    case 403: {
      if (showMsg) {
        yield call(showToastrMessage, 'error', 'messages:error:403');
      }

      logError(status, dataToUse);

      break;
    }
    case 404: {
      if (showMsg) {
        yield call(showToastrMessage, 'error', 'messages:error:404');
      }

      logError(status, dataToUse);

      break;
    }
    case 422: {
      if (showMsg) {
        const message = R.path(['message'], dataToUse);

        if (H.isNotNilAndNotEmpty(message)) return showToastrMessageSimple('error', message);

        yield call(showToastrMessage, 'error', 'messages:error:422');
      }

      logError(status, dataToUse);

      break;
    }
    case 500: {
      if (showMsg) {
        yield call(showToastrMessage, 'error', 'messages:error:500');
      }

      logError(status, dataToUse);

      break;
    }
    default: {
      if (showMsg) {
        yield call(showToastrMessage, 'error', 'messages:error:unknown');
      }

      logError(status, dataToUse);

      break;
    }
  }

  if (R.and(clearConsole, isProduction)) {
    console.clear(); // eslint-disable-line
  }
}

// use with wait/async
const showValidationErrorsSimple = (data: Object) => {
  const { invalidFields, message, errors, error_description } = data;

  if (H.isNotNil(invalidFields)) {
    invalidFields.map((field: Object) => (
      showToastrMessageSimple('error', `${field.fieldName}: ${field.message}`)
    ));
  } else if (H.isNotNil(message)) {
    if (H.isNotNilAndNotEmpty(errors)) {
      errors.map((error: Object) => (
        showToastrMessageSimple('error', error)
      ));
    } else {
      showToastrMessageSimple('error', message);
    }
  } else if (H.isNotNil(error_description)) {
    showToastrMessageSimple('error', error_description);
  }
};

// use with wait/async
const handleFailResponseSimple = (
  res: Object,
  showMsg: boolean = true,
  msg: string,
) => {
  const { data, status } = res;

  switch (status) {
    case 400: {
      if (showMsg) {
        showValidationErrorsSimple(data);
      }

      logError(status, data);

      break;
    }
    case 401: {
      if (showMsg) {
        showToastrMessageSimple('error', getWindowLocale('messages:error:401'));
      }

      logError(status, data);

      break;
    }
    case 403: {
      if (showMsg) {
        showToastrMessageSimple('error', getWindowLocale('messages:error:403'));
      }

      logError(status, data);

      break;
    }
    case 404: {
      if (showMsg) {
        showToastrMessageSimple('error', getWindowLocale('messages:error:404'));
      }

      logError(status, data);

      break;
    }
    case 422: {
      if (showMsg) {
        showToastrMessageSimple('error', getWindowLocale('messages:error:422'));
      }

      logError(status, data);

      break;
    }
    case 500: {
      if (showMsg) {
        showToastrMessageSimple('error', getWindowLocale('messages:error:500'));
      }

      logError(status, data);

      break;
    }
    default: {
      if (showMsg) {
        showToastrMessageSimple('error', getWindowLocale('messages:error:unknown'));
      }

      logError(status, data);

      break;
    }
  }
};

const setElementsToRequestBody = (elements: Array = [], options: Object = {}) => (
  R.assocPath(['data', 'elements'], elements, options)
);

const setParamToRequestQuery = (name: string, param: any, options: Object = {}) => {
  if (R.or(R.isNil(name), R.isNil(param))) return options;

  return R.assocPath(['params', name], param, options);
};

const validationMessageWithFieldName = (
  message: string,
  prefix: string,
  fieldName: string,
) => message.replace(prefix, fieldName);

const handlePartialSuccessErrors = (errors: Object) => R.forEachObjIndexed(
  (error: string) => showToastrMessageSimple('error', error),
  errors,
);

const catchSendRequestSimple = (error: any, handlerName: string, closeLoader: Function) => {
  if (H.isFunction(closeLoader)) closeLoader();

  handleException(error, handlerName);

  showToastrMessageSimple(
    'error',
    getWindowLocale(
      'messages:error:unknown',
      'Oops! The system is experiencing a problem. The issue has been reported.',
    ),
  );
};

export {
  logError,
  isResponseFail,
  getMockResponse,
  handleException,
  parseQueryString,
  isResponseSuccess,
  handleFailResponse,
  isPartiallySuccess,
  isOptimisticLocking,
  showValidationErrors,
  isNotPartiallySuccess,
  setParamToRequestQuery,
  catchSendRequestSimple,
  handleFailResponseSimple,
  setElementsToRequestBody,
  handlePartialSuccessErrors,
  showValidationErrorsSimple,
  validationMessageWithFieldName,
};
