import { pure, compose, withState, withHandlers } from 'react-recompose';
// helpers/constants
import * as G from '../helpers';
//////////////////////////////////////////////////

export const withFilteredList = ({ filter }: Object) => compose(
  withState('originalList', 'setOriginalList', null),
  withState('filterString', 'setFilterString', null),
  withState('filteredList', 'setFilteredList', null),
  withHandlers({
    handleFilterList: (props: Object) => (filterString: string) => {
      const { originalList, setFilterString, setFilteredList } = props;
      setFilterString(filterString);
      if (G.isNilOrEmpty(filterString)) return setFilteredList(originalList);
      const newList = filter(filterString, originalList);
      setFilteredList(newList);
    },
  }),
  pure,
);
