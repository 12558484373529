import * as R from 'ramda';
// constants
import * as GC from '../constants';
import { ENUMS } from '../constants/enums';
// helpers
import { getWindowLocale } from './locale';
import { getConfigValueFromStore } from './common';
import { getCarrierRateIntegrationOptions } from './options';
import { getGuidFromObject, getDisplayedValueFromObject } from './getter';
import { isTrue, ifElse, isNilOrEmpty, isNotNilAndNotEmpty } from './helpers';
import { getItemFromWindow, getAmousConfigByNameFromWindow, getConfigUiOrderOrderEntryFromWindow } from './window';
//////////////////////////////////////////////////

const getParentGuidOrGuidFromObject = (item: Object = {}) => (
  R.or(item.parentGuid, item.guid)
);

const fromObjectDisplayedValueAndParentGuidOrGuidToLabelValue = (item: Object = {}) => ({
  [GC.FIELD_LABEL]: getDisplayedValueFromObject(item),
  [GC.FIELD_VALUE]: getParentGuidOrGuidFromObject(item),
});

const indexMappedDropdownOptionsByField = (
  fieldName: string,
  mapper: Function,
  dropdownOptions: Array,
) => R.compose(R.indexBy(R.prop(fieldName)), mapper)(dropdownOptions);

const mapDropdownConfigOptionsToObjects = R.map((item: Object) => ({
  dropdownOptionGuid: getParentGuidOrGuidFromObject(item),
  storedValue: item.storedValue,
  displayedValue: item.displayedValue,
}));

const mapConfigValuesByName = (resData: object) => ({
  configs: R.indexBy(R.prop(GC.FIELD_NAME), resData.configs),
  dropdowns: R.indexBy(R.prop(GC.FIELD_NAME), resData.dropdowns),
});

const mapConfigsByBranchesAndNames = (resData: Array) => R.reduce(
  (acc: Object, config: Object) => R.assocPath(
    [config[GC.FIELD_BRANCH_GUID], config[GC.FIELD_NAME]],
    ifElse(R.isNil(config[GC.FIELD_VALUE]), config[GC.FIELD_INHERITED_VALUE], config[GC.FIELD_VALUE]),
    acc,
  ),
  {},
  resData,
);

const getNewDoBranchConfigFromWindow = (configName: string) => getConfigValueFromStore(
  configName, getItemFromWindow('amousNewDoBranchConfigs'),
);

const getConfigValueFromStoreOrWindow = (
  configName: string, configStore: Object,
) => {
  const value = R.path(['configs', configName, 'value'], configStore);

  if (isNotNilAndNotEmpty(value)) return value;

  const fromStore = R.path(['configs', configName, 'inheritedValue'], configStore);

  if (isNotNilAndNotEmpty(fromStore)) return fromStore;

  return getAmousConfigByNameFromWindow(configName);
};

const getConfigValueFromState = (configName: string, configState: Object = {}) => {
  if (isNilOrEmpty(configName)) {
    return null;
  }

  if (R.has(configName, configState)) {
    const config = R.propOr({}, configName)(configState);

    if (isNilOrEmpty(config)) return null;

    return R.or(R.prop('value', config), R.prop('inheritedValue', config));
  }

  const config = R.find(R.propEq(configName, 'name'))(configState);

  if (isNilOrEmpty(config)) return null;

  return R.or(R.prop('value', config), R.prop('inheritedValue', config));
};

const getDisplayValueFromOptionsByStoredValue = (value: string, options: Array) => (
  R.pathOr(value, [value, 'label'], R.indexBy(R.prop('value'), options))
);

const createIndexedOptions = (defaultOptions: Array) => (
  R.indexBy((option: Object) => R.or(option.parentGuid, option.guid), defaultOptions)
);

const getFullOptionsFromDropdownConfigStore = (configStore: any, dropdownName: string) => (
  R.pathOr([], ['dropdowns', dropdownName, 'options'], configStore)
);

const mapAsyncConfigsDropdowns = (dropdowns: any) => {
  if (isNilOrEmpty(dropdowns)) return {};

  const result = {};
  R.forEachObjIndexed((value: Object, key: string) => {
    const options = R.prop('options', value);
    const mappedOptions = R.map((option: Object) => ({
      label: option.displayedValue,
      value: getParentGuidOrGuidFromObject(option),
    }), options);
    result[key] = [GC.EMPTY_OPTION_OBJECT, ...mappedOptions];
  }, dropdowns);

  return result;
};

const mapDropdownsObjectInEntity = (fieldToSet: string, fields: Array, entity: Object) => {
  if (R.and(isNilOrEmpty(entity), isNilOrEmpty(fields))) return entity;

  return R.mapObjIndexed((item: Object, key: string) => {
    if (R.and(R.includes(key, fields), R.is(Object, item))) {
      return R.prop(fieldToSet, item);
    }

    return item;
  }, entity);
};

const createOptionsFromDropdownConfig = (
  configStore: any,
  dropdownName: string,
  valueName: string = 'storedValue',
  isFirstEmpty: boolean = true,
) => {
  const configValue = R.pathOr(null, ['dropdowns', dropdownName, 'options'], configStore);

  if (isNotNilAndNotEmpty(configValue)) {
    const options = configValue.map((item: Object) => ({
      label: item.displayedValue,
      value: item[valueName],
    }));

    if (isFirstEmpty) {
      return R.concat(R.of(Array, GC.EMPTY_OPTION_OBJECT), options);
    }

    return options;
  }

  return [];
};

const getEmptyOptionWithText = (text: string) => ({
  label: R.or(text, ''), value: '',
});

const addEmptyOptionToDropDown = (options: Array, text: Object) => (
  R.prepend(getEmptyOptionWithText(text), options)
);

const getOptionsFromConfigValueByParentGuidOrGuid = (configValue: Array) => R.map((item: Object) => ({
  label: item.displayedValue,
  value: R.or(item.parentGuid, item.guid),
}), configValue);

const getServiceOptionsFromConfigAndServiceMappingList = (configValue: Array) => {
  const serviceMappingList = R.or(getItemFromWindow('serviceMappingList'), []);

  return R.map(
    (item: Object) => {
      const value = R.or(item.parentGuid, getGuidFromObject(item));
      const carriers = R.compose(
        R.join(', '),
        R.map(({ integrationType }: Object) => R.compose(
          R.pathOr('', [GC.FIELD_LABEL]),
          R.find(R.propEq(integrationType, GC.FIELD_VALUE)),
        )(getCarrierRateIntegrationOptions())),
        R.filter(R.propEq(value, GC.FIELD_SERVICE_MAPPING_SERVICE_CONFIG_GUID)),
      )(serviceMappingList);
      const displayedValue = getDisplayedValueFromObject(item);
      const label = ifElse(
        R.isEmpty(carriers),
        displayedValue,
        `${displayedValue} (${carriers})`,
      );

      return { label, value };
    },
    configValue,
  );
};

const createOptionsFromDropdownConfigWithGuidOrParentGuid = (
  configStore: any,
  dropdownName: string,
  isFirstEmpty: boolean = false,
) => {
  const configValue = R.pathOr(null, ['dropdowns', dropdownName, 'options'], configStore);

  if (isNotNilAndNotEmpty(configValue)) {
    let options;

    if (R.equals(dropdownName, GC.GENERAL_SERVICES)) {
      options = getServiceOptionsFromConfigAndServiceMappingList(configValue);
    } else {
      options = getOptionsFromConfigValueByParentGuidOrGuid(configValue);
    }

    if (isFirstEmpty) {
      return R.concat(R.of(Array, GC.EMPTY_OPTION_OBJECT), options);
    }

    return options;
  }

  return [];
};

const mapDropdownConfigWithParentGuid = (configStore: any, dropdownName: string) => {
  const configValue = R.pathOr(null, ['dropdowns', dropdownName, 'options'], configStore);

  if (isNotNilAndNotEmpty(configValue)) {
    const options = getOptionsFromConfigValueByParentGuidOrGuid(configValue);

    return [{ label: '', value: '', parentGuid: null }, ...options];
  }

  return [];
};

const mapCustomConfigOptionsFromProps = (
  configName: string,
  props: Object,
  nullable: boolean = false,
  withoutEmptyOption: boolean = false,
) => {
  if (isNilOrEmpty(R.prop(configName, props))) return [];

  const options = props[configName].map((item: Object) => ({
    label: item.displayedValue,
    value: R.or(item.parentGuid, item.guid),
  }));

  if (withoutEmptyOption) {
    return options;
  }

  const firstOptions = ifElse(
    isTrue(nullable),
    GC.EMPTY_OPTION_NULLABLE_OBJECT,
    GC.EMPTY_OPTION_OBJECT,
  );

  return [firstOptions, ...options];
};

const getCallUrl = (autodialApp: string, phone: string) => {
  // NOTE: using only tel: on Alex's request
  return `${GC.DIAL_SERVICE_DEFAULT}:${phone}`;

  // if (isNilOrEmpty(autodialApp)) return `skype:${phone}?call`;

  // const url = `${autodialApp}:${phone}`;

  // if (R.equals(autodialApp, 'skype')) return `${url}?call`;

  // return url;
};

const getRouteTelConfigsForCreateClo = (configs: Object) => {
  const autoRouteName = getConfigValueFromStore(
    GC.GENERAL_ROUTE_NAME_AUTOGENERATED,
    configs,
  );
  const autoTelPrimaryRef = getConfigValueFromStore(
    GC.TEL_PRIMARY_REFERENCE_AUTOGENERATED,
    configs,
  );
  const telPrimCopyFromClo = getConfigValueFromStore(
    GC.TEL_PRIMARY_REFERENCE_COPY_FROM_CLO,
    configs,
  );

  return {
    autoRouteName,
    autoTelPrimaryRef,
    telPrimCopyFromClo,
  };
};


const shouldHideRouteTelModalOnCreateClo = (routeTelConfigs: Object) => {
  const { autoRouteName, autoTelPrimaryRef, telPrimCopyFromClo } = routeTelConfigs;

  return R.and(
    R.and(isTrue(autoRouteName), isTrue(autoTelPrimaryRef)),
    R.or(isTrue(telPrimCopyFromClo), isTrue(autoTelPrimaryRef)),
  );
};

const transformGuidToRequest = (guid: string = '', options: Array = []) => {
  const option = R.find(
    (item: Object) => R.or(
      R.propEq(guid, GC.FIELD_PARENT_GUID, item),
      R.propEq(guid, GC.FIELD_GUID, item),
    ),
    options,
  );

  if (isNilOrEmpty(option)) return null;

  return R.assoc(
    GC.FIELD_DROPDOWN_OPTION_GUID,
    getParentGuidOrGuidFromObject(option),
    R.pick([GC.FIELD_STORED_VALUE, GC.FIELD_DISPLAYED_VALUE], option),
  );
};

// TODO: check using it
const getDefaultBranchCloItemValues = (branchConfigs: Object) => {
  const uomSystem = getConfigValueFromStore(
    GC.GENERAL_UOM_CALC_DEFAULT_UOM_SYSTEM, branchConfigs,
  );

  const weightUOM = getConfigValueFromStore(
    GC.CLO_ITEM_DEFAULT_WEIGHT_UOM, branchConfigs,
  );

  const temperatureUOM = getConfigValueFromStore(
    GC.CLO_ITEM_DEFAULT_TEMPERATURE_UOM, branchConfigs,
  );

  const packageType = getConfigValueFromStore(
    GC.CLO_ITEM_DEFAULT_PACKAGE_TYPE, branchConfigs,
  );

  const branchItemValues = R.pathOr(GC.defaultImperialItemUoms, [uomSystem], GC.defaultItemUoms);

  return {
    [GC.FIELD_ITEM_WEIGHT_TYPE]: R.or(
      weightUOM,
      R.path([GC.FIELD_ITEM_WEIGHT_TYPE], branchItemValues),
    ),
    [GC.FIELD_ITEM_PACKAGE_TYPE]: R.or(
      packageType,
      R.path([GC.FIELD_ITEM_PACKAGE_TYPE], branchItemValues),
    ),
    [GC.FIELD_ITEM_TEMPERATURE_UOM]: R.or(
      temperatureUOM,
      R.path([GC.FIELD_ITEM_TEMPERATURE_UOM], branchItemValues),
    ),
  };
};

const getDefaultBranchCloItemValuesFromConfigs = (branchConfigs: Object) => {
  const weightUOM = getConfigValueFromStore(
    GC.CLO_ITEM_DEFAULT_WEIGHT_UOM, branchConfigs,
  );

  const temperatureUOM = getConfigValueFromStore(
    GC.CLO_ITEM_DEFAULT_TEMPERATURE_UOM, branchConfigs,
  );

  const packageType = getConfigValueFromStore(
    GC.CLO_ITEM_DEFAULT_PACKAGE_TYPE, branchConfigs,
  );

  const defaultCountry = getConfigValueFromStore(
    GC.CLO_ITEM_DEFAULT_COUNTRY, branchConfigs,
  );

  const itemDefaultType = getConfigValueFromStore(
    GC.CLO_ITEM_DEFAULT_TYPE,
    branchConfigs,
  );

  return {
    [GC.FIELD_ITEM_WEIGHT_TYPE]: weightUOM,
    [GC.FIELD_ITEM_PACKAGE_TYPE]: packageType,
    [GC.FIELD_ORIGIN_COUNTRY]: defaultCountry,
    [GC.FIELD_ITEM_TEMPERATURE_UOM]: temperatureUOM,
    [GC.FIELD_ITEM_TYPE]: R.or(itemDefaultType, GC.ITEM_TYPE_GENERAL),
  };
};

const mapDropdownObjectPropsToLabelValueObject = R.map(
  ({ displayedValue, dropdownOptionGuid }: Object) => ({ label: displayedValue, value: dropdownOptionGuid }),
);

const mapDisplayedValueOriginalConfigGuidObjectPropsToLabelValueObject = (
  data: Array,
  withStoredValue: boolean = false,
) => R.map(({ storedValue, displayedValue, originalConfigGuid }: Object) => ({
  [GC.FIELD_VALUE]: originalConfigGuid,
  [GC.FIELD_LABEL]: `${displayedValue}${ifElse(withStoredValue, ` (${storedValue})`, '')}`,
}), data);

const hasConfigName = (config: Object, configName: string) =>
  R.has(configName, R.or(config, {}));

const checkIsConfigNotExisted = (configName: string, initialValues: Object, inheritedValues: Object) =>
  R.not(R.and(hasConfigName(initialValues, configName), hasConfigName(inheritedValues, configName)));

const isImperialUomSystem = () => {
  const uomSystem = getAmousConfigByNameFromWindow(GC.GENERAL_UOM_CALC_DEFAULT_UOM_SYSTEM);

  return R.equals(uomSystem, GC.IMPERIAL_SYSTEM);
};

const isMetricUomSystem = () => {
  const uomSystem = getAmousConfigByNameFromWindow(GC.GENERAL_UOM_CALC_DEFAULT_UOM_SYSTEM);

  return R.equals(uomSystem, GC.METRIC_SYSTEM);
};

const getMileOrKmUomByUomSystem = (uomSystem: any) => {
  const uomSystemToUse = R.or(uomSystem, getAmousConfigByNameFromWindow(GC.GENERAL_UOM_CALC_DEFAULT_UOM_SYSTEM));

  if (R.equals(uomSystemToUse, GC.METRIC_SYSTEM)) return GC.UOM_KILOMETER;

  return GC.UOM_MILE;
};

const getFootOrMeterUomByUomSystem = () => ifElse(isMetricUomSystem(), GC.UOM_METER, GC.UOM_FOOT);

const shouldGetPrimaryRefSequenceFromBranchConfigs = (branchConfigs: Object) => {
  const primaryReferenceSequenceGuid = getConfigValueFromStore(
    GC.CLO_PRIMARY_REFERENCE_SEQUENCE, branchConfigs,
  );

  const cloPrimaryRefAutogenerated = getConfigValueFromStore(
    GC.CLO_PRIMARY_REFERENCE_AUTOGENERATED, branchConfigs,
  );

  return R.and(
    isTrue(cloPrimaryRefAutogenerated),
    isNotNilAndNotEmpty(primaryReferenceSequenceGuid),
  );
};

const isQuickOrderEntry = () => {
  const config = getConfigUiOrderOrderEntryFromWindow();

  return R.equals(config, ENUMS.ENUM_QUICK);
};

const getModeServiceFromAsyncConfigsForRateSelect = (props: Object) => {
  const transportationMode = createOptionsFromDropdownConfigWithGuidOrParentGuid(
    props.asyncConfigs,
    GC.GENERAL_MODE_TRANSPORTATION,
    true,
  );

  const serviceType = createOptionsFromDropdownConfigWithGuidOrParentGuid(
    props.asyncConfigs,
    GC.GENERAL_TRANSPORTATION_SERVICE_TYPE,
    true,
  );

  return { serviceType, transportationMode };
};

const getModeServiceGLCodeFromAsyncConfigsForRateSelect = (props: Object) => {
  const transportationMode = createOptionsFromDropdownConfigWithGuidOrParentGuid(
    props.asyncConfigs,
    GC.GENERAL_MODE_TRANSPORTATION,
    true,
  );

  const serviceType = createOptionsFromDropdownConfigWithGuidOrParentGuid(
    props.asyncConfigs,
    GC.GENERAL_TRANSPORTATION_SERVICE_TYPE,
    true,
  );

  const glCode = createOptionsFromDropdownConfigWithGuidOrParentGuid(
    props.asyncConfigs,
    GC.INVOICE_GL_CODE,
    true,
  );

  return { glCode, serviceType, transportationMode };
};

const getModeGLCodeFromAsyncConfigsForRateSelect = (props: Object) => {
  const transportationMode = createOptionsFromDropdownConfigWithGuidOrParentGuid(
    props.asyncConfigs,
    GC.GENERAL_MODE_TRANSPORTATION,
    true,
  );

  const glCode = createOptionsFromDropdownConfigWithGuidOrParentGuid(
    props.asyncConfigs,
    GC.INVOICE_GL_CODE,
    true,
  );

  return { glCode, transportationMode };
};


const getModeServiceFromBranchConfigsForRateSelect = (props: Object) => {
  const transportationMode = createOptionsFromDropdownConfigWithGuidOrParentGuid(
    props.branchConfigs,
    GC.GENERAL_MODE_TRANSPORTATION,
    true,
  );

  const serviceType = createOptionsFromDropdownConfigWithGuidOrParentGuid(
    props.branchConfigs,
    GC.GENERAL_TRANSPORTATION_SERVICE_TYPE,
    true,
  );

  return { serviceType, transportationMode };
};

const getDistanceUomLocale = () => {
  if (isMetricUomSystem()) return getWindowLocale('titles:km', 'Km');

  return getWindowLocale('titles:miles', 'Miles');
};

const makeTransportationGroupingOptions = (guid: string, list: Object, branchConfigs: Object, values: Object) => {
  const grouping = R.prop(guid, list);

  if (R.isNil(grouping)) {
    return {
      serviceOptions: createOptionsFromDropdownConfigWithGuidOrParentGuid(branchConfigs, GC.GENERAL_SERVICES),
      equipmentOptions: createOptionsFromDropdownConfigWithGuidOrParentGuid(branchConfigs, GC.GENERAL_EQUIPMENTS),
    };
  }

  const { services, equipments } = grouping;

  const { services: existingServices, equipment: existingEquipment } = R.or(values, {});

  const serviceOptions = R.compose(
    getServiceOptionsFromConfigAndServiceMappingList,
    R.filter(({ guid, parentGuid }: Object) => R.includes(
      R.or(parentGuid, guid),
      R.concat(services, R.or(existingServices, [])),
    )),
    R.pathOr([], ['dropdowns', GC.GENERAL_SERVICES, 'options']),
  )(branchConfigs);

  const equipmentOptions = R.compose(
    getOptionsFromConfigValueByParentGuidOrGuid,
    R.filter(({ guid, parentGuid }: Object) => R.includes(
      R.or(parentGuid, guid),
      R.append(existingEquipment, equipments),
    )),
    R.pathOr([], ['dropdowns', GC.GENERAL_EQUIPMENTS, 'options']),
  )(branchConfigs);

  return { serviceOptions, equipmentOptions };
};

export {
  getCallUrl,
  isMetricUomSystem,
  isQuickOrderEntry,
  isImperialUomSystem,
  getDistanceUomLocale,
  createIndexedOptions,
  mapConfigValuesByName,
  transformGuidToRequest,
  getEmptyOptionWithText,
  checkIsConfigNotExisted,
  getConfigValueFromState,
  addEmptyOptionToDropDown,
  mapAsyncConfigsDropdowns,
  getMileOrKmUomByUomSystem,
  mapDropdownsObjectInEntity,
  getFootOrMeterUomByUomSystem,
  mapConfigsByBranchesAndNames,
  getParentGuidOrGuidFromObject,
  getDefaultBranchCloItemValues,
  getRouteTelConfigsForCreateClo,
  getNewDoBranchConfigFromWindow,
  mapCustomConfigOptionsFromProps,
  createOptionsFromDropdownConfig,
  getConfigValueFromStoreOrWindow,
  mapDropdownConfigWithParentGuid,
  indexMappedDropdownOptionsByField,
  mapDropdownConfigOptionsToObjects,
  makeTransportationGroupingOptions,
  shouldHideRouteTelModalOnCreateClo,
  getFullOptionsFromDropdownConfigStore,
  getDisplayValueFromOptionsByStoredValue,
  mapDropdownObjectPropsToLabelValueObject,
  getDefaultBranchCloItemValuesFromConfigs,
  getModeGLCodeFromAsyncConfigsForRateSelect,
  getOptionsFromConfigValueByParentGuidOrGuid,
  getModeServiceFromAsyncConfigsForRateSelect,
  getModeServiceFromBranchConfigsForRateSelect,
  shouldGetPrimaryRefSequenceFromBranchConfigs,
  getServiceOptionsFromConfigAndServiceMappingList,
  getModeServiceGLCodeFromAsyncConfigsForRateSelect,
  createOptionsFromDropdownConfigWithGuidOrParentGuid,
  fromObjectDisplayedValueAndParentGuidOrGuidToLabelValue,
  mapDisplayedValueOriginalConfigGuidObjectPropsToLabelValueObject,
};
