import { compose, withState, lifecycle, withHandlers } from 'react-recompose';
// helpers/constants
import * as G from '../helpers';
import * as GC from '../constants';
// utilities
import { sendRequest } from '../utilities/http';
import endpointsMap from '../utilities/endpoints';
/////////////////////////////////////////////////

export const withAsyncLoadReferences = compose(
  withState('asyncReferences', 'setAsyncReferences', null),
  withState('asyncRefLoading', 'setAsyncRefLoading', true),
  withHandlers({
    getAsyncReferences: (props: Object) => async () => {
      const {
        loadGuid,
        loadType,
        setAsyncReferences,
        setAsyncRefLoading,
      } = props;

      const isClo = G.isLoadTypeClo(loadType);
      const propName = G.ifElse(isClo, GC.FIELD_CLO_GUID, GC.FIELD_TEL_GUID);
      const endpoint = G.ifElse(isClo, endpointsMap.cloReferenceList, endpointsMap.telReferenceList);
      const options = { params: { [propName]: loadGuid } };

      const res = await sendRequest('get', endpoint, options);

      const { data, status } = res;

      if (G.isResponseSuccess(status)) {
        setAsyncReferences(data, () => setAsyncRefLoading(false));
      } else {
        G.handleFailResponseSimple(res);
      }
    },
  }),
  lifecycle({
    componentDidMount() {
      this.props.getAsyncReferences();
    },
  }),
);

export const withAsyncCloReferenceByEventGuids = compose(
  withState('asyncCloReferenceByEventGuids', 'setAsyncCloReferenceByEventGuids', null),
  withState('asyncCloReferenceByEventGuidsLoading', 'setAsyncCloReferenceByEventGuidsLoading', true),
  withHandlers({
    getAsyncCloReferenceByEventGuids: (props: Object) => async (eventGuids: any) => {
      const {
        setAsyncCloReferenceByEventGuids,
        setAsyncCloReferenceByEventGuidsLoading,
      } = props;

      if (G.isNilOrEmpty(eventGuids)) return;

      const options = { data: eventGuids };

      const res = await sendRequest('post', endpointsMap.cloReferenceByEventGuids, options);

      const { data, status } = res;

      if (G.isResponseSuccess(status)) {
        setAsyncCloReferenceByEventGuids(data, () => setAsyncCloReferenceByEventGuidsLoading(false));
      } else {
        G.handleFailResponseSimple(res);
      }
    },
  }),
);
