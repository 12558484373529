import * as R from 'ramda';
import shortid from 'shortid';
import { v4 as uuidv4 } from 'uuid';
//////////////////////////////////////////////////

const genShortId = () => (
  shortid.generate()
);

const generateGuid = () => uuidv4();

const setGuidToArrayObjectItems = (arr: Array = []) => R.map(
  (item: Object = {}) => R.assoc('guid', generateGuid(), item),
  arr,
);

export {
  genShortId,
  generateGuid,
  setGuidToArrayObjectItems,
};
