import React from 'react';
import * as R from 'ramda';
import { compose, withHandlers } from 'react-recompose';
// features
import { AsyncEditDriverInvoiceForm } from '../features/invoice/components/driver-form';
// helpers/constants
import * as G from '../helpers';
import * as GC from '../constants';
// utilities
import endpointsMap from '../utilities/endpoints';
//////////////////////////////////////////////////

const withHandleUpdateDriverInvoice = (updateFrom: string) => withHandlers({
  handleUpdateDriverInvoice: (props: Object) => (values: Object) => {
    const { updateDriverInvoiceRequest } = props;

    const data = {
      updateFrom,
      invoice: values,
    };

    updateDriverInvoiceRequest(data);
  },
});

const withAsyncEditDriverInvoice = (options: any) => compose(
  withHandlers({
    handleUpdateDriverInvoice: (props: Object) => (values: Object) => {
      const { updateDriverInvoiceRequest } = props;

      const updateFrom = R.path(['updateFrom'], options);

      const data = {
        updateFrom,
        invoice: values,
      };

      updateDriverInvoiceRequest(data);
    },
  }),
  withHandlers({
    handleEditDriverInvoice: (props: Object) => (item: Object | string, item2: Object) => {
      const { load, openModal, closeModal, handleUpdateDriverInvoice } = props;

      const invoice = R.and(G.isString(item), G.isObject(item2)) ? item2 : item;

      const updateFrom = R.path(['updateFrom'], options);

      const configsNamesArray = [
        GC.GENERAL_MODE_TRANSPORTATION,
        GC.GENERAL_UOM_CALC_DEFAULT_UOM_SYSTEM,
        GC.GENERAL_TRANSPORTATION_SERVICE_TYPE,
      ];

      const totalQuantities = 0;
      const customerLineHaul = 0;

      const totalCustomersRate = G.getTotalCustomersRate(props, updateFrom);
      const stopsQuantity = R.length(R.pathOr([], [GC.FIELD_LOAD_STOPS], load));

      const guid = G.getGuidFromObject(invoice);

      const branchGuid = R.or(
        R.pathOr(G.getBranchGuidFromObject(invoice), ['branchGuid'], props),
        G.getBranchGuidFromObject(load),
      );

      const condition = R.or(
        G.isInvoiceTypeFleetVendorInvoice(G.getPropFromObject(GC.FIELD_TYPE, invoice)),
        G.isNotNil(G.getPropFromObject(GC.FIELD_FLEET_VENDOR_PAYROLL_GUID, invoice)),
      );

      const endpoint = G.ifElse(
        condition,
        endpointsMap.getFleetVendorInvoiceItemEndpoint(guid),
        endpointsMap.getTelInvoiceItemEndpoint(guid),
      );

      const modalContent = (
        <AsyncEditDriverInvoiceForm
          closeModal={closeModal}
          branchGuid={branchGuid}
          asyncEndpoint={endpoint}
          stopsQuantity={stopsQuantity}
          totalQuantities={totalQuantities}
          customerLineHaul={customerLineHaul}
          configsNamesArray={configsNamesArray}
          totalCustomersRate={totalCustomersRate}
          handleSendFleetInvoice={handleUpdateDriverInvoice}
        />
      );

      const modal = {
        component: modalContent,
        options: {
          width: 900,
          height: 'auto',
          overflow: 'auto',
          maxHeight: '90vh',
        },
        p: '0',
      };

      openModal(modal);
    },
  }),
);

export {
  withAsyncEditDriverInvoice,
  withHandleUpdateDriverInvoice,
};
