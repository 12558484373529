import { useMemo } from 'react';
import { useDispatch } from 'react-redux';
// components
import { openModal, closeModal } from '../components/modal/actions';
import { openLoader, closeLoader } from '../components/loader/actions';
// features
import { setInitialExpandedContainer, setExpandedContainerOptions } from '../features/expanded-container/actions';
// hooks
export * from './use-async-endpoint';
//////////////////////////////////////////////////

const useModalActions = () => {
  const dispatch = useDispatch();

  const actions = useMemo(() => ({
    closeModal: () => dispatch(closeModal()),
    openModal: (...args: Array) => dispatch(openModal(...args)),
  }), [dispatch]);

  return actions;
};

const useLoaderActions = () => {
  const dispatch = useDispatch();

  const actions = useMemo(() => ({
    openLoader: () => dispatch(openLoader()),
    closeLoader: () => dispatch(closeLoader()),
  }), [dispatch]);

  return actions;
};

const useModalAndLoaderActions = () => {
  const dispatch = useDispatch();

  const actions = useMemo(() => ({
    openLoader: () => dispatch(openLoader()),
    closeModal: () => dispatch(closeModal()),
    closeLoader: () => dispatch(closeLoader()),
    openModal: (...args: Array) => dispatch(openModal(...args)),
  }), [dispatch]);

  return actions;
};

const useExpandedContainerActions = () => {
  const dispatch = useDispatch();

  const actions = useMemo(() => ({
    setInitialExpandedContainer: () => dispatch(setInitialExpandedContainer()),
    setExpandedContainerOptions: (...args: Array) => dispatch(setExpandedContainerOptions(...args)),
  }), [dispatch]);

  return actions;
};

export {
  useModalActions,
  useLoaderActions,
  useModalAndLoaderActions,
  useExpandedContainerActions,
};
