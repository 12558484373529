import * as R from 'ramda';
import * as P from 'plow-js';
// helpers
import { isNilOrEmpty } from './helpers';
//////////////////////////////////////////////////

const storeGetTelNotificationsSuccess = (state: Object, results: Array) => {
  if (isNilOrEmpty(results)) return state;

  let newState = state;

  results.forEach(({ guid, read, notification, warningHidden }: Object) => {
    newState = R.assocPath(
      ['telNotifications', notification.objectGuid, notification.guid],
      R.mergeRight(
        notification,
        {
          read,
          warningHidden,
          selected: false,
          userNotificationGuid: guid,
        }),
      newState,
    );
  });

  return newState;
};

const storeTelNotificationReceived = (state: Object, { guid, notification }: Object) => (
  P.$set(
    `telNotifications.${notification.objectGuid}.${notification.guid}`,
    R.mergeRight(
      notification,
      {
        read: false,
        selected: false,
        warningHidden: false,
        userNotificationGuid: guid,
      },
    ),
    state,
  )
);

const storeHideTelNotificationsSuccess = (state: Object, telGuid: string) => (
  P.$set(
    `telNotifications.${telGuid}`,
    R.map(
      (notification: Object) => R.assoc('warningHidden', true, notification),
      R.path(['telNotifications', telGuid], state),
    ),
    state,
  )
);

export {
  storeTelNotificationReceived,
  storeGetTelNotificationsSuccess,
  storeHideTelNotificationsSuccess,
};
