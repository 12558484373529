import * as G from '../helpers';
import React, { useState, useCallback, useEffect } from 'react';
//////////////////////////////////////////////////

export const withWindowSize = (ComposedComponent: any) => {
  const WindowSizeComponent = (props: Object) => {
    const [windowSize, setWindowSize] = useState({
      width: window.innerWidth,
      height: window.innerHeight,
    });

    const resizeHandler = useCallback(
      G.setDebounce(() => {
        setWindowSize({
          width: window.innerWidth,
          height: window.innerHeight,
        });
      }, 200), [],
    );

    useEffect(() => {
      window.addEventListener('resize', resizeHandler);

      return () => {
        window.removeEventListener('resize', resizeHandler);
      };
    }, []);

    return (
      <ComposedComponent
        windowWidth={windowSize.width}
        windowHeight={windowSize.height}
        {...props}
      />
    );
  };

  return WindowSizeComponent;
};
