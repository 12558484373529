import * as R from 'ramda';
import * as P from 'plow-js';
import React, { Component } from 'react';
// helpers/constants
import * as G from '../helpers';
import * as GC from '../constants';
// utilities
import { sendRequest } from '../utilities/http';
import endpointsMap from '../utilities/endpoints';
//////////////////////////////////////////////////

// TODO: recreate as functional component

class AvailableDocumentTypes extends Component {
  constructor(props: Object) {
    super(props);
    this.state = { availableDocumentTypes: [], availableDocumentTypesOptionsAsync: [] };
    this.getAvailableDocumentTypesRequest = this.getAvailableDocumentTypesRequest.bind(this);
  }

  componentDidMount() {
    this.getAvailableDocumentTypesRequest(this.props);
  }

  async getAvailableDocumentTypesRequest(props: Object) {
    const { branchGuid } = props;

    const branchGuidToUse = G.ifElse(
      G.isNotNilAndNotEmpty(branchGuid),
      branchGuid,
      G.getAmousCurrentBranchGuidFromWindow(),
    );

    const options = {
      params: {
        [GC.FIELD_BRANCH_GUID]: branchGuidToUse,
      },
    };

    const res = await sendRequest('get', endpointsMap.availableDocumentTypes, options);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      const newState = P.$all(
        P.$set('availableDocumentTypes', R.or(data, [])),
        P.$set('availableDocumentTypesOptionsAsync', G.getOptionsFromConfigValueByParentGuidOrGuid(R.or(data, []))),
        this.state,
      );

      this.setState(newState);
    } else {
      G.handleFailResponseSimple(
        res,
        false,
        'withAsyncAvailableDocumentTypes -> getAvailableDocumentTypesRequest',
      );
    }
  }

  render() {
    return <div>{this.props.render(this.state, this.getAvailableDocumentTypesRequest)}</div>;
  }
}

export const withAsyncAvailableDocumentTypes = (Component: any) => {
  return class extends React.Component {
    render() {
      return (
        <AvailableDocumentTypes
          telGuid={this.props.telGuid}
          branchGuid={this.props.branchGuid}
          render={(parentState: Object) =>
            <Component
              {...this.props}
              availableDocumentTypes={parentState.availableDocumentTypes}
              availableDocumentTypesOptionsAsync={parentState.availableDocumentTypesOptionsAsync}
            />
          }
        />
      );
    }
  };
};
