import * as R from 'ramda';
import { pure, compose, withState, lifecycle, withHandlers } from 'react-recompose';
// helpers/constants
import * as G from '../helpers';
//////////////////////////////////////////////////

const getTop = (pageY: number, posY: number) => {
  const top = R.subtract(pageY, posY);
  if (R.lte(top, 30)) return 30;
  if (R.gt(top, window.innerHeight)) return window.innerHeight;
  return top;
};

const getLeft = (pageX: number, posX: number) => {
  const left = R.subtract(pageX, posX);
  if (R.lte(left, 1)) return 1;
  if (R.gt(left, R.subtract(window.innerWidth, 30))) return R.subtract(window.innerWidth, 30);
  return left;
};

export const withDraggableHOC = compose(
  withState('isDragging', 'setIsDragging', false),
  withState('top', 'setTop', ({ top }: Object) => top),
  withState('positionProps', 'setPositionProps', null),
  withState('left', 'setLeft', ({ left }: Object) => left),
  withState('initialWidth', 'setInitialWidth', ({ initWidth }: Object) => R.or(initWidth, 'auto')),
  withState('initialHeight', 'setInitialHeight', ({ initHeight }: Object) => R.or(initHeight, 'auto')),
  withHandlers(() => {
    const refs = {};
    return {
      handleRegisterChild: () => (ref: Object, refName: string) => (
        refs[refName] = ref
      ),
      handleMouseDown: (props: Object) => (event: Object) => {
        if (G.notEquals(event.button, 0)) return;
        const modal = refs.movableContainer;
        props.setIsDragging(true);
        props.setPositionProps({
          y: R.subtract(event.pageY, modal.offsetTop),
          x: R.subtract(event.pageX, modal.offsetLeft),
        });
      },
      handleMouseMove: (props: Object) => (event: Object) => {
        const elements = ['INPUT', 'TEXTAREA'];
        if (R.includes(R.path(['target', 'tagName'], event), elements)) return;
        if (props.isDragging) {
          const top = getTop(event.pageY, props.positionProps.y);
          const left = getLeft(event.pageX, props.positionProps.x);
          props.setTop(top);
          props.setLeft(left);
        }
        event.preventDefault();
        G.stopPropagation(event);
      },
      handleMouseUp: (props: Object) => (event: Event) => {
        G.stopPropagation(event);
        props.setIsDragging(false);
        document.removeEventListener('mousemove', props.handleMouseMove);
      },
    };
  }),
  lifecycle({
    componentDidMount() {
      document.addEventListener('mouseup', this.props.handleMouseUp);
      document.addEventListener('mousemove', this.props.handleMouseMove);
    },
    componentWillUnmount() {
      document.removeEventListener('mouseup', this.props.handleMouseUp);
      document.removeEventListener('mousemove', this.props.handleMouseMove);
    },
  }),
  pure,
);

export default withDraggableHOC;
