import * as R from 'ramda';
// constants
import * as GC from '../constants';
// utilities
import endpointsMap from '../utilities/endpoints';
import { sendRequest, sendRequestWithQSParamsSerializer } from '../utilities/http';
// helpers
import { mapConfigValuesByName } from './';
import { getPropFromObject } from './getter';
import { handleException, isResponseSuccess } from './ajax';
import { isNilOrEmpty, isNotNilAndNotEmpty } from './helpers';
import { getAmousCurrentBranchGuidFromWindow } from './window';
import { getImageSrcFromArrayBufferResponse, openFileInWindowFromArrayBufferResponse } from './file';
//////////////////////////////////////////////////

const asyncGetAccessorialsList = async (branchGuid: string) => {
  try {
    const options = {
      params: {
        [GC.FIELD_BRANCH_GUID]: branchGuid,
      },
    };

    const { data, status } = await sendRequest('get', endpointsMap.accessorialsListEndpoint, options);

    if (isResponseSuccess(status)) {
      return R.or(data, []);
    }

    return [];
  } catch (error) {
    handleException('error', 'asyncGetAccessorialsList exception');
  }
};

const asyncGetVinLookupConfig = async (branchGuid: string) => {
  try {
    if (isNilOrEmpty(branchGuid)) return null;

    const options = {
      params: {
        [GC.FIELD_BRANCH_GUID]: branchGuid,
      },
    };

    const { data, status } = await sendRequest('get', endpointsMap.fleetVinLookupConfig, options);

    if (isResponseSuccess(status)) {
      return data;
    }

    return null;
  } catch (error) {
    handleException('error', 'asyncGetVinLookupConfig exception');
  }
};

const asyncGetConfigsByNames = async (props: Object) => {
  try {
    const names = getPropFromObject('names', props);
    const branchGuid = R.pathOr(getAmousCurrentBranchGuidFromWindow(), ['branchGuid'], props);

    const params = { names: R.join(',', [names]), [GC.FIELD_BRANCH_GUID]: branchGuid };

    const { data, status } = await sendRequest('get', endpointsMap.branchConfigsEndpoint, { params });

    if (isResponseSuccess(status)) {
      return mapConfigValuesByName(data);
    }

    return null;
  } catch (error) {
    handleException('error', 'asyncGetConfigsByNames exception');
  }
};

const asyncGetFileAndCreateSrc = async (endpoint: string) => {
  try {
    const options = {
      resType: 'arraybuffer',
    };
    const res = await sendRequestWithQSParamsSerializer('get', endpoint, options);

    const { data, status } = res;

    if (R.and(isNotNilAndNotEmpty(data), isResponseSuccess(status))) {
      const src = getImageSrcFromArrayBufferResponse(res);

      return src;
    }

    return null;
  } catch (error) {
    handleException('error', 'asyncGetFileAndCreateSrc exception');
  }
};

const asyncGetFileAndOpenInWindow = async (endpoint: string) => {
  try {
    const options = {
      resType: 'arraybuffer',
    };
    const res = await sendRequestWithQSParamsSerializer('get', endpoint, options);

    const { data, status } = res;

    if (R.and(isNotNilAndNotEmpty(data), isResponseSuccess(status))) {
      openFileInWindowFromArrayBufferResponse(res);
    }
  } catch (error) {
    handleException('error', 'asyncGetFileAndOpenInWindow exception');
  }
};

export {
  asyncGetConfigsByNames,
  asyncGetVinLookupConfig,
  asyncGetAccessorialsList,
  asyncGetFileAndCreateSrc,
  asyncGetFileAndOpenInWindow,
};
