import React from 'react';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { pure, compose, withProps, withState, lifecycle, withHandlers } from 'react-recompose';
// components
import { openModal } from '../components/modal/actions';
import { ConfirmComponent } from '../components/confirm';
import { openLoader, closeLoader } from '../components/loader/actions';
// features
import PC from '../features/permission/role-permission';
import { makeSelectAuthorities } from '../features/permission/selectors';
// helpers/constants
import * as G from '../helpers';
import * as GC from '../constants';
import { ENUMS } from '../constants/enums';
// utilities
import { sendRequest } from '../utilities/http';
import endpointsMap from '../utilities/endpoints';
// hocs
import { withLoadingState } from './common';
//////////////////////////////////////////////////

const getEndpoint = (branchGuid: string) => {
  if (branchGuid) return branchGuid;

  const branchGuidFromWindow = G.getAmousCurrentBranchGuidFromWindow();

  return endpointsMap.getBranchChildrenWithShared(branchGuidFromWindow);
};

export const withAsyncGetBranchChildrenWithShared = compose(
  connect(null, { openLoader, closeLoader }),
  withState('asyncBranchChildrenWithShared', 'setAsyncBranchChildrenWithShared', []),
  withHandlers({
    getBranchChildrenWithShared: (props: Object) => async (payload: Object) => {
      const { openLoader, closeLoader } = props;

      openLoader();

      const { setAsyncBranchChildrenWithShared } = props;

      const branchGuid = R.path(['branchGuid'], payload);

      const endpoint = getEndpoint(branchGuid);

      const res = await sendRequest('get', endpoint);

      const { data, status } = res;

      if (G.isResponseSuccess(status)) {
        setAsyncBranchChildrenWithShared(data);
      } else {
        G.handleFailResponseSimple(res);
      }

      closeLoader();
    },
  }),
  pure,
);

export const withAsyncGetCarrierNameMap = compose(
  connect(null, { openLoader, closeLoader }),
  withState('asyncCarrierNameMap', 'setAsyncCarrierNameMap', []),
  withHandlers({
    getAsyncCarrierNameMap: (props: Object) => async (payload: Object) => {
      const { openLoader, closeLoader } = props;
      openLoader();
      const { setAsyncCarrierNameMap } = props;
      const branchGuid = R.path(['branchGuid'], payload);
      const branchGuidToUse = G.ifElse(
        G.isNilOrEmpty(branchGuid),
        G.getAmousCurrentBranchGuidFromWindow(),
        branchGuid,
      );
      const endpoint = endpointsMap.carrierNameMap;
      const options = {
        params: {
          [GC.FIELD_BRANCH_GUID]: branchGuidToUse,
        },
      };
      const res = await sendRequest('get', endpoint, options);
      const { data, status } = res;
      if (G.isResponseSuccess(status)) {
        setAsyncCarrierNameMap(data);
      } else {
        G.handleFailResponseSimple(res);
      }
      closeLoader();
    },
  }),
  pure,
);

export const withAsyncGetDriverListFullName = compose(
  connect(null, { openLoader, closeLoader }),
  withState('asyncDriverListFullName', 'setAsyncDriverListFullName', []),
  withHandlers({
    getAsyncGetDriverListFullName: (props: Object) => async (payload: Object) => {
      const { openLoader, closeLoader } = props;
      openLoader();
      const { setAsyncDriverListFullName } = props;
      const branchGuid = R.path(['branchGuid'], payload);
      const branchGuidToUse = G.ifElse(
        G.isNilOrEmpty(branchGuid),
        G.getAmousCurrentBranchGuidFromWindow(),
        branchGuid,
      );
      const endpoint = endpointsMap.listFleetDriversFullName;
      const options = {
        params: {
          [GC.FIELD_BRANCH_GUID]: branchGuidToUse,
        },
      };
      const res = await sendRequest('get', endpoint, options);
      const { data, status } = res;
      if (G.isResponseSuccess(status)) {
        setAsyncDriverListFullName(data);
      } else {
        G.handleFailResponseSimple(res);
      }
      closeLoader();
    },
  }),
  pure,
);

const authoritiesMapStateToProps = (state: Object) => createStructuredSelector({
  authorities: makeSelectAuthorities(state),
});

export const withAsyncGetUserGeneralListFullName = compose(
  connect(authoritiesMapStateToProps, { openLoader, closeLoader }),
  withState('asyncUserGeneralListFullName', 'setAsyncUserGeneralListFullName', []),
  withHandlers({
    getAsyncUserGeneralListFullName: (props: Object) => async (payload: Object) => {
      const { openLoader, closeLoader, authorities } = props;

      const permissions = [PC.USER_WRITE];
      const permission = G.checkStringsContains(permissions, authorities);
      if (R.not(permission)) return;
      openLoader();
      const { setAsyncUserGeneralListFullName } = props;
      const branchGuid = R.path(['branchGuid'], payload);
      const branchGuidToUse = G.ifElse(
        G.isNilOrEmpty(branchGuid),
        G.getAmousCurrentBranchGuidFromWindow(),
        branchGuid,
      );
      const endpoint = endpointsMap.listUsersGeneral;
      const options = {
        params: {
          [GC.FIELD_BRANCH_GUID]: branchGuidToUse,
        },
      };
      const res = await sendRequest('get', endpoint, options);
      const { data, status } = res;
      if (G.isResponseSuccess(status)) {
        setAsyncUserGeneralListFullName(data);
      } else {
        G.handleFailResponseSimple(res);
      }
      closeLoader();
    },
  }),
  pure,
);

export const withAsyncGetUserGeneralListFullNameOnDidMount = compose(
  withAsyncGetUserGeneralListFullName,
  lifecycle({
    componentDidMount() {
      this.props.getAsyncUserGeneralListFullName();
    },
  }),
);

export const withAsyncGetDispatchingGroupList = compose(
  connect(null, { openLoader, closeLoader }),
  withState('asyncGetDispatchingGroupList', 'setAsyncGetDispatchingGroupList', []),
  withHandlers({
    getAsyncGetDispatchingGroupList: (props: Object) => async (payload: Object) => {
      if (G.isCurrentUserTypeCustomer()) return;

      const { openLoader, closeLoader } = props;
      openLoader();
      const { setAsyncGetDispatchingGroupList } = props;
      const branchGuid = R.path(['branchGuid'], payload);
      const branchGuidToUse = G.ifElse(
        G.isNilOrEmpty(branchGuid),
        G.getAmousCurrentBranchGuidFromWindow(),
        branchGuid,
      );
      const endpoint = endpointsMap.fleetDispatchingGroupByBranchGuid;
      const options = {
        params: {
          [GC.FIELD_BRANCH_GUID]: branchGuidToUse,
        },
      };
      const res = await sendRequest('get', endpoint, options);
      const { data, status } = res;
      if (G.isResponseSuccess(status)) {
        setAsyncGetDispatchingGroupList(data);
      } else {
        G.handleFailResponseSimple(res);
      }
      closeLoader();
    },
  }),
  pure,
);

export const withAsyncGetUnitIdListByEntityType = compose(
  connect(null, { openLoader, closeLoader }),
  withState('asyncUnitIdList', 'setAsyncUnitIdList', []),
  withHandlers({
    getAsyncGetUnitIdList: (props: Object) => async (payload: Object) => {
      const { openLoader, closeLoader, entityType, setAsyncUnitIdList } = props;

      openLoader();
      const branchGuid = R.path(['branchGuid'], payload);
      const branchGuidToUse = G.ifElse(
        G.isNilOrEmpty(branchGuid),
        G.getAmousCurrentBranchGuidFromWindow(),
        branchGuid,
      );
      const endpoint = G.ifElse(
        R.equals(R.pathOr(entityType, ['entityType'], payload), 'trailer'),
        endpointsMap.listFleetTrailers,
        endpointsMap.listFleetTrucks,
      );
      const options = {
        params: {
          [GC.FIELD_BRANCH_GUID]: branchGuidToUse,
        },
      };

      const res = await sendRequest('get', endpoint, options);

      const { data, status } = res;

      if (G.isResponseSuccess(status)) {
        setAsyncUnitIdList(data);
      } else {
        G.handleFailResponseSimple(res);
      }

      closeLoader();
    },
  }),
  pure,
);

export const withAsyncGetSelectedRateByLoadGuid = compose(
  withState('selectedCLORate', 'setSelectedCLORate', null),
  withState('selectedTELRate', 'setSelectedTELRate', null),
  withHandlers({
    handleGetSelectedTELRate: (props: Object) => async () => {
      const { telGuid, setSelectedTELRate } = props;
      if (G.isNilOrEmpty(telGuid)) return;
      const options = { params: { telGuid } };
      const endpoint = endpointsMap.telRate;
      const res = await sendRequest('get', endpoint, options);
      const { data, status } = res;
      if (G.isResponseSuccess(status)) {
        const selectedRate = R.filter(R.prop('selected'), R.or(data, []));
        setSelectedTELRate(selectedRate);
      } else {
        G.handleException('withAsyncGetRateByLoadType exception');
      }
    },
    handleGetSelectedCLORate: (props: Object) => async () => {
      const { cloGuid, setSelectedCLORate } = props;
      if (G.isNilOrEmpty(cloGuid)) return;
      const options = { params: { cloGuid } };
      const endpoint = endpointsMap.cloRate;
      const res = await sendRequest('get', endpoint, options);
      const { data, status } = res;
      if (G.isResponseSuccess(status)) {
        const selectedRate = R.filter(R.prop('selected'), R.or(data, []));
        setSelectedCLORate(selectedRate);
      } else {
        G.handleException('withAsyncGetRateByLoadType exception');
      }
    },
  }),
  lifecycle({
    componentDidMount() {
      const { handleGetSelectedTELRate, handleGetSelectedCLORate } = this.props;
      handleGetSelectedTELRate();
      handleGetSelectedCLORate();
    },
  }),
  pure,
);

export const withAsyncGetDetailsForPostLoad = compose(
  withState('detailsForPostLoad', 'setDetailsForPostLoad', []),
  withHandlers({
    handleGetDetailsForPostLoad: (props: Object) => async () => {
      const { guids, setDetailsForPostLoad } = props;

      if (G.isNilOrEmpty(guids)) return;

      const options = { data: guids };

      const res = await sendRequest('post', endpointsMap.postedShipmentsInfo, options);

      const { data, status } = res;

      if (G.isResponseSuccess(status)) {
        setDetailsForPostLoad(data);
      } else {
        G.handleException('withAsyncGetDetailsForPostLoad exception');
      }
    },
  }),
  lifecycle({
    componentDidMount() {
      this.props.handleGetDetailsForPostLoad();
    },
  }),
  pure,
);

export const withAsyncGetFleetVendorCompanyNameList = compose(
  withState('fleetVendorCompanyNameList', 'setFleetVendorCompanyNameList', R.path(['fleetVendorList'])),
  withHandlers({
    handleGetFleetVendorCompanyNameList: (props: Object) => async () => {
      const {
        branchGuid,
        getFleetVendorListSuccess,
        setFleetVendorCompanyNameList,
      } = props;

      if (G.isNilOrEmpty(branchGuid)) return;

      const options = {
        params: {
          [GC.BRANCH_GUID]: branchGuid,
        },
      };

      const res = await sendRequest('get', endpointsMap.fleetVendorList, options);

      const { data, status } = res;

      if (G.isResponseSuccess(status)) {
        setFleetVendorCompanyNameList(data);
        G.callFunctionWithArgs(getFleetVendorListSuccess, data);
      } else {
        G.handleException('withAsyncGetFleetVendorCompanyNameList exception');
      }
    },
  }),
  lifecycle({
    componentDidMount() {
      const { fleetVendorCompanyNameList, handleGetFleetVendorCompanyNameList } = this.props;

      if (G.isNilOrEmpty(fleetVendorCompanyNameList)) handleGetFleetVendorCompanyNameList();
    },
  }),
  pure,
);

const rejectGlCodeMappingListByCurrency = (mapping: Object, invoiceCurrency: any, withoutCurrency: any) => {
  const mappingCurrency = G.getCurrencyFromObject2(mapping);

  if (G.isTrue(withoutCurrency)) {
    return G.isNotNilAndNotEmpty(mappingCurrency);
  }

  return G.notEquals(mappingCurrency, invoiceCurrency);
};

const getCustomerInvoiceGlMappingListWithOrderType = (glCodeMappingList: Object, invoiceCurrency: string) => R.compose(
  R.indexBy(R.prop(GC.FIELD_ORDER_TYPE_GUID)),
  R.reject((item: Object) => rejectGlCodeMappingListByCurrency(item, invoiceCurrency)),
  R.filter((item: Object) => G.isAllTrue(
    G.isNotNilAndNotEmpty(R.path([GC.FIELD_ORDER_TYPE_GUID], item)),
    R.propEq(GC.INVOICE_SCOPE_TYPE_CUSTOMER, GC.FIELD_SCOPE, item),
    R.propEq(GC.INVOICE_MAPPING_TYPE_INVOICE, GC.FIELD_GL_CODE_TYPE, item),
  )),
)(R.or(glCodeMappingList, []));

const getGlMapping = (
  glCodeMappingList: Object,
  invoiceCurrency: string,
  scopeToUse: string,
  orderTypeGuid: any,
  withoutCurrency: any,
) => {
  const accessorialMappings = R.compose(
    R.map(R.prop(GC.FIELD_GL_CODE_GUID)),
    R.indexBy(R.prop(GC.FIELD_ACCESSORIAL_CONFIG_GUID)),
    R.reject((item: Object) => rejectGlCodeMappingListByCurrency(item, invoiceCurrency, withoutCurrency)),
    R.filter((item: Object) => R.and(
      R.propEq(scopeToUse, GC.FIELD_SCOPE, item),
      R.propEq(GC.INVOICE_MAPPING_TYPE_ASSESSORIALS, GC.FIELD_GL_CODE_TYPE, item),
    )),
  )(R.or(glCodeMappingList, []));

  return R.compose(
    R.assoc('scopeToUse', scopeToUse),
    R.assoc(GC.FIELD_ORDER_TYPE_GUID, orderTypeGuid),
    R.assoc(GC.INVOICE_MAPPING_TYPE_ASSESSORIALS, accessorialMappings),
    R.map(R.prop(GC.FIELD_GL_CODE_GUID)),
    R.indexBy(R.prop(GC.FIELD_GL_CODE_TYPE)),
    R.pathOr([], [scopeToUse]),
    R.groupBy(R.prop(GC.FIELD_SCOPE)),
    R.reject((item: Object) => rejectGlCodeMappingListByCurrency(item, invoiceCurrency, withoutCurrency)),
    R.filter(({ orderTypeGuid }: Object) => G.isNilOrEmpty(orderTypeGuid)),
    R.filter(({ glCodeType }: Object) => G.notEquals(glCodeType, GC.INVOICE_MAPPING_TYPE_ASSESSORIALS)),
  )(R.or(glCodeMappingList, []));
};

export const getInvoiceGlCode2 = (glCodeMappings: Object, invoiceCurrency: string) => {
  const mappings = R.path([invoiceCurrency], glCodeMappings);

  let value = null;

  if (R.includes(GC.INVOICE_MAPPING_TYPE_INVOICE, R.keys(mappings))) {
    value = G.getPropFromObject(GC.INVOICE_MAPPING_TYPE_INVOICE, mappings);
  }

  if (R.and(
    G.isNilOrEmpty(value),
    R.includes(GC.INVOICE_MAPPING_TYPE_INVOICE, R.keys(glCodeMappings)),
  )) {
    value = G.getPropFromObject(GC.INVOICE_MAPPING_TYPE_INVOICE, glCodeMappings);
  }

  return value;
};

export const getInvoiceGlCode = (props: Object) => {
  const { scopeToUse, orderTypeGuid, invoiceCurrency, glCodeMappings, glCodeMappingList } = props;

  const customerInvoiceGlMapping = getCustomerInvoiceGlMappingListWithOrderType(glCodeMappingList, invoiceCurrency);

  if (G.isAllTrue(
    R.equals(scopeToUse, GC.INVOICE_SCOPE_TYPE_CUSTOMER),
    G.isNotNilAndNotEmpty(customerInvoiceGlMapping),
  )) {
    const glCodeGuid = R.path(
      [orderTypeGuid, GC.FIELD_GL_CODE_GUID],
      customerInvoiceGlMapping,
    );

    if (G.isNotNilAndNotEmpty(glCodeGuid)) return glCodeGuid;
  }

  return getInvoiceGlCode2(glCodeMappings, invoiceCurrency);
};

export const withAsyncGetGLCodeMappingsByScope = (scope: string) => compose(
  withState('glCodeMappings', 'setGLCodeMappings', {}),
  withState('glCodeMappingList', 'setGlCodeMappingList', null),
  withHandlers({
    handleGetGLCodeMappings: (props: Object) => async () => {
      const {
        clo,
        type,
        values,
        isEditMode,
        branchGuid,
        activeDriver,
        setFieldValue,
        setGLCodeMappings,
        setGlCodeMappingList,
      } = props;

      if (G.isNilOrEmpty(branchGuid)) return;

      const options = {
        params: {
          [GC.BRANCH_GUID]: branchGuid,
        },
      };
      const scopeToUse = G.ifElse(
        R.includes(GC.FIELD_VENDOR, [type, activeDriver]),
        GC.INVOICE_SCOPE_TYPE_VENDOR,
        scope,
      );

      const res = await sendRequest('get', endpointsMap.glCodeMappingList, options);

      const { data, status } = res;

      if (G.isResponseSuccess(status)) {
        setGlCodeMappingList(data);

        const invoiceCurrency = G.getCurrencyFromObject2(values);
        const orderTypeGuid = R.path([GC.FIELD_LOAD_ORDER_TYPE, GC.FIELD_DROPDOWN_OPTION_GUID], clo);
        const mappings = getGlMapping(data, invoiceCurrency, scopeToUse, orderTypeGuid, true);
        const mappingsUSD = getGlMapping(data, GC.CURRENCY_TYPE_USD, scopeToUse, orderTypeGuid);
        const mappingsCAD = getGlMapping(data, GC.CURRENCY_TYPE_CAD, scopeToUse, orderTypeGuid);
        const mappingsEUR = getGlMapping(data, GC.CURRENCY_TYPE_EUR, scopeToUse, orderTypeGuid);
        const mappingsMXN = getGlMapping(data, GC.CURRENCY_TYPE_MXN, scopeToUse, orderTypeGuid);
        const glCodeMappings = {
          ...mappings,
          [GC.CURRENCY_TYPE_USD]: mappingsUSD,
          [GC.CURRENCY_TYPE_CAD]: mappingsCAD,
          [GC.CURRENCY_TYPE_EUR]: mappingsEUR,
          [GC.CURRENCY_TYPE_MXN]: mappingsMXN,
        };

        setGLCodeMappings(glCodeMappings);

        if (G.isTrue(isEditMode)) return;

        const invoiceGlCode = getInvoiceGlCode({
          scopeToUse,
          orderTypeGuid,
          glCodeMappings,
          invoiceCurrency,
          glCodeMappingList: data,
        });

        setFieldValue(GC.FIELD_GL_CODE, invoiceGlCode);
      } else {
        G.handleException('withAsyncGetGLCodeMappingsByScope exception');
      }
    },
  }),
  lifecycle({
    componentDidMount() {
      if (G.isNotNilAndNotEmpty(scope)) this.props.handleGetGLCodeMappings();
    },
  }),
  pure,
);

export const withAsyncGetDefaultCarrierAccessorials = compose(
  withHandlers({
    handleGetDefaultCarrierAccessorials: ({ branchGuid }: Object) => async () => {
      if (G.isNilOrEmpty(branchGuid)) return;
      const amousDefaultCarrierAccessorials = G.getItemFromWindow('amousDefaultCarrierAccessorials');
      if (G.isNotNilAndNotEmpty(G.getPropFromObject(branchGuid, amousDefaultCarrierAccessorials))) return;
      const options = {
        params: { [GC.BRANCH_GUID]: branchGuid },
      };
      const res = await sendRequest('get', endpointsMap.defaultCarrierAccessorialList, options);
      const { data, status } = res;
      if (G.isResponseSuccess(status)) {
        const newAmousDefaultCarrierAccessorials = R.assoc(branchGuid, data, amousDefaultCarrierAccessorials);

        G.setItemToWindow('amousDefaultCarrierAccessorials', newAmousDefaultCarrierAccessorials);
      } else {
        G.handleException('withAsyncGetDefaultCarrierAccessorials exception');
      }
    },
  }),
  lifecycle({
    componentWillMount() {
      this.props.handleGetDefaultCarrierAccessorials();
    },
  }),
);

export const withAsyncGetCommissionAssigneeListByLoadType = compose(
  withState('availableCommissionAssigneeOptions', 'setAvailableCommissionAssigneeOptions', []),
  withHandlers({
    handleGetCommissionAssigneeList: ({ load, setAvailableCommissionAssigneeOptions }: Object) => async () => {
      const branchGuid = G.getBranchGuidFromObject(load);

      const options = {
        params: {
          [GC.BRANCH_GUID]: branchGuid,
        },
      };

      const res = await sendRequest('get', endpointsMap.commissionAssigneeAvailableList, options);

      const { data, status } = res;

      if (G.isResponseSuccess(status)) {
        const optionsByLoadType = R.compose(
          R.pick(G.ifElse(
            G.isLoadTypeClo(R.prop(GC.FIELD_LOAD_TYPE, load)),
            [
              GC.COMMISSION_ASSIGNMENT_TYPE_SALE_PERSON,
              GC.COMMISSION_ASSIGNMENT_TYPE_ACCOUNT_MANAGER,
              GC.COMMISSION_ASSIGNMENT_TYPE_SALE_REPRESENTATIVE,
            ],
            [
              GC.COMMISSION_ASSIGNMENT_TYPE_BROKER_AGENT,
              GC.COMMISSION_ASSIGNMENT_TYPE_SALE_REPRESENTATIVE,
              GC.COMMISSION_ASSIGNMENT_TYPE_CARRIER_REPRESENTATIVE,
            ],
          )),
          R.groupBy(R.prop(GC.FIELD_TYPE)),
          R.map(({ id, guid, name, type }: Object) => ({
            type,
            [GC.FIELD_VALUE]: guid,
            [GC.FIELD_LABEL]: `${name} (${id})`,
          })),
        )(R.or(data, []));

        setAvailableCommissionAssigneeOptions(optionsByLoadType);
      } else {
        G.handleException('withAsyncGetDefaultCarrierAccessorials exception');
      }
    },
  }),
  lifecycle({
    componentWillMount() {
      this.props.handleGetCommissionAssigneeList();
    },
  }),
);

export const withAsyncGetBranchListByType = (type: string = GC.BRANCH_TYPE_ENUM_DIVISION) => compose(
  withState('branchListByType', 'setBranchListByType', []),
  withState('branchListByTypeOptions', 'setBranchListByTypeOptions', []),
  withHandlers({
    handleGetBranchListByType: (props: Object) => async () => {
      const { openLoader, closeLoader, branchGuid, setBranchListByType, setBranchListByTypeOptions } = props;

      G.callFunction(openLoader);

      const branchGuidToUse = G.ifElse(
        G.isNotNilAndNotEmpty(branchGuid),
        branchGuid,
        G.getAmousCurrentBranchGuidFromWindow(),
      );

      const endpoint = endpointsMap.getBranchListByType(branchGuidToUse);

      const options = {
        params: { [GC.BRANCH_TYPE]: type },
      };

      const res = await sendRequest('get', endpoint, options);

      const { data, status } = res;

      if (G.isResponseSuccess(status)) {
        const mapped = R.map((item: Object) =>
          R.mergeRight(
            item,
            {
              [GC.FIELD_VALUE]: G.getGuidFromObject(item),
              [GC.FIELD_LABEL]: G.getPropFromObject(GC.FIELD_NAME, item),
            },
          ),
          R.or(data, []),
        );

        setBranchListByType(mapped);
        setBranchListByTypeOptions(G.addEmptyOptionToDropDown(G.mapNameGuidToLabelValue(R.or(data, []))));
      } else {
        G.handleException('withAsyncGetBranchListByType exception');
      }

      G.callFunction(closeLoader);
    },
  }),
  lifecycle({
    componentDidMount() {
      this.props.handleGetBranchListByType();
    },
  }),
);

export const withAsyncGetServiceVendorListAvailabe = (types: Array) => compose(
  connect(null, { openLoader, closeLoader }),
  withState('serviceVendorListAvailabe', 'setServiceVendorListAvailabe', []),
  withHandlers({
    handleGetServiceVendorList: (props: Object) => async () => {
      const { branchGuid, openLoader, closeLoader, setServiceVendorListAvailabe } = props;

      openLoader();

      const branchGuidToUse = G.ifElse(
        G.isNotNilAndNotEmpty(branchGuid),
        branchGuid,
        G.getAmousCurrentBranchGuidFromWindow(),
      );
      const endpoint = endpointsMap.serviceVendorListAvailable;
      const options = {
        params: {
          [GC.BRANCH_GUID]: branchGuidToUse,
          types: R.or(types, GC.SERVICE_VENDOR_TYPE_GENERAL),
        },
      };

      const res = await sendRequest('get', endpoint, options);

      const { data, status } = res;

      if (G.isResponseSuccess(status)) {
        setServiceVendorListAvailabe(data);
      } else {
        G.handleFailResponseSimple(res);
      }

      closeLoader();
    },
  }),
  withProps(({ serviceVendorListAvailabe }: Object) => {
    const serviceVendorListAvailabeOptions = G.mapNameGuidToLabelValue(serviceVendorListAvailabe);

    return { serviceVendorListAvailabeOptions };
  }),
  lifecycle({
    componentDidMount() {
      this.props.handleGetServiceVendorList();
    },
  }),
);

export const withAsyncGetServiceVendor = () => compose(
  connect(null, { openLoader, closeLoader }),
  withState('serviceVendor', 'setServiceVendor', null),
  withHandlers({
    handleGetServiceVendor: (props: Object) => async () => {
      const { openLoader, closeLoader, setServiceVendor, serviceVendorGuid } = props;

      if (G.isNilOrEmpty(serviceVendorGuid)) return;

      openLoader();

      const endpoint = endpointsMap.getServiceVendorByGuid(serviceVendorGuid);

      const res = await sendRequest('get', endpoint);

      const { data, status } = res;

      if (G.isResponseSuccess(status)) {
        setServiceVendor(data);
      } else {
        G.handleFailResponseSimple(res);
      }

      closeLoader();
    },
  }),
  lifecycle({
    componentDidMount() {
      this.props.handleGetServiceVendor();
    },
  }),
);

export const withAsyncGetAvailableFactoringTypes = () => compose(
  withState('availableFactoringTypes', 'setAvailableFactoringTypes', []),
  withHandlers({
    handleGetAvailableFactoringTypes: (props: Object) => async () => {
      const { branchGuid, setAvailableFactoringTypes } = props;

      const options = {
        params: {
          [GC.BRANCH_GUID]: G.ifElse(
            G.isNilOrEmpty(branchGuid),
            G.getAmousCurrentBranchGuidFromWindow(),
            branchGuid,
          ),
        },
      };

      const res = await sendRequest('get', endpointsMap.getActiveFactoringIntegration, options);

      const { data, status } = res;

      if (G.isResponseSuccess(status)) {
        setAvailableFactoringTypes(data);
      } else {
        G.handleFailResponseSimple(res);
      }
    },
  }),
  lifecycle({
    componentDidMount() {
      this.props.handleGetAvailableFactoringTypes();
    },
  }),
);

export const withAsyncGetFactoringPaymentTerm = compose(
  withHandlers({
    handleGetFactoringPaymentTerm: (props: Object) => async () => {
      const { branchGuid, setFieldValue, selectedRate } = props;

      const options = {
        params: {
          [GC.BRANCH_GUID]: branchGuid,
          [GC.FIELD_CARRIER_GUID]: R.path(
            [GC.SYSTEM_OBJECT_CARRIER_ASSIGNMENT, GC.SYSTEM_OBJECT_CARRIER_SNAPSHOT, GC.FIELD_GUID],
            selectedRate,
          ),
        },
      };

      const res = await sendRequest('get', endpointsMap.carrierDefaultFactoringPaymentTerm, options);

      const { data, status } = res;

      if (G.isResponseSuccess(status)) {
        setFieldValue(GC.FIELD_FACTORING_PAYMENT_TERM, data);
      } else {
        G.handleFailResponseSimple(res);
      }
    },
  }),
  lifecycle({
    componentDidMount() {
      this.props.handleGetFactoringPaymentTerm();
    },
  }),
);

export const withAsyncGetTrailersInService = () => compose(
  withState('trailersInService', 'setTrailersInService', []),
  withState('trailersInServiceOptions', 'setTrailersInServiceOptions', []),
  withHandlers({
    handleGetAvailableTrailersInService: (props: Object) => async () => { // rename
      const {
        branchGuid,
        setTrailersInService,
        setTrailersInServiceOptions,
      } = props;

      const permission = R.or(
        G.hasAmousCurrentUserPermissions(PC.FLEET_TRAILER_READ),
        G.hasAmousCurrentUserPermissions(PC.FLEET_TRAILER_WRITE),
      );

      if (R.not(permission)) return;

      const branchConfigs = G.getAmousConfigFromWindow();
      const manageTrailersFrom = G.getConfigValueFromStore(GC.TRAILER_MANAGE_TRAILERS_FROM, branchConfigs);
      const useStopTrailers = R.equals(manageTrailersFrom, ENUMS.ENUM_EVENTS);

      const isPathDoNew = R.includes(G.getWindowLocationPathname(), [GC.ROUTE_PATH_DO_NEW]);

      if (R.and(isPathDoNew, R.not(useStopTrailers))) return;

      const options = {
        params: {
          [GC.BRANCH_GUID]: G.ifElse(
            G.isNilOrEmpty(branchGuid),
            G.getAmousCurrentBranchGuidFromWindow(),
            branchGuid,
          ),
        },
      };

      const res = await sendRequest('get', endpointsMap.listFleetTrailersInService, options);

      const { data, status } = res;

      if (G.isResponseSuccess(status)) {
        const labelValueOptions = R.map(G.renameKeys({ [GC.FIELD_GUID]: 'value', [GC.FIELD_UNIT_ID]: 'label' }), data);
        setTrailersInServiceOptions(labelValueOptions);
        setTrailersInService(data);
      } else {
        G.handleFailResponseSimple(res, false);
      }
    },
  }),
  lifecycle({
    componentDidMount() {
      this.props.handleGetAvailableTrailersInService();
    },
  }),
);

export const withAsyncGetRoles = () => compose(
  withLoadingState,
  withState('asyncRoles', 'setAsyncRoles', []),
  withState('asyncRoleOptions', 'setAsyncRoleOptions', []),
  withHandlers({
    getRoles: (props: Object) => async () => {
      const {
        branchGuid,
        setAsyncRoles,
        openLoadingState,
        closeLoadingState,
        setAsyncRoleOptions,
      } = props;

      G.callFunction(openLoadingState);

      const permission = R.or(
        G.hasAmousCurrentUserPermissions(PC.ROLES_READ),
        G.hasAmousCurrentUserPermissions(PC.ROLES_WRITE),
      );

      if (R.not(permission)) return;

      const options = {
        params: {
          [GC.FIELD_CURRENT_BRANCH_GUID]: G.ifElse(
            G.isNilOrEmpty(branchGuid),
            G.getAmousCurrentBranchGuidFromWindow(),
            branchGuid,
          ),
        },
      };

      const res = await sendRequest('get', endpointsMap.listRoles, options);

      const { data, status } = res;

      if (G.isResponseSuccess(status)) {
        const options = G.mapNameGuidToLabelValue(data);

        setAsyncRoles(data);
        setAsyncRoleOptions(options);
      } else {
        G.handleFailResponseSimple(res, false);
      }

      G.callFunction(closeLoadingState);
    },
  }),
  lifecycle({
    componentDidMount() {
      this.props.getRoles();
    },
  }),
);

export const withAsyncGetRoleGrantedBranchGuids = compose(
  connect(null, { openLoader, closeLoader }),
  withHandlers({
    getGrantedEnterpriseGuids: (props: Object) => async (roleGuid: string) => {
      const { openLoader, closeLoader } = props;

      if (G.isNilOrEmpty(roleGuid)) return;

      openLoader();

      const endpoint = endpointsMap.getRoleGrantedBranchGuidsEndpoint(roleGuid);

      const res = await sendRequest('get', endpoint);

      const { data, status } = res;

      if (G.isResponseFail(status)) {
        G.handleFailResponseSimple(res);
      }

      closeLoader();

      return data;
    },
  }),
  lifecycle({
    componentDidMount() {
      this.props.getGrantedEnterpriseGuids();
    },
  }),
);

export const withAsyncGetTransportationModeGrouping = compose(
  connect(null, { openModal }),
  withState(
    'transportationModeGroupingList',
    'setTransportationModeGroupingList',
    R.prop('transportationModeGroupingList'),
  ),
  withHandlers({
    handleGetTransportationModeGroupingList: (props: Object) => async () => {
      const { branchGuid, setTransportationModeGroupingList, getTransportationModeGroupingListSuccess } = props;

      if (G.isNilOrEmpty(branchGuid)) return;

      const requestOptions = {
        params: { [GC.BRANCH_GUID]: branchGuid },
      };

      const res = await sendRequest('get', endpointsMap.transportationModeGroupingList, requestOptions);

      const { data, status } = res;

      if (G.isResponseSuccess(status)) {
        const indexedList = R.indexBy(R.prop(GC.FIELD_TRANSPORTATION_MODE_GUID), data);

        setTransportationModeGroupingList(indexedList);
        G.callFunctionWithArgs(getTransportationModeGroupingListSuccess, indexedList);
      } else {
        G.handleException('handleGetTransportationModeGroupingList exception');
      }
    },
    handleCheckModeGroupingAndShowConfirmMessage: (props: Object) => (options: Object) => {
      const {
        load,
        openModal,
        closeModal,
        transportationModeGroupingList,
      } = props;

      const { callback, transportationMode } = options;

      const loadServices = R.propOr([], GC.FIELD_SERVICES, load);
      const loadEquipment = R.prop(GC.FIELD_LOAD_EQUIPMENT, load);

      const loadEquipmentAndServices = G.ifElse(
        G.isNotNil(loadEquipment),
        R.append(loadEquipment, loadServices),
        loadServices,
      );

      const grouping = R.prop(transportationMode, transportationModeGroupingList);

      if (R.or(R.isNil(grouping), R.isEmpty(loadEquipmentAndServices))) return callback();

      const { services, equipments } = grouping;

      const availableServicesAndEquipments = R.concat(services, equipments);

      const allIncludes = R.all(
        ({ dropdownOptionGuid }: Object = {}) => R.includes(dropdownOptionGuid, availableServicesAndEquipments),
        loadEquipmentAndServices,
      );

      if (allIncludes) return callback();

      const availableLoadServices = R.filter(
        ({ dropdownOptionGuid }: Object) => R.includes(dropdownOptionGuid, services),
        loadServices,
      );

      const availableLoadEquipment = G.ifElse(
        R.includes(R.prop(GC.FIELD_DROPDOWN_OPTION_GUID, loadEquipment), equipments),
        loadEquipment,
        null,
      );

      const textLocale = G.getWindowLocale(
        'messages:transportation-mode-grouping-is-not-mapped',
        'This mode of transportation is not mapped to the selected services/equipment. By clicking confirm, the services/equipment will be removed',
      );

      const component = <ConfirmComponent textLocale={textLocale} />;

      const modal = {
        component,
        options: {
          width: 680,
          controlButtons: [
            {
              type: 'button',
              name: G.getWindowLocale('actions:confirm', 'Confirm'),
              action: () => {
                closeModal();

                callback({
                  [GC.FIELD_SERVICES]: availableLoadServices,
                  [GC.FIELD_LOAD_EQUIPMENT]: availableLoadEquipment,
                });
              },
            },
          ],
        },
      };

      openModal(modal);
    },
  }),
  lifecycle({
    componentDidMount() {
      const {
        transportationModeGroupingList,
        shouldNotGetTransportationModeList,
        handleGetTransportationModeGroupingList,
      } = this.props;

      if (R.and(
        R.isNil(transportationModeGroupingList),
        R.not(shouldNotGetTransportationModeList),
      )) handleGetTransportationModeGroupingList();
    },
    componentDidUpdate(prevProps: Object) {
      const {
        transportationModeGroupingList,
        shouldNotGetTransportationModeList,
        handleGetTransportationModeGroupingList,
      } = this.props;

      if (G.isAllTrue(
        R.isNil(transportationModeGroupingList),
        G.isFalse(shouldNotGetTransportationModeList),
        G.notEquals(shouldNotGetTransportationModeList, prevProps.shouldNotGetTransportationModeList),
      )) handleGetTransportationModeGroupingList();
    },
  }),
  pure,
);
