import * as R from 'ramda';
import * as P from 'plow-js';
import React, { Component } from 'react';
// helpers/constants
import * as G from '../helpers';
// utilities
import { sendRequest } from '../utilities/http';
//////////////////////////////////////////////////

export class AsyncData extends Component {
  constructor(props: Object) {
    super(props);
    this.state = { data: null, error: false, loading: true };
    this.setLoading = this.setLoading.bind(this);
    this.getFilteredDataRequest = this.getFilteredDataRequest.bind(this);
  }

  setLoading() {
    this.setState({ loading: true });
  }

  async getFilteredDataRequest(options: Object) {
    const { showErrMsg, emptyData, setEmptyData, asyncMethod, asyncEndpoint } = this.props;

    const res = await sendRequest(asyncMethod, asyncEndpoint, options);
    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      if (G.isNotNil(data)) {
        const newState = P.$all(
          P.$set('data', data),
          P.$set('loading', false),
          this.state,
        );
        this.setState(newState);
      } else if (setEmptyData) {
        const newState = P.$all(
          P.$set('loading', false),
          P.$set('data', emptyData),
          this.state,
        );
        this.setState(newState);
      }
    } else {
      if (setEmptyData) {
        const newState = P.$all(
          P.$set('loading', false),
          P.$set('data', emptyData),
          this.state,
        );
        this.setState(newState);
      }
      G.handleFailResponseSimple(
        res,
        R.or(showErrMsg, false),
        'withDataFilter -> getFilteredDataRequest',
      );
    }
  }

  render() {
    return <div>{this.props.render(this.state, this.setLoading, this.getFilteredDataRequest)}</div>;
  }
}

export const withDataFilterHoc = (Component: any) => (
  class extends React.Component {
    render() {
      const { showErrMsg, emptyData, asyncMethod, setEmptyData, asyncEndpoint } = this.props;

      return (
        <AsyncData
          emptyData={emptyData}
          showErrMsg={showErrMsg}
          setEmptyData={setEmptyData}
          asyncEndpoint={asyncEndpoint}
          asyncMethod={R.or(asyncMethod, 'get')}
          render={(parentState: Object, setLoading: Function, getFilteredDataRequest: Function) =>
            <Component
              {...this.props}
              setLoading={setLoading}
              asyncData={parentState}
              getFilteredDataRequest={getFilteredDataRequest}
            />
          }
        />
      );
    }
  }
);
