// helpers
import { ifElse, isAllTrue } from './helpers';
//////////////////////////////////////////////////

// helper for getting react component display name
const getDisplayName = (WrappedComponent: React.ComponentType<any>) =>
  WrappedComponent.displayName || WrappedComponent.name || 'Component';

const getComponentCountTimes = (count: number = 0, renderFunc: Function) => {
  const arr = new Array(count).fill();

  return arr.map(renderFunc);
};

const renderIfAll = (component: React.FunctionComponent, ...conditions: Array) => ifElse(
  isAllTrue(...conditions),
  component,
  null,
);

export {
  renderIfAll,
  getDisplayName,
  getComponentCountTimes,
};
