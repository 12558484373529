import { compose, withState, lifecycle, withHandlers } from 'react-recompose';
// helpers/constants
import * as G from '../helpers';
import * as GC from '../constants';
// utilities
import { sendRequest } from '../utilities/http';
import endpointsMap from '../utilities/endpoints';
/////////////////////////////////////////////////

export const withAsyncMailSendingIntegrationExists = compose(
  withState('mailSendingIntegrationExists', 'setAsyncData', null),
  withState('asyncLoading', 'setAsyncLoading', true),
  withHandlers({
    getAsyncData: ({ setAsyncData }: Object) => async () => {
      const branchGuid = G.getAmousCurrentBranchGuidFromWindow();
      const options = {
        params: { [GC.BRANCH_GUID]: branchGuid },
      };

      const res = await sendRequest(
        'get',
        endpointsMap.getMailServiceIntegrationConfigAvailableIntegrationType,
        options,
      );

      const { data, status } = res;

      if (G.isResponseSuccess(status)) {
        setAsyncData(data);
      } else {
        G.handleFailResponseSimple(res);
      }
    },
  }),
  lifecycle({
    componentDidMount() {
      this.props.getAsyncData();
    },
  }),
);
