import * as R from 'ramda';
import * as P from 'plow-js';
import React, { Component } from 'react';
// helpers/constants
import * as G from '../helpers';
// utilities
import { sendRequest } from '../utilities/http';
import endpointsMap from '../utilities/endpoints';
//////////////////////////////////////////////////

class TelEvents extends Component {
  constructor(props: Object) {
    super(props);
    this.state = { asyncEvents: null };
    this.getTelEventsRequest = this.getTelEventsRequest.bind(this);
  }

  componentDidMount() {
    this.getTelEventsRequest(this.props);
  }

  async getTelEventsRequest(props: Object) {
    const { telGuid } = props;
    const params = { telGuid };

    const res = await sendRequest('get', endpointsMap.telEventList, { params });

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      const newState = P.$set('asyncEvents', R.or(data, []), this.state);
      this.setState(newState);
    } else {
      G.handleFailResponseSimple(
        res,
        false,
        'withAsyncTelEvents -> getTelEventsRequest',
      );
    }
  }

  render() {
    return <div>{this.props.render(this.state, this.getTelEventsRequest)}</div>;
  }
}

export const withAsyncTelEvents = (Component: any) => class extends React.Component {
  render() {
    return (
      <TelEvents
        telGuid={this.props.telGuid}
        branchGuid={this.props.branchGuid}
        render={(parentState: Object) =>
          <Component
            {...this.props}
            asyncEvents={parentState.asyncEvents}
          />
          }
      />
    );
  }
};
