
import * as R from 'ramda';
// components
import { createMainCharge, createFuelCharge, createDiscountCharge } from '../components/charge/settings';
// constants
import * as GC from '../constants';
// helpers
import { isMetricUomSystem } from './config';
import { getConfigValueFromStore } from './common';
import { showToastrMessageFromLocale } from './toastr';
import { getPropFromObject, getDisplayedValueFromObject } from './getter';
import { NaNToNull, NaNToZero, mathRoundNumber, fromNillOrEmptyToZero } from './calc';
import {
  getItemFromWindow,
  getAmousCurrentBranchGuidFromWindow,
  getConfigUiReplaceDollarSymbolFromWindow,
} from './window';
import {
  ifElse,
  isTrue,
  isZero,
  isArray,
  isNotNil,
  isString,
  isAllTrue,
  notEquals,
  isNotEmpty,
  isNilOrEmpty,
  isAllNilOrEmpty,
  isNotNilAndNotEmpty,
} from './helpers';
//////////////////////////////////////////////////

const isRateTypeFleetRate = R.equals(GC.RATE_TYPE_FLEET_RATE);

const isRateTypeCarrierRate = R.equals(GC.RATE_TYPE_CARRIER_RATE);

const isContractTypeCarrier = R.equals('carrier');

const isContractTypeCustomer = R.equals('customer');

const isInvoiceTypeFleetVendorInvoice = R.equals(GC.INVOICE_TYPE_FLEET_VENDOR_INVOICE);

// charges
const isChargeTypeMain = (chargeItem: Object | string) => R.equals(
  GC.CHARGE_TYPE_MAIN,
  R.pathOr(chargeItem, [GC.FIELD_CHARGE_TYPE], chargeItem),
);

const isChargeTypeDiscount = (chargeItem: Object | string) => R.equals(
  GC.CHARGE_TYPE_DISCOUNT,
  R.pathOr(chargeItem, [GC.FIELD_CHARGE_TYPE], chargeItem),
);

const isChargeTypeFuel = (chargeItem: Object | string) => R.equals(
  GC.CHARGE_TYPE_FUEL,
  R.pathOr(chargeItem, [GC.FIELD_CHARGE_TYPE], chargeItem),
);

const isChargeTypeAdditional = (chargeItem: Object | string) => R.equals(
  GC.CHARGE_TYPE_ADDITIONAL,
  R.pathOr(chargeItem, [GC.FIELD_CHARGE_TYPE], chargeItem),
);

const isAutoAssessorialCharge = (chargeItem: Object | string) => R.equals(
  true,
  R.pathOr(chargeItem, [GC.FIELD_CHARGE_AUTO_ASSESSORIAL], chargeItem),
);

const isChargeRateTypeCustomerRate = R.equals(GC.CHARGE_RATE_TYPE_CUSTOMER_RATE);

const isChargeRateTypeLineHaul = R.equals(GC.CHARGE_RATE_TYPE_LINE_HAUL);

const isChargeRateTypeCustomerLineHaul = R.equals(GC.CHARGE_RATE_TYPE_CUSTOMER_LINE_HAUL);

const isChargeRateTypeTaxableTotal = R.equals(GC.CHARGE_RATE_TYPE_TAXABLE_TOTAL);

const isChargeRateTypeCustomerRateOrLineHaul = (rateType: string) => R.or(
  isChargeRateTypeCustomerRate(rateType),
  isChargeRateTypeCustomerLineHaul(rateType),
);

const isChargeRateTypeLineHaulOrCustomerLineHaul = (rateType: string) => R.or(
  isChargeRateTypeLineHaul(rateType),
  isChargeRateTypeCustomerLineHaul(rateType),
);

const mainChargesFilter = R.filter(isChargeTypeMain);

const discountChargesFilter = R.filter(isChargeTypeDiscount);

const fuelChargesFilter = R.filter(isChargeTypeFuel);

const additionChargesFilter = R.filter(isChargeTypeAdditional);

const getFilteredByTypeCharges = (charges: Array) => {
  const mainCharges = mainChargesFilter(charges);
  const fuelCharges = fuelChargesFilter(charges);
  const discountCharges = discountChargesFilter(charges);
  const additionalCharges = additionChargesFilter(charges);

  return {
    mainCharges,
    fuelCharges,
    discountCharges,
    additionalCharges,
  };
};

const getQuantityFromCharge = (charge: Object) => R.path([GC.FIELD_CHARGE_QUANTITY], charge);

const getHoldQuantityFromCharge = (charge: Object) => R.path([GC.FIELD_CHARGE_HOLD_QUANTITY], charge);

const getRateTypeFromCharge = (charge: Object) => R.path([GC.FIELD_CHARGE_RATE_TYPE], charge);

const getRateUnitFromCharge = (charge: Object) => R.path([GC.FIELD_CHARGE_RATE_UNIT], charge);

const getTotalFromCharge = (charge: Object) => R.path([GC.FIELD_TOTAL], charge);

const getCurrencyFromObject = (object: Object) => R.pathOr(GC.DEFAULT_UI_CURRENCY, [GC.FIELD_CURRENCY], object);

const getCurrencyFromObject2 = (object: Object) => R.path([GC.FIELD_CURRENCY], object);

const getCurrencyByPath = (object: Object, path: any = [GC.FIELD_CURRENCY]) => R.path(path, object);

const getChargesFromObject = (object: Object) => R.pathOr([], [GC.FIELD_CHARGES], object);

const makeTelRateWithCorrectChargesForFleetVendorInvoice = (rate: Object) => {
  const fleetVendorCharges = R.propOr([], GC.FIELD_FLEET_VENDOR_CHARGES, rate);

  if (R.any(R.prop(GC.FIELD_ONLY_INCLUDE_TO_INVOICE), fleetVendorCharges)) {
    return R.compose(
      R.mergeDeepRight(rate),
      R.objOf(GC.FIELD_FLEET_VENDOR_CHARGES),
      R.map(R.dissoc(GC.FIELD_ONLY_INCLUDE_TO_INVOICE)),
      R.filter(({ type, onlyIncludeToInvoice }: Object) => ifElse(
        isChargeTypeAdditional(type),
        onlyIncludeToInvoice,
        true,
      )),
    )(fleetVendorCharges);
  }

  return rate;
};
// charges

// currency
const isCurrencyTypeUSD = R.equals(GC.CURRENCY_TYPE_USD);

const isNotCurrencyTypeUSD = notEquals(GC.CURRENCY_TYPE_USD);

const getCurrencySymbol = (currency: string) => {
  if (isString(currency)) {
    const replace = getConfigUiReplaceDollarSymbolFromWindow();

    if (R.and(isTrue(replace), isCurrencyTypeUSD(currency))) {
      return 'US$';
    }

    const code = R.trim(currency);

    return R.pathOr('', [code, 'symbol'], GC.CURRENCY_DETAILS);
  }

  return '';
};

const getCurrencySymbol2 = (currency: string) => {
  if (isString(currency)) {
    const replace = getConfigUiReplaceDollarSymbolFromWindow();

    if (R.and(isTrue(replace), isCurrencyTypeUSD(currency))) {
      return 'US$';
    }

    const code = R.trim(currency);

    return R.path([code, 'symbol'], GC.CURRENCY_DETAILS);
  }

  return null;
};

const getCurrencyFromRate = (rate: Object, configCurrency: string = GC.DEFAULT_UI_CURRENCY) => (
  R.pathOr(configCurrency, ['currency'], rate)
);

const getCurrencySymbolFromRate = (rate: Object) => {
  const currency = getCurrencyFromRate(rate);

  return getCurrencySymbol(currency);
};

const getCurrencySymbolFromCharge = (charge: Object) => {
  const currency = getPropFromObject(GC.FIELD_CURRENCY, charge);

  return getCurrencySymbol2(currency);
};

const getRateTotalWithCurrency = (rate: Object) => {
  const currency = getCurrencyFromRate(rate);

  return `${getCurrencySymbol(currency)} ${R.pathOr(0, ['total'], rate)} ${currency}`;
};

const getRateTotalWithCurrencySymbol = (rate: Object) => {
  const currency = getCurrencyFromRate(rate);

  return `${getCurrencySymbol(currency)} ${R.pathOr(0, ['total'], rate)}`;
};

const getRpmText = (distanceUom: string) => ifElse(R.equals(distanceUom, GC.UOM_KILOMETER), 'RPKM', 'RPM');

const getRpmTextByUomSystemConfig = () => {
  if (isMetricUomSystem()) return 'RPKM';

  return 'RPM';
};

const convertCurrencyByExchangeRate = (amount: number, fromCurrency: string, toCurrency: string) => {
  const amousExchangeRateMapByDate = getItemFromWindow('amousExchangeRateMapByDate');

  const exchangeRateTo = R.pathOr(1, [toCurrency], amousExchangeRateMapByDate);
  const exchangeRateFrom = R.pathOr(1, [fromCurrency], amousExchangeRateMapByDate);

  if (R.and(isNilOrEmpty(fromCurrency), isNilOrEmpty(toCurrency))) return amount;

  if (R.equals(fromCurrency, toCurrency)) return amount;

  if (R.and(isCurrencyTypeUSD(fromCurrency), isNotCurrencyTypeUSD(toCurrency))) {
    return R.multiply(amount, exchangeRateTo);
  }

  if (R.and(isNotCurrencyTypeUSD(fromCurrency), isCurrencyTypeUSD(toCurrency))) {
    return R.divide(amount, exchangeRateFrom);
  }

  if (R.and(isNotCurrencyTypeUSD(fromCurrency), isNotCurrencyTypeUSD(toCurrency))) {
    const USDTotal = R.divide(amount, exchangeRateFrom);

    return R.multiply(USDTotal, exchangeRateTo);
  }

  return amount;
};

const convertCurrencyByExchangeRateToFloatOrNull = (
  amount: number,
  fromCurrency: string,
  toCurrency: string,
) => NaNToNull(mathRoundNumber(
  convertCurrencyByExchangeRate(amount, fromCurrency, toCurrency),
));

const convertToNormalizedCurrencyByExchangeRate = (amount: number, fromCurrency: string) => {
  const branchGuid = getAmousCurrentBranchGuidFromWindow();
  const amousExchangeRateByBranch = getItemFromWindow('amousExchangeRateByBranch');

  const exchangeRate = R.path([branchGuid, fromCurrency, 'exchangeRate'], amousExchangeRateByBranch);

  if (isNilOrEmpty(exchangeRate)) return { normalizedAmount: amount, normalizedCurrency: fromCurrency };

  const normalizedAmount = R.multiply(amount, exchangeRate);

  const normalizedCurrency = R.path(
    [branchGuid, fromCurrency, GC.FIELD_DESTINATION_CURRENCY],
    amousExchangeRateByBranch,
  );

  return { normalizedAmount, normalizedCurrency };
};
// currency

// calc charges total
const getChargePriceSheetCurrencyTotal = (charge: Object, rateCurrency: string) => {
  const total = getTotalFromCharge(charge);
  const chargeCurrency = R.pathOr(rateCurrency, [GC.FIELD_CURRENCY], charge);
  const chargeCurrencyToUse = R.or(chargeCurrency, rateCurrency);
  const convertedTotal = convertCurrencyByExchangeRate(total, chargeCurrencyToUse, rateCurrency);

  return convertedTotal;
};

const calcChargesArrayTotal = (charges: Object, options: any) => {
  if (isNilOrEmpty(charges)) return 0;

  const rateCurrency = R.path(['rateCurrency'], options);
  const omitAdvancePayment = getPropFromObject('omitAdvancePayment', options);
  const omitInternalExpense = isTrue(R.path(['omitInternalExpense'], options));
  const useAdvancePaymentChargeAsNegativeCharge = getPropFromObject(
    'useAdvancePaymentChargeAsNegativeCharge',
    options,
  );

  return R.reduce((acc: Array, item: Object) => {
    const advancePayment = getPropFromObject(GC.FIELD_ADVANCE_PAYMENT, item);
    const internalExpense = getPropFromObject(GC.FIELD_CHARGE_INTERNAL_EXPENSE, item);

    let total = getTotalFromCharge(item);

    if (isNotNilAndNotEmpty(rateCurrency)) {
      total = getChargePriceSheetCurrencyTotal(item, rateCurrency);
    }

    total = R.or(total, 0);

    if (R.or(
      isTrue(getPropFromObject(GC.FIELD_DEDUCTION, item)),
      isAllTrue(advancePayment, useAdvancePaymentChargeAsNegativeCharge),
    )) {
      return R.subtract(acc, total);
    }

    if (R.or(isAllTrue(internalExpense, omitInternalExpense), isAllTrue(advancePayment, omitAdvancePayment))) {
      return acc;
    }

    return R.add(acc, total);
  }, 0, charges);
};

const calcAllChargesArrayTotal = (charges: Object, options: any) => {
  const { mainCharges, fuelCharges, discountCharges, additionalCharges } = getFilteredByTypeCharges(charges);

  const mainChargesTotal = calcChargesArrayTotal(mainCharges, options);
  const fuelChargesTotal = calcChargesArrayTotal(fuelCharges, options);
  const discountChargesTotal = calcChargesArrayTotal(discountCharges, options);
  const additionalChargesTotal = calcChargesArrayTotal(additionalCharges, options);

  return {
    mainChargesTotal,
    fuelChargesTotal,
    discountChargesTotal,
    additionalChargesTotal,
  };
};

const calcChargesTotal = (charges: Object, options: any) => {
  if (isNilOrEmpty(charges)) return 0;

  const {
    mainChargesTotal,
    fuelChargesTotal,
    discountChargesTotal,
    additionalChargesTotal,
  } = calcAllChargesArrayTotal(charges, options);

  const mainWithoutDiscount = R.subtract(mainChargesTotal, discountChargesTotal);

  return R.sum([mainWithoutDiscount, fuelChargesTotal, additionalChargesTotal]);
};

const calcChargesTotalByType = (charges: Object, type: string, options: any) => {
  if (isNilOrEmpty(charges)) return 0;

  const { mainCharges, fuelCharges, discountCharges, additionalCharges } = getFilteredByTypeCharges(charges);

  if (isChargeTypeMain(type)) return calcChargesArrayTotal(mainCharges, options);

  if (isChargeTypeFuel(type)) return calcChargesArrayTotal(fuelCharges, options);

  if (isChargeTypeDiscount(type)) return calcChargesArrayTotal(discountCharges, options);

  if (isChargeTypeAdditional(type)) return calcChargesArrayTotal(additionalCharges, options);

  return 0;
};

const calcTelRateTotal = (rate: Object) => {
  if (isNilOrEmpty(rate)) return 0;

  const {
    currency,
    carrierRateCharges,
    fleetVendorCharges,
    primaryDriverCharges,
    secondaryDriverCharges,
  } = rate;

  const options = { rateCurrency: currency };

  const vendorTotal = calcChargesTotal(fleetVendorCharges, options);
  const carrierTotal = calcChargesTotal(carrierRateCharges, options);
  const primaryTotal = calcChargesTotal(primaryDriverCharges, options);
  const secondaryTotal = calcChargesTotal(secondaryDriverCharges, options);

  return R.sum([vendorTotal, primaryTotal, secondaryTotal, carrierTotal]);
};

const calcCloRateTotal = (rate: Object) => {
  if (isNilOrEmpty(rate)) return 0;

  const { charges, currency } = rate;

  const options = { rateCurrency: currency };

  return calcChargesTotal(charges, options);
};
// calc charges total

const getCalcTelRateTotalByType = (rate: Object, type: string, options: Object = {}) => {
  const {
    currency,
    carrierRateCharges,
    fleetVendorCharges,
    primaryDriverCharges,
    secondaryDriverCharges,
  } = rate;

  const optionsToUse = R.assoc('rateCurrency', currency, options);

  const primaryDriverTotal = calcChargesTotalByType(primaryDriverCharges, type, optionsToUse);
  const secondaryDriverTotal = calcChargesTotalByType(secondaryDriverCharges, type, optionsToUse);
  const carrierRateTotal = calcChargesTotalByType(carrierRateCharges, type, optionsToUse);
  const fleetVendorTotal = calcChargesTotalByType(fleetVendorCharges, type, optionsToUse);

  if (isNotNilAndNotEmpty(fleetVendorCharges)) return fleetVendorTotal;

  return R.sum([primaryDriverTotal, secondaryDriverTotal, carrierRateTotal]);
};

const getCalcTelRateChargesTotal = (charges: Array, rateCurrency: string) => (
  NaNToZero(mathRoundNumber(
    calcChargesTotal(charges, { rateCurrency, omitAdvancePayment: true }),
  ))
);

const getCalcRateChargesTotal = (charges: Array, rateCurrency: string) => (
  NaNToZero(mathRoundNumber(
    calcChargesTotal(charges, { rateCurrency }),
  ))
);

const getCalcRateChargesTotalWithoutInternalExpense = (charges: Array, rateCurrency: string) => (
  NaNToZero(mathRoundNumber(
    calcChargesTotal(charges, { rateCurrency, omitInternalExpense: true }),
  ))
);

const getCalcTelRateChargesTotalPay = (charges: Array, rateCurrency: string) => (
  NaNToZero(mathRoundNumber(
    calcChargesTotal(
      charges,
      { rateCurrency, omitInternalExpense: true, useAdvancePaymentChargeAsNegativeCharge: true },
    ),
  ))
);

const getCalcTelInvoiceChargesTotal = (charges: Array, rateCurrency: string) => (
  NaNToZero(mathRoundNumber(
    calcChargesTotal(
      charges,
      { rateCurrency, omitInternalExpense: true, useAdvancePaymentChargeAsNegativeCharge: true },
    ),
  ))
);

const getTelMainTotalInfo = (rate: Object = {}) => (
  `${getCurrencySymbolFromRate(rate)} ${mathRoundNumber(getCalcTelRateTotalByType(rate, GC.CHARGE_TYPE_MAIN))}`
);

const getTelDiscountTotalInfo = (rate: Object = {}) => (
  `${getCurrencySymbolFromRate(rate)} ${mathRoundNumber(getCalcTelRateTotalByType(rate, GC.CHARGE_TYPE_DISCOUNT))}`
);

const getTelFuelTotalInfo = (rate: Object = {}) => (
  `${getCurrencySymbolFromRate(rate)} ${mathRoundNumber(getCalcTelRateTotalByType(rate, GC.CHARGE_TYPE_FUEL))}`
);

const getTelAdditionalChargesTotalInfo = (rate: Object = {}) => (
  `${getCurrencySymbolFromRate(rate)} ${mathRoundNumber(getCalcTelRateTotalByType(rate, GC.CHARGE_TYPE_ADDITIONAL))}`
);

const getTelChargesTotalInfo = (rate: Object = {}) => (
  `${getCurrencySymbolFromRate(rate)} ${mathRoundNumber(calcTelRateTotal(rate))}`
);

const getCalcCloRateTotalByChargeType = (rate: Object, type: string, options: Object = {}) => {
  const { charges, currency } = rate;

  const optionsToUse = R.assoc('rateCurrency', currency, options);

  return calcChargesTotalByType(charges, type, optionsToUse);
};

const getCloMainTotalInfo = (rate: Object = {}) => (
  `${getCurrencySymbolFromRate(rate)} ${mathRoundNumber(getCalcCloRateTotalByChargeType(rate, GC.CHARGE_TYPE_MAIN))}`
);

const getCloDiscountTotalInfo = (rate: Object = {}) => (
  `${getCurrencySymbolFromRate(rate)} ${mathRoundNumber(getCalcCloRateTotalByChargeType(rate, GC.CHARGE_TYPE_DISCOUNT))}`
);

const getCloFuelTotalInfo = (rate: Object = {}) => (
  `${getCurrencySymbolFromRate(rate)} ${mathRoundNumber(getCalcCloRateTotalByChargeType(rate, GC.CHARGE_TYPE_FUEL))}`
);

const getCloAdditionalChargesTotalInfo = (rate: Object = {}) => (
  `${getCurrencySymbolFromRate(rate)} ${mathRoundNumber(getCalcCloRateTotalByChargeType(rate, GC.CHARGE_TYPE_ADDITIONAL))}`
);

const getCloChargesTotalInfo = (rate: Object = {}) => (
  `${getCurrencySymbolFromRate(rate)} ${mathRoundNumber(calcCloRateTotal(rate))}`
);

const createMainAndFuelCharges = () => ([
  createMainCharge(),
  createFuelCharge(),
]);

const createMainAndDiscountCharges = () => ([
  createMainCharge(),
  createDiscountCharge(),
]);

const addOneMainChargeToCharges = (charges: Array) => {
  if (R.isNil(charges)) return [];

  const length = R.length(mainChargesFilter(charges));

  if (isZero(length)) return R.append(createMainCharge(), charges);

  return charges;
};

const addOneDiscountChargeToCharges = (charges: Array) => {
  if (R.isNil(charges)) return [];

  const length = R.length(discountChargesFilter(charges));

  if (isZero(length)) return R.append(createDiscountCharge(), charges);

  return charges;
};

const addOneFuelChargeToCharges = (charges: Array) => {
  if (R.isNil(charges)) return [];

  const length = R.length(fuelChargesFilter(charges));

  if (isZero(length)) return R.append(createFuelCharge(), charges);

  return charges;
};

const addMainFuelChargeToCharges = (charges: Array) => addOneMainChargeToCharges(
  addOneFuelChargeToCharges(charges),
);

const addMainFuelChargeToCharges2 = (charges: Array, fuelCharge: any) => {
  if (isNilOrEmpty(fuelCharge)) {
    return addOneMainChargeToCharges(addOneFuelChargeToCharges(charges));
  }

  return addOneMainChargeToCharges(R.append(fuelCharge, charges));
};

const addMainDiscountFuelChargeToCharges = (charges: Array) => addOneMainChargeToCharges(
  addOneDiscountChargeToCharges(addOneFuelChargeToCharges(charges)),
);

const replaceAutoChargesWithNewSharedAssessorialsApplyTo = (charges: Array, sharedApplyTo: Array) => {
  if (R.or(isNilOrEmpty(charges), isNilOrEmpty(sharedApplyTo))) return charges;

  const filteredCharges = R.reject((item: Object) => isAutoAssessorialCharge(item), charges);

  return R.concat(filteredCharges, sharedApplyTo);
};

const getChargeTotalFromCharges = (charges: Array, type: string) => {
  if (isNilOrEmpty(charges)) return 0;

  return R.compose(
    R.pathOr(0, [type, GC.FIELD_CHARGE_TOTAL]),
    R.indexBy(R.prop(GC.FIELD_CHARGE_TYPE)),
  )(charges);
};

const getChargeTotalFromRate = (rate: Object, type: string) => (
  getChargeTotalFromCharges(R.propOr(null, 'charges', rate), type)
);

const getChargeRateNameFromCharges = (charges: Array, type: string) => {
  if (isNilOrEmpty(charges)) return '';

  return R.compose(
    R.pathOr('', [type, GC.FIELD_CHARGE_RATE_NAME]),
    R.indexBy(R.prop(GC.FIELD_CHARGE_TYPE)),
  )(charges);
};

const getChargeRateNameFromRate = (rate: Object, type: string) => (
  getChargeRateNameFromCharges(R.propOr(null, 'charges', rate), type)
);

const getAdditionalChargesTotalFromCharges = (charges: Object) => {
  if (isNilOrEmpty(charges)) return 0;

  return R.compose(
    R.sum(),
    R.map((item: Object) => R.pathOr(0, [GC.FIELD_CHARGE_TOTAL], item)),
    R.filter((item: Object) => R.equals(R.prop(GC.FIELD_CHARGE_TYPE, item), GC.CHARGE_TYPE_ADDITIONAL)),
  )(charges);
};

const getAdditionalChargesTotalFromRate = (rate: Object) => (
  getAdditionalChargesTotalFromCharges(R.propOr(null, 'charges', rate))
);

const calculateTotal = (costs: Object) => {
  if (isNilOrEmpty(costs)) return 0;

  return R.reduce((acc: Array, { total }: Object) => R.add(acc, total), 0, costs);
};

const calculateChargesTotal = (charges: Object) => {
  if (isNilOrEmpty(charges)) return 0;

  return R.reduce((acc: Array, item: Object) => {
    const rate = R.prop(GC.FIELD_CHARGE_TOTAL, item);

    if (R.isNil(rate)) return acc;

    const type = R.prop(GC.FIELD_CHARGE_TYPE, item);

    if (R.equals(type, GC.CHARGE_TYPE_DISCOUNT)) {
      return R.subtract(acc, rate);
    }

    return R.add(acc, rate);
  }, 0, charges);
};

const calculateChargesTotalExcludeDeductions = (charges: Object) => {
  if (isNilOrEmpty(charges)) return 0;

  return R.reduce((acc: Array, item: Object) => {
    const { total, deduction } = item;

    if (R.or(R.isNil(total), isTrue(deduction))) return acc;

    const type = R.prop(GC.FIELD_CHARGE_TYPE, item);

    if (R.equals(type, GC.CHARGE_TYPE_DISCOUNT)) {
      return R.subtract(acc, total);
    }

    return R.add(acc, total);
  }, 0, charges);
};

const calculateChargesTotalFromRate = (rate: Object) => (
  calculateChargesTotal(R.propOr(null, 'charges', rate))
);

const getChargesTotalFromRateWithCurrency = (rate: Object) => (
  `${getCurrencySymbol(getCurrencyFromRate(rate))} ${mathRoundNumber(calculateChargesTotalFromRate(rate))}`
);

const getTelRateCharges = (rate: Object, { rateType, onlyPrimaryDriver, concatDriverCharges }: Object) => {
  const primaryDriverCharges = R.path(['primaryDriverCharges'], rate);
  const secondaryDriverCharges = R.path(['secondaryDriverCharges'], rate);
  const carrierRateCharges = R.path(['carrierRateCharges'], rate);
  const fleetVendorCharges = R.path(['fleetVendorCharges'], rate);

  if (isRateTypeCarrierRate(rateType)) return carrierRateCharges;

  if (R.and(isRateTypeFleetRate(rateType), isNotNilAndNotEmpty(fleetVendorCharges))) return fleetVendorCharges;

  if (R.and(isRateTypeFleetRate(rateType), isTrue(onlyPrimaryDriver))) return primaryDriverCharges;

  if (R.and(isRateTypeFleetRate(rateType), isTrue(concatDriverCharges))) {
    return R.concat(R.or(primaryDriverCharges, []), R.or(secondaryDriverCharges, []));
  }

  if (isRateTypeFleetRate(rateType)) return { primaryDriverCharges, secondaryDriverCharges };

  return {
    fleetVendorCharges,
    carrierRateCharges,
    primaryDriverCharges,
    secondaryDriverCharges,
  };
};

const getTelChargesTotalFromRate = (rate: Object) => {
  const primaryDriverTotal = R.prop('primaryDriverTotal', rate);
  const secondaryDriverTotal = R.prop('secondaryDriverTotal', rate);

  if (R.and(isNilOrEmpty(primaryDriverTotal), isNilOrEmpty(secondaryDriverTotal))) return 0;

  return R.add(primaryDriverTotal, secondaryDriverTotal);
};

const getTelAdditionalChargesTotal = (rate: Object) => {
  const carrierAssignment = R.prop('carrierAssignment', rate);
  const carrierRateCharges = R.prop('carrierRateCharges', rate);

  if (R.and(isNotNil(carrierAssignment), isNotNil(carrierRateCharges))) {
    return getAdditionalChargesTotalFromCharges(carrierRateCharges);
  }

  const primaryDriverCharges = R.prop('primaryDriverCharges', rate);
  const secondaryDriverCharges = R.prop('secondaryDriverCharges', rate);
  const fleetVendorCharges = R.prop('fleetVendorCharges', rate);
  const primaryDriverTotal = getAdditionalChargesTotalFromCharges(primaryDriverCharges);
  const secondaryDriverTotal = getAdditionalChargesTotalFromCharges(secondaryDriverCharges);
  const fleetVendorTotal = getAdditionalChargesTotalFromCharges(fleetVendorCharges);

  if (isNotNilAndNotEmpty(fleetVendorCharges)) return fleetVendorTotal;

  return R.add(primaryDriverTotal, secondaryDriverTotal);
};

const getTotalTextFromRate = (selectedRate: Object) => {
  const rateCurrency = getCurrencyFromRate(selectedRate);
  const normalizedCurrency = R.path(['normalizedTotal', GC.FIELD_CURRENCY], selectedRate);
  const currencySymbol = getCurrencySymbol(R.or(normalizedCurrency, rateCurrency));
  const rateTotal = R.pathOr(0, [GC.FIELD_TOTAL], selectedRate);
  const normalizedTotal = R.path(['normalizedTotal', GC.FIELD_TOTAL], selectedRate);
  const total = R.or(normalizedTotal, rateTotal);

  return `${currencySymbol} ${total}`;
};

const calculateTelChargesTotal = (rate: Object) => {
  const carrierAssignment = R.prop(GC.SYSTEM_OBJECT_CARRIER_ASSIGNMENT, rate);
  const carrierRateCharges = R.prop(GC.FIELD_CARRIER_RATE_CHARGES, rate);

  if (R.and(isNotNil(carrierAssignment), isNotNil(carrierRateCharges))) {
    return calculateChargesTotal(carrierRateCharges);
  }

  const primaryDriverCharges = R.prop(GC.FIELD_PRIMARY_DRIVER_CHARGES, rate);
  const secondaryDriverCharges = R.prop(GC.FIELD_SECONDARY_DRIVER_CHARGES, rate);
  const primaryDriverTotal = calculateChargesTotal(primaryDriverCharges);
  const secondaryDriverTotal = calculateChargesTotal(secondaryDriverCharges);

  return R.add(primaryDriverTotal, secondaryDriverTotal);
};

const calculateLoadsRatesTotalFromRates = R.reduce(
  (acc: Array, load: Object) => {
    const rate = R.prop('rate', load);

    if (isNilOrEmpty(rate)) return acc;

    return R.add(acc, R.prop(GC.FIELD_CHARGE_TOTAL, rate));
  },
  0,
);

const calculateTelsRatesTotalFromCharges = R.reduce(
  (acc: Array, tel: Object) => {
    const rate = R.prop('rate', tel);

    if (isNilOrEmpty(rate)) return acc;

    let telTotal = calculateTelChargesTotal(rate);

    const branchGuid = getAmousCurrentBranchGuidFromWindow();
    const amousExchangeRateByBranch = getItemFromWindow('amousExchangeRateByBranch');

    const exchangeRate = R.pathOr(
      R.path([GC.SYSTEM_OBJECT_NORMALIZED_TOTAL, 'exchangeRate'], rate),
      [branchGuid, R.prop(GC.FIELD_CURRENCY, rate), 'exchangeRate'],
      amousExchangeRateByBranch,
    );

    if (isNotNilAndNotEmpty(exchangeRate)) {
      telTotal = R.multiply(exchangeRate, telTotal);
    }

    return R.add(acc, telTotal);
  },
  0,
);

const calculateChargesDiscountOrDeduction = (charges: Object, prop: string) => {
  if (isNilOrEmpty(charges)) return 0;

  return R.reduce((acc: Array, item: Object) => {
    const rate = R.prop(GC.FIELD_CHARGE_TOTAL, item);
    const calcProp = R.prop(prop, item);

    if (isTrue(calcProp)) return R.add(acc, rate);

    return acc;
  }, 0, charges);
};

const getTotalsValues = (props: Object) => {
  const { values, initialValues } = props;

  if (isNotNilAndNotEmpty(initialValues)) {
    return R.pick(GC.GROUPED_FIELDS.TRIP_TOTALS_ARR, initialValues);
  }

  return R.pick(GC.GROUPED_FIELDS.TRIP_TOTALS_ARR, values);
};

const getTotalsFromValues = ({ values }: Object) => R.pick(GC.GROUPED_FIELDS.TRIP_TOTALS_ARR, values);

const getDefaultUomFields = (asyncConfigs: Object) => {
  let defaultValue = {};

  const uomSystem = getConfigValueFromStore(
    GC.GENERAL_UOM_CALC_DEFAULT_UOM_SYSTEM,
    asyncConfigs,
  );

  if (R.and(isNotNil(uomSystem), isNotEmpty(uomSystem))) {
    defaultValue = GC.defaultSystemUoms[uomSystem];
  }

  return defaultValue;
};

const calculateMasterInvoiceDiscount = (charges: Object) => (
  calculateChargesDiscountOrDeduction(charges, GC.FIELD_CHARGE_DISCOUNT)
);

const calculatePayrollDriverDeduction = (charges: Object) => (
  calculateChargesDiscountOrDeduction(charges, GC.FIELD_CHARGE_DEDUCTION)
);

const isInvalidDriverRate = (values: Object) => {
  const {
    primaryDriverGuid,
    secondaryDriverGuid,
    primaryDriverCharges,
    secondaryDriverCharges,
  } = values;

  const primaryDriverMainChargeTotal = getChargeTotalFromCharges(
    primaryDriverCharges,
    GC.CHARGE_TYPE_MAIN,
  );

  const secondaryDriverMainChargeTotal = getChargeTotalFromCharges(
    secondaryDriverCharges,
    GC.CHARGE_TYPE_MAIN,
  );

  if (R.equals(primaryDriverGuid, secondaryDriverGuid)) {
    showToastrMessageFromLocale('info', 'messages:provid-drivers');

    return true;
  }

  if (isNilOrEmpty(primaryDriverMainChargeTotal)) {
    showToastrMessageFromLocale('info', 'messages:provide-main-change-for-primari-drivers');

    return true;
  }

  if (R.and(
    isNotNilAndNotEmpty(secondaryDriverGuid),
    isNilOrEmpty(secondaryDriverMainChargeTotal),
  )) {
    showToastrMessageFromLocale('info', 'messages:provide-main-change-for-team-drivers');

    return true;
  }

  return false;
};

const isTelStatusLessThenBooked = (telStatus: Object) => {
  const sequence = GC.TEL_STATUS_SEQUENCE_MAP[telStatus];

  return R.lt(sequence, 7);
};

const toZeroInvalidChargeRateAndTotal = (charge: Object) => {
  const { rate, total } = charge;

  return R.compose(
    R.assoc('total', fromNillOrEmptyToZero(total)),
    R.assoc('rate', fromNillOrEmptyToZero(rate)),
  )(charge);
};

const mapToZeroInvalidChargesRateAndTotal = (charges: Array) => R.map(
  toZeroInvalidChargeRateAndTotal,
  R.or(charges, []),
);

const getTelSingleCloTransportationMode = (clos: any) => {
  if (R.and(isArray(clos), R.equals(1, R.length(clos)))) {
    return R.path([0, 'rate', GC.FIELD_MODE, GC.FIELD_DROPDOWN_OPTION_GUID], clos);
  }

  return null;
};

const calculateFuelCardsTotal = R.compose(
  mathRoundNumber,
  R.sum,
  R.map(({ total, productType }: Object) => ifElse(
    R.equals(productType, GC.FUEL_CARDS_PRODUCT_TYPE_DISCOUNT),
    R.negate(total),
    total,
  )),
  R.filter(R.prop('selected')),
  R.reduce((acc: Array, fuelCard: Object) => R.concat(acc, fuelCard.details), []),
  R.filter(({ selected, removed }: Object) => R.and(selected, R.not(removed))),
);

const calculateTollChargesTotal = R.compose(
  mathRoundNumber,
  R.sum,
  R.map(R.prop(GC.FIELD_TOLL_CHARGES_AMOUNT)),
  R.filter(({ selected, removed }: Object) => R.and(selected, R.not(removed))),
);

const calculateExpensesTotal = R.compose(
  mathRoundNumber,
  R.sum,
  R.map(R.prop(GC.FIELD_TOTAL)),
  R.filter(({ selected, removed }: Object) => R.and(selected, R.not(removed))),
);

const calculateAdvancePaymentsTotal = R.compose(
  mathRoundNumber,
  R.sum,
  R.map((entity: Object) => R.subtract(
    R.prop(GC.FIELD_ADVANCE_PAYMENT_CHECK_AMOUNT, entity),
    R.prop(GC.FIELD_ADVANCE_PAYMENT_CHECK_BALANCE, entity),
  )),
  R.filter(({ selected, removed }: Object) => R.and(selected, R.not(removed))),
);

const calculateDriverPayrollsTotal = R.compose(
  mathRoundNumber,
  R.sum,
  R.map(R.prop(GC.FIELD_PAYROLL_GRAND_TOTAL)),
  R.filter(({ selected, removed }: Object) => R.and(selected, R.not(removed))),
);

const omitEmptyChargesFromCharges = (charges: Object) => {
  if (isNilOrEmpty(charges)) return charges;

  return R.reject(
    (charge: Object) => isNilOrEmpty(getDisplayedValueFromObject(charge)),
    charges,
  );
};

const omitEmptyChargesFromData = (data: Object, chargesName: string) => {
  const charges = R.pathOr([], [chargesName], data);

  if (isNilOrEmpty(charges)) return data;

  const chargesToUse = omitEmptyChargesFromCharges(charges);

  return R.assoc(chargesName, chargesToUse, data);
};

const omitEmptyChargesFromDriverRate = (data: Object) => R.reduce(
  (acc: Object, name: string) => omitEmptyChargesFromData(acc, name),
  data,
  [GC.FIELD_PRIMARY_DRIVER_CHARGES, GC.FIELD_SECONDARY_DRIVER_CHARGES, GC.FIELD_FLEET_VENDOR_CHARGES],
);

const omitEmptyPaymentsFromData = (data: Object) => R.assoc(
  GC.FIELD_PAYMENTS,
  R.filter(
    (payment: Object) => R.compose(
      R.not,
      isAllNilOrEmpty,
      R.values,
      R.dissoc('uniq'),
    )(payment),
    R.pathOr([], [GC.FIELD_PAYMENTS], data),
  ),
  data,
);

const transformPaymentsFromArrayToObjectWithReportKeys = R.compose(
  R.reduce((acc: Object, item: Object) => {
    const {
      checkDate,
      checkAmount,
      checkNumber,
      depositDate,
      achPaymentConfirmationNumber,
    } = item;

    return {
      [GC.GRC.PAYMENTS_CHECK_DATE]: R.append(
        checkDate,
        R.pathOr([], [GC.GRC.PAYMENTS_CHECK_DATE], acc),
      ),
      [GC.GRC.PAYMENTS_CHECK_AMOUNT]: R.append(
        checkAmount,
        R.pathOr([], [GC.GRC.PAYMENTS_CHECK_AMOUNT], acc),
      ),
      [GC.GRC.PAYMENTS_CHECK_NUMBER]: R.append(
        checkNumber,
        R.pathOr([], [GC.GRC.PAYMENTS_CHECK_NUMBER], acc),
      ),
      [GC.GRC.PAYMENTS_DEPOSIT_DATE]: R.append(
        depositDate,
        R.pathOr([], [GC.GRC.PAYMENTS_DEPOSIT_DATE], acc),
      ),
      [GC.GRC.PAYMENTS_ACH_PAYMENT_CONFIRMATION_NUMBER]: R.append(
        achPaymentConfirmationNumber,
        R.pathOr([], [GC.GRC.PAYMENTS_ACH_PAYMENT_CONFIRMATION_NUMBER], acc),
      ),
    };
  }, {}),
  R.pathOr([], [GC.FIELD_PAYMENTS]),
);

const getTotalCustomersRate = (props: Object, page: string) => {
  const { load } = props;

  if (R.equals(page, GC.PAGE_DISPATCH_DETAILS_NEW_LOAD)) {
    const clos = R.pathOr([], [GC.SYSTEM_LIST_CLOS], load);

    return R.reduce((acc: number, item: Object) => {
      const total = R.pathOr(0, [GC.FIELD_RATE, GC.FIELD_TOTAL], item);

      return R.add(acc, total);
    }, 0, clos);
  }

  return 0;
};

export {
  getRpmText,
  calculateTotal,
  getTotalsValues,
  calcChargesTotal,
  calcTelRateTotal,
  calcCloRateTotal,
  isChargeTypeFuel,
  isChargeTypeMain,
  fuelChargesFilter,
  mainChargesFilter,
  getCurrencySymbol,
  isCurrencyTypeUSD,
  getTelRateCharges,
  getCurrencyByPath,
  getCurrencySymbol2,
  getTotalFromCharge,
  getCurrencyFromRate,
  getTelMainTotalInfo,
  getTelFuelTotalInfo,
  getCloMainTotalInfo,
  getCloFuelTotalInfo,
  getDefaultUomFields,
  getTotalsFromValues,
  isInvalidDriverRate,
  isChargeTypeDiscount,
  getChargesFromObject,
  isNotCurrencyTypeUSD,
  getTotalTextFromRate,
  additionChargesFilter,
  discountChargesFilter,
  getRateTypeFromCharge,
  getTotalCustomersRate,
  getRateUnitFromCharge,
  getCurrencyFromObject,
  calcChargesArrayTotal,
  calculateChargesTotal,
  getQuantityFromCharge,
  isRateTypeFleetRate,
  isRateTypeCarrierRate,
  isContractTypeCarrier,
  isContractTypeCustomer,
  isChargeTypeAdditional,
  getCurrencyFromObject2,
  calcChargesTotalByType,
  getTelChargesTotalInfo,
  getCloChargesTotalInfo,
  getChargeTotalFromRate,
  calculateExpensesTotal,
  isAutoAssessorialCharge,
  getCalcRateChargesTotal,
  getTelDiscountTotalInfo,
  getCloDiscountTotalInfo,
  calculateFuelCardsTotal,
  getFilteredByTypeCharges,
  getRateTotalWithCurrency,
  calcAllChargesArrayTotal,
  createMainAndFuelCharges,
  calculateTelChargesTotal,
  omitEmptyChargesFromData,
  isChargeRateTypeLineHaul,
  getCurrencySymbolFromRate,
  getCalcTelRateTotalByType,
  addOneFuelChargeToCharges,
  addOneMainChargeToCharges,
  omitEmptyPaymentsFromData,
  getHoldQuantityFromCharge,
  calculateTollChargesTotal,
  isTelStatusLessThenBooked,
  getChargeTotalFromCharges,
  getChargeRateNameFromRate,
  getCalcTelRateChargesTotal,
  addMainFuelChargeToCharges,
  getTelChargesTotalFromRate,
  getRpmTextByUomSystemConfig,
  getCurrencySymbolFromCharge,
  addMainFuelChargeToCharges2,
  omitEmptyChargesFromCharges,
  isChargeRateTypeTaxableTotal,
  isChargeRateTypeCustomerRate,
  createMainAndDiscountCharges,
  getTelAdditionalChargesTotal,
  getChargeRateNameFromCharges,
  calculateDriverPayrollsTotal,
  getCalcTelRateChargesTotalPay,
  addOneDiscountChargeToCharges,
  convertCurrencyByExchangeRate,
  getCalcTelInvoiceChargesTotal,
  calculateChargesTotalFromRate,
  calculateAdvancePaymentsTotal,
  getRateTotalWithCurrencySymbol,
  omitEmptyChargesFromDriverRate,
  calculateMasterInvoiceDiscount,
  isInvoiceTypeFleetVendorInvoice,
  getCalcCloRateTotalByChargeType,
  calculatePayrollDriverDeduction,
  toZeroInvalidChargeRateAndTotal,
  isChargeRateTypeCustomerLineHaul,
  getChargePriceSheetCurrencyTotal,
  getCloAdditionalChargesTotalInfo,
  getTelAdditionalChargesTotalInfo,
  getAdditionalChargesTotalFromRate,
  getTelSingleCloTransportationMode,
  calculateLoadsRatesTotalFromRates,
  addMainDiscountFuelChargeToCharges,
  calculateTelsRatesTotalFromCharges,
  calculateChargesDiscountOrDeduction,
  mapToZeroInvalidChargesRateAndTotal,
  getChargesTotalFromRateWithCurrency,
  getAdditionalChargesTotalFromCharges,
  calculateChargesTotalExcludeDeductions,
  isChargeRateTypeCustomerRateOrLineHaul,
  convertToNormalizedCurrencyByExchangeRate,
  convertCurrencyByExchangeRateToFloatOrNull,
  isChargeRateTypeLineHaulOrCustomerLineHaul,
  getCalcRateChargesTotalWithoutInternalExpense,
  transformPaymentsFromArrayToObjectWithReportKeys,
  replaceAutoChargesWithNewSharedAssessorialsApplyTo,
  makeTelRateWithCorrectChargesForFleetVendorInvoice,
};
