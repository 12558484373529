import * as R from 'ramda';
// icons
import * as I from '../svgs';
// constants
import * as GC from '../constants';
// helpers
import { isZero, ifElse, isNotNil, isNotZero } from './helpers';
//////////////////////////////////////////////////

const spreadUiStyles = (styles: Object) => ifElse(
  R.is(Object, styles),
  styles,
  {},
);

const mergeAndSpreadUiStyles = (defaultStyles: Object, incoming: Object = {}) => spreadUiStyles(
  R.mergeRight(defaultStyles, incoming),
);

const scrollIntoElementView = ({ elementId }: Object) => {
  const element = document.getElementById(elementId);

  if (isNotNil(element)) element.scrollIntoView({ inline: 'center' });
};

const setElementBorderRight = (index: number) => ifElse(
  isZero(R.modulo(index, 2)),
  '1px solid',
  '',
);

const setElementBorderBottom = (index: number, length: number) => {
  const mod = R.modulo(index, 2);
  const evenElem = isZero(mod);
  const oddElem = isNotZero(mod);
  const lastElem = R.subtract(length, 1);
  const penultimateElement = R.subtract(length, 2);

  const evenElementAndPenultimate = R.and(
    evenElem,
    R.or(R.equals(penultimateElement, index), R.equals(lastElem, index)),
  );

  const addElementAndLast = R.and(oddElem, R.equals(lastElem, index));

  return ifElse(R.or(evenElementAndPenultimate, addElementAndLast), '', '1px solid');
};

const convertDataTypeToUiField = (field: any) => {
  if (R.is(Function, field)) return field();

  if (R.isNil(field)) return '';

  if (R.is(Boolean, field)) {
    return ifElse(
      R.equals(field, true),
      I.uiTrue,
      I.uiFalse,
    )();
  }

  if (R.is(Object, field)) return R.pathOr('', ['displayedValue'], field);

  return field;
};

const convertDataTypeToUiField2 = (field: any) => {
  if (R.is(Function, field)) return field();

  if (R.isNil(field)) return I.uiFalse();

  if (R.is(Boolean, field)) {
    return ifElse(
      R.equals(field, true),
      I.uiTrue,
      I.uiFalse,
    )();
  }

  if (R.is(Object, field)) return R.pathOr('', ['displayedValue'], field);

  return field;
};

// helpers for getting main logo link
const renderLogoLink = (currentTheme: string) => (
  `https://s3.amazonaws.com/amous-front-dev/logo/${currentTheme}-logo.svg`
);

const getNewLogoLink = () => 'https://amous-front-dev.s3.amazonaws.com/newAmousLogo.svg';

const getLogoLink = () => 'https://s3.amazonaws.com/amous-front-dev/logo/light-logo.svg';

// helper for compose image link
const composeImgLink = (themeName: string, name: string) => {
  if (R.isNil(name)) return;

  if (R.not(themeName)) { return `https://s3.amazonaws.com/amous-front-dev/icons/${name}.svg`; }

  return `https://s3.amazonaws.com/amous-front-dev/icons/${themeName}/${themeName}-${name}.svg`;
};

const getDocumentIconByDocumentFileName = (documentFilename: string, width: number, height: number) => {
  const fileExtension = R.compose(
    R.last,
    R.split('.'),
  )(R.or(documentFilename, ''));

  const iconMap = {
    [GC.EXTENSION_PDF]: I.pdf,
    [GC.EXTENSION_DOC]: I.word,
    [GC.EXTENSION_XLS]: I.excel,
    [GC.EXTENSION_DOCX]: I.word,
    [GC.EXTENSION_XLSX]: I.excel,
  };

  const defaultIcon = I.documents;

  return R.pathOr(defaultIcon, [fileExtension], iconMap)(null, width, height);
};

export {
  getLogoLink,
  spreadUiStyles,
  renderLogoLink,
  getNewLogoLink,
  composeImgLink,
  scrollIntoElementView,
  setElementBorderRight,
  mergeAndSpreadUiStyles,
  setElementBorderBottom,
  convertDataTypeToUiField,
  convertDataTypeToUiField2,
  getDocumentIconByDocumentFileName,
};
