import * as R from 'ramda';
// helpers
import { isNotNilAndNotEmpty } from './helpers';
//////////////////////////////////////////////////

const createIndexedDB = async ({
  idbName,
  idbKeyPath,
  idbIndexKey,
  idbStoreName,
}: Object,
  // eslint-disable-next-line flowtype/require-parameter-type
  onIDBCloseResolved = () => {},
) => (
  new Promise(async (res: Function, rej: Function) => {
    // eslint-disable-next-line no-undef
    const db = indexedDB.open(idbName, 1);

    db.onerror = (event: Object) => {
      const error = event.target.error;
      console.error(`IndexedDb ${idbName} error: ${error.message}`);
      rej(error.code);
    };

    db.onupgradeneeded = (event: Object) => {
      const db = event.target.result;
      const store = db.createObjectStore(idbStoreName, {keyPath: idbKeyPath});

      if (idbIndexKey) store.createIndex(idbIndexKey, idbIndexKey, { unique: false });

      console.log(`IndexedDb ${idbName} schema configured for store ${idbStoreName}`);
    };
    db.onsuccess = (event: Object) => {
      const db = event.target.result;

      db.onclose = () => {
        console.log(`Database ${idbName} connection closed`);
        onIDBCloseResolved();
      };
      res(db);
    };
  })
);

const readDataFromIDB = async ({
  idb,
  idbProps,
  credentials,
}: Object) => (
  new Promise(async (res: Function, rej: Function) => {
    const { idbStoreName } = idbProps;
    const tx = idb.transaction(idbStoreName, 'readonly');
    const store = tx.objectStore(idbStoreName);

    const read = store.get(credentials);

    read.onsuccess = () => res(read.result);
    read.onerror = () => rej(read.errorCode);
  })
);

const readDataByIndexKeyFromIDB = async ({
  idb,
  byKey,
  idbProps,
  credentials,
}: Object) => (
  new Promise(async (res: Function, rej: Function) => {
    const { idbStoreName } = idbProps;
    const tx = idb.transaction(idbStoreName, 'readonly');
    const store = tx.objectStore(idbStoreName);
    const indexByKey = store.index(byKey);

    const read = indexByKey.getAllKeys(credentials);

    read.onsuccess = () => res(read.result);
    read.onerror = () => rej(read.errorCode);
  })
);

const deleteDataByKeyFromIDB = async ({
  idb,
  idbProps,
  credentials,
}: Object) => (
  new Promise(async (res: Function, rej: Function) => {
    const { idbStoreName } = idbProps;
    const tx = idb.transaction(idbStoreName, 'readwrite');
    const store = tx.objectStore(idbStoreName);

    const deleteRequest = store.delete(credentials);

    deleteRequest.onsuccess = () => res(deleteRequest.result);
    deleteRequest.onerror = () => rej(deleteRequest.errorCode);
  })
);

const deleteDataByIndexKeyFromIDB = async ({
  idb,
  idbProps,
  credentials,
}: Object) => (
  new Promise(async (res: Function, rej: Function) => {
    const { idbIndexKey, idbStoreName } = idbProps;
    const tx = idb.transaction(idbStoreName, 'readwrite');
    const store = tx.objectStore(idbStoreName);
    const indexByKey = store.index(idbIndexKey);

    const search = indexByKey.getAllKeys(credentials);

    search.onsuccess = () => {
      const requests = search.result.map((item: string) => {
        const deletePromise = new Promise((res: Function, rej: Function) => {
          const deleteRequest = store.delete(item);

          deleteRequest.onsuccess = () => res(deleteRequest.result);
          deleteRequest.onerror = () => rej(deleteRequest.errorCode);
        });

        return deletePromise;
      });

      Promise.all(requests)
        .then(() => {
          res();
        })
        .catch((error: Error) => {
          rej(error.message);
        });
    };

    search.onerror = () => rej(search.errorCode);
  })
);

const deleteDatabase = async ({
  idb,
  idbProps,
}: Object) => (
  new Promise(async (res: Function, rej: Function) => {
    const { idbName } = idbProps;
    // eslint-disable-next-line no-undef
    const deleteRequest = indexedDB.deleteDatabase(idbName);

    deleteRequest.onerror = (event: Object) => {
      const error = event.target.error;
      console.error(`IndexedDb ${idbName} error: ${error.message}`);
      rej(error.code);
    };

    deleteRequest.onsuccess = (event: Object) => {
      const db = event.target.result;
      console.warn(`Database ${idbName} was dropped`);
      res(db);
    };

    deleteRequest.onblocked = () => {
      console.warn(`Couldn't delete database ${idbName} due to the operation being blocked`);
      console.warn('Trying close it!!!');

      idb.close();

      res(idb);
    };
  })
);

const setDataToIDB = async ({
  idb,
  idbProps,
  dataToSet,
  credentials,
  additionalData,
}: Object) => (
  new Promise(async (res: Function, rej: Function) => {
    const { idbStoreName, idbKeyPath } = idbProps;
    const tx = idb.transaction(idbStoreName, 'readwrite');
    const store = tx.objectStore(idbStoreName);

    const write = store.put({
      value: dataToSet,
      [idbKeyPath]: credentials,
      ...(R.and(isNotNilAndNotEmpty(additionalData), additionalData)),
    });

    write.onsuccess = () => res(write.result);
    write.onerror = () => rej(write.errorCode);
  })
);

// Use with UI
const openIndexedDB = (idbProps: Object) => {
  const { idbName, idbStoreName, idbKeyPath = 'id' } = idbProps;

  return new Promise((resolve: Function, reject: Function) => {
    const request = indexedDB.open(idbName, 1); // eslint-disable-line

    request.onupgradeneeded = (event: Object) => {
      const db = event.target.result;

      if (R.not(db.objectStoreNames.contains(idbStoreName))) {
        db.createObjectStore(idbStoreName, { keyPath: idbKeyPath });
      }
    };

    request.onsuccess = (event: Object) => resolve(event.target.result);
    request.onerror = (event: Object) => reject(event.target.errorCode);
  });
};

const saveIndexedDBItem = async (idbProps: Object, item: Object) => {
  const db = await openIndexedDB(idbProps);

  const { idbStoreName } = idbProps;

  return new Promise((resolve: Function, reject: Function) => {
    const transaction = db.transaction([idbStoreName], 'readwrite');
    const objectStore = transaction.objectStore(idbStoreName);

    const request = objectStore.put(item);

    request.onsuccess = () => resolve(request.result);
    request.onerror = () => reject(request.errorCode);
  });
};

const getIndexedDBItem = async (idbProps: object, id: string) => {
  const db = await openIndexedDB(idbProps);

  const { idbStoreName } = idbProps;

  return new Promise((resolve: Function, reject: Function) => {
    const transaction = db.transaction([idbStoreName]);
    const objectStore = transaction.objectStore(idbStoreName);

    const request = objectStore.get(id);

    request.onsuccess = (event: Object) => resolve(event.target.result);
    request.onerror = (event: Object) => reject(event.target.errorCode);
  });
};
//

export {
  setDataToIDB,
  deleteDatabase,
  createIndexedDB,
  readDataFromIDB,
  deleteDataByKeyFromIDB,
  readDataByIndexKeyFromIDB,
  deleteDataByIndexKeyFromIDB,
  // UI
  openIndexedDB,
  getIndexedDBItem,
  saveIndexedDBItem,
};

