import * as R from 'ramda';
import { compose, withState, lifecycle, withHandlers } from 'react-recompose';
// helpers/constants
import * as G from '../helpers';
import * as GC from '../constants';
// utilities
import { sendRequest } from '../utilities/http';
import endpointsMap from '../utilities/endpoints';
/////////////////////////////////////////////////

export const withAsyncInvoicesCount = compose(
  withState('invoicesCount', 'setInvoicesCount', null),
  withState('invoicesCountLoading', 'setInvoicesCountLoading', true),
  withHandlers({
    getAsyncData: (props: Object) => async () => {
      const {
        telGuid,
        cloGuid,
        setInvoicesCount } = props;

      const endpoint = G.ifElse(G.isNotNil(telGuid), endpointsMap.telInvoicesCount, endpointsMap.cloInvoicesCount);
      const params = G.ifElse(G.isNotNil(telGuid), { telGuid }, { cloGuid });
      const res = await sendRequest('get', endpoint, { params });
      const { data, status } = res;
      if (G.isResponseSuccess(status)) {
        setInvoicesCount(data);
      } else {
        G.handleFailResponseSimple(res);
      }
    },
    handleSetInvoiceStatus: (props: Object) => () => {
      const {
        telGuid,
        selectedRate,
        activeDriver,
        defaultStatus,
        invoicesCount,
        setFieldValue,
        invoiceAssignments,
        defaultSecondInvoiceStatus,
      } = props;

      if (R.equals(invoicesCount, 0)) return setFieldValue(GC.FIELD_INVOICE_STATUS, defaultStatus);

      let hasInvoice = R.gt(invoicesCount, 0);

      if (R.and(G.isNotNil(telGuid), G.isNotNil(R.path([GC.SYSTEM_OBJECT_FLEET_ASSIGNMENT], selectedRate)))) {
        if (R.equals(activeDriver, 'vendor')) {
          const assignments = R.indexBy(R.prop(GC.FIELD_FLEET_VENDOR_GUID), invoiceAssignments);
          const fleetVendorGuid = R.path(
            [GC.SYSTEM_OBJECT_FLEET_ASSIGNMENT, GC.FIELD_TRUCK, GC.FIELD_FLEET_VENDOR_GUID],
            selectedRate,
          );
          hasInvoice = G.isNotNil(R.path([fleetVendorGuid], assignments));
        } else {
          const assignments = R.indexBy(R.prop(GC.FIELD_DRIVER_GUID), invoiceAssignments);
          const path = G.ifElse(
            R.equals(activeDriver, 'primary'),
            [GC.SYSTEM_OBJECT_FLEET_ASSIGNMENT, GC.FIELD_PRIMARY_DRIVER, GC.FIELD_GUID],
            [GC.SYSTEM_OBJECT_FLEET_ASSIGNMENT, GC.FIELD_SECONDARY_DRIVER, GC.FIELD_GUID],
          );
          const driverGuid = R.path(path, selectedRate);
          hasInvoice = G.isNotNil(R.path([driverGuid], assignments));
        }
      }

      const statusToUse = G.ifElse(hasInvoice, defaultSecondInvoiceStatus, defaultStatus);

      setFieldValue(GC.FIELD_INVOICE_STATUS, statusToUse);
    },
  }),
  lifecycle({
    componentDidMount() {
      this.props.getAsyncData();
    },
    componentDidUpdate(prevProps: Object) {
      const { activeDriver, invoicesCount, invoiceAssignments, handleSetInvoiceStatus } = this.props;

      const prevActiveDriver = R.path(['activeDriver'], prevProps);
      const prevInvoiceAssignments = R.path(['invoiceAssignments'], prevProps);
      const prevInvoicesCount = R.path(['invoicesCount'], prevProps);
      const condition = G.isAnyTrue(
        G.notEquals(activeDriver, prevActiveDriver),
        G.notEquals(invoicesCount, prevInvoicesCount),
        G.notEquals(invoiceAssignments, prevInvoiceAssignments),
      );

      if (condition) handleSetInvoiceStatus();
    },
  }),
);
