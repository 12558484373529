import * as R from 'ramda';
// helpers
import { isTrue, ifElse, isArray, isString, isNotNil, isNotEmpty, isNilOrEmpty, isNotNilAndNotEmpty } from './helpers';
//////////////////////////////////////////////////

const replaceSnake = (str: string) => str.replace(/_/g, ' ');

const toTitleCase = (string: string = '') => {
  if (R.is(String, string)) {
    return string.charAt(0).toUpperCase() + string.substr(1).toLowerCase();
  }

  return string;
};

const fromSnakeToTitleCase = (str: string) => R.compose(
  toTitleCase,
  replaceSnake,
)(str);

const stringContains = (string: string = '', expression: any) => string.includes(expression);

const isEmptyString = (item: any) => R.and(isString(item), R.isEmpty(item));

const isNotEmptyString = (item: any) => R.not(isEmptyString(item));

const fromNullToEmptyString = (item: any) => ifElse(R.isNil(item), '', item);

const fromEmptyStringToNull = (item: any) => ifElse(isEmptyString(item), null, item);

const getNumberFromString = (text: string = '') => text.replace(/\D/g, '');

const getStringDepOnBool = (bool: boolean, str: string) => ifElse(
  isTrue(bool),
  '',
  R.or(str, ', '),
);

const cutString = (text: string, length: number, withEllipsis: boolean) => {
  if (!text) return text;

  let substring = text;

  if (text.length > length) {
    substring = text.substring(0, length);

    if (withEllipsis) {
      substring = `${substring}...`;
    }
  }

  return substring;
};

const makeURLString = (url: string = null) => {
  if (R.or(R.isNil(url), R.not(R.is(String, url)))) return url;

  const start = url.slice(0, 4);

  if (R.equals(start, 'http')) {
    return url;
  }

  return `//${url}`;
};

const checkStringsContains = (inputs: Array, checked: Array) => {
  let isAccess = false;

  R.forEach((item: string) => {
    const contain = R.includes(item, checked);

    if (contain) isAccess = true;
  }, inputs);

  return isAccess;
};

const checkStringsContainsIfInput = (inputs: Array, checked: Array) => {
  if (isNilOrEmpty(inputs)) return true;

  let isAccess = false;

  R.forEach((item: string) => {
    const contain = R.includes(item, checked);

    if (contain) isAccess = true;
  }, inputs);

  return isAccess;
};

const createStringFromArray = (arr: Array = [], joiner: string = ' ') => {
  if (R.not(isArray(arr))) return arr;

  const check = (item: any) => R.and(isNotEmpty(item), isNotNil(item));

  return arr.filter(check).join(joiner);
};

const createStringFromArrayCurried = R.curry((joiner: string, arr: Array) => {
  if (R.not(isArray(arr))) return arr;

  const check = (item: any) => R.and(isNotEmpty(item), isNotNil(item));

  return arr.filter(check).join(joiner);
});

const createStringFromArrayWithProp = (
  array: Array = [],
  joiner: string = ', ',
  prop: string,
) => {
  const check = (item: any) => R.and(isNotEmpty(item), isNotNil(item));
  const getProp = (item: Object) => R.path([prop], item);

  if (isNotNilAndNotEmpty(prop)) {
    return array.map(getProp).filter(check).join(joiner);
  }

  return array.filter(check).join(joiner);
};

const getStringTailBySymbol = (str: string, symbol: string) => {
  const symbolIndex = R.indexOf(symbol, str);

  return R.slice(R.inc(symbolIndex), Infinity, str);
};

const isPlural = (count: number, str: string) =>
  ifElse(
    R.gt(count, 1),
    `${str}s`,
    str,
  );

const getStringWidth = (string: string, font: number | string) => {
  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d');

  context.font = font;

  const metrics = context.measureText(string);

  return metrics.width;
};

export {
  isPlural,
  cutString,
  toTitleCase,
  replaceSnake,
  makeURLString,
  isEmptyString,
  stringContains,
  getStringWidth,
  isNotEmptyString,
  getStringDepOnBool,
  getNumberFromString,
  fromSnakeToTitleCase,
  checkStringsContains,
  fromEmptyStringToNull,
  getStringTailBySymbol,
  createStringFromArray,
  fromNullToEmptyString,
  checkStringsContainsIfInput,
  createStringFromArrayCurried,
  createStringFromArrayWithProp,
};
