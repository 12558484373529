import * as R from 'ramda';
import * as P from 'plow-js';
import React, { Component } from 'react';
// helpers/constants
import * as G from '../helpers';
// utilities
import { sendRequest } from '../utilities/http';
///////////////////////////////////////////////////////////////////////////////////////////////////

class ReportList extends Component {
  constructor(props: Object) {
    super(props);
    this.state = { data: null, error: false, loading: true };
    this.getReportListRequest = this.getReportListRequest.bind(this);
    this.setSelectedItemToAsyncReport = this.setSelectedItemToAsyncReport.bind(this);
  }

  async getReportListRequest(requestAsyncReportOptions: Object) {
    const { openLoader, closeLoader, asyncReportOptions, asyncReportEndpoint } = this.props;

    if (G.isFunction(openLoader)) openLoader({ showDimmer: true});

    const options = R.or(requestAsyncReportOptions, asyncReportOptions);

    const res = await sendRequest('post', asyncReportEndpoint, options);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      const dataToUse = R.pathOr(data, ['results'], data);

      if (G.isNotNil(dataToUse)) {
        const newState = P.$all(
          P.$set('data', G.setPropsWithValuesToArrayItems(
            dataToUse,
            [{ value: false, name: 'selected' }],
          )),
          P.$set('loading', false),
          this.state,
        );

        this.setState(newState);
      }
    } else {
      G.handleFailResponseSimple(
        res,
        false,
        'getReportListRequest -> getReportListRequest',
      );
    }

    if (G.isFunction(closeLoader)) closeLoader();
  }

  setSelectedItemToAsyncReport(item: string) {
    const currentDriversState = this.state.data;
    const newState = P.$set('data', G.selectItem(currentDriversState, item), this.state);
    this.setState(newState);
  }

  render() {
    return <div>{this.props.render(this.state, this.getReportListRequest, this.setSelectedItemToAsyncReport)}</div>;
  }
}

const withAsyncReport = (Component: any) => (
  class extends React.Component { // eslint-disable-line
    render() {
      return (
        <ReportList
          openLoader={this.props.openLoader}
          closeLoader={this.props.closeLoader}
          asyncReportOptions={this.props.asyncReportOptions}
          asyncReportEndpoint={this.props.asyncReportEndpoint}
          render={(parentState: Object, getReportListRequest: Function, setSelectedItemToAsyncReport: Function) =>
            <Component
              {...this.props}
              asyncReportListState={parentState}
              getReportListRequest={getReportListRequest}
              setSelectedItemToAsyncReport={setSelectedItemToAsyncReport}
            />
          }
        />
      );
    }
  }
);

export { withAsyncReport };
