import * as R from 'ramda';
import { pure, compose, withState, withPropsOnChange } from 'react-recompose';
// helpers/constants
import * as GC from '../constants';
import * as G from '../helpers';
// hocs
import { withAsyncConfigs } from './with-async-configs';
// utilities
import { sendRequest } from '../utilities/http';
import endpointsMap from '../utilities/endpoints';
//////////////////////////////////////////////////

export async function getSequenceRequest(props: Object) {
  const { branchGuid, setSequence, sequenceGuid } = props;

  const params = { [GC.FIELD_BRANCH_GUID]: branchGuid };

  const res = await sendRequest('get', endpointsMap.getSequenceEndpoint(sequenceGuid), { params });

  const { status, data } = res;

  if (R.and(G.isResponseSuccess(status), G.isNotEmpty(data))) {
    const { prefix, suffix, approxValue } = data;
    const arrayOfValues = [prefix, approxValue, suffix];
    const value = G.createStringFromArray(arrayOfValues, '');
    setSequence(value);
  } else {
    G.handleFailResponseSimple(
      res,
      false,
      'withAsyncSequence -> getSequenceRequest',
    );
  }
}

export const getSequenceGuidFromConfig = (
  configs: Array,
  sequenceConfigName: string,
  autogenerateConfigName: string,
) => {
  const autogenerate = G.getConfigValueFromStore(autogenerateConfigName, configs);

  if (R.not(autogenerate)) return null;

  return G.getConfigValueFromStore(sequenceConfigName, configs);
};

export const withAsyncSequence = compose(
  withAsyncConfigs,
  withState('asyncSequence', 'setSequence', null),
  withPropsOnChange(
    ['asyncConfigs'],
    ({
      branchGuid,
      setSequence,
      asyncConfigs,
      sequenceConfigName,
      autogenerateConfigName,
    }: Object) => {
      const sequenceGuid = getSequenceGuidFromConfig(asyncConfigs, sequenceConfigName, autogenerateConfigName);

      if (R.and(
        G.isNotNilAndNotEmpty(branchGuid),
        G.isNotNilAndNotEmpty(sequenceGuid),
      )) {
        getSequenceRequest({ branchGuid, setSequence, sequenceGuid });
      }
    },
  ),
  pure,
);

export default withAsyncSequence;
