import * as R from 'ramda';
// constants
import * as GC from '../constants';
// helpers
import { isNilOrEmpty } from './helpers';
import { getAmousConfigByNameFromWindow } from './window';
import { toTitleCase, createStringFromArray } from './string';
//////////////////////////////////////////////////

const getFirstDriverInfo = ({ fleetAssignment }: Object) => {
  const firstName = R.pathOr('', [GC.SYSTEM_OBJECT_PRIMARY_DRIVER, GC.FIELD_FIRST_NAME], fleetAssignment);
  const lastName = R.pathOr('', [GC.SYSTEM_OBJECT_PRIMARY_DRIVER, GC.FIELD_LAST_NAME], fleetAssignment);

  return `${firstName} ${lastName}`;
};

const getFirstDriverInfoLoginId = ({ fleetAssignment }: Object) => {
  const loginId = R.pathOr('', [GC.SYSTEM_OBJECT_PRIMARY_DRIVER, GC.FIELD_USER_LOGIN_ID], fleetAssignment);

  if (isNilOrEmpty(loginId)) return '';

  return loginId;
};

const getTeamDriverInfo = ({ fleetAssignment }: Object) => {
  const firstName = R.pathOr('', [GC.SYSTEM_OBJECT_SECONDARY_DRIVER, GC.FIELD_FIRST_NAME], fleetAssignment);
  const lastName = R.pathOr('', [GC.SYSTEM_OBJECT_SECONDARY_DRIVER, GC.FIELD_LAST_NAME], fleetAssignment);

  if (R.and(isNilOrEmpty(firstName), isNilOrEmpty(lastName))) return null;

  return `${firstName} ${lastName}`;
};

const getTeamDriverInfoLoginId = ({ fleetAssignment }: Object) => {
  const loginId = R.pathOr('', [GC.SYSTEM_OBJECT_SECONDARY_DRIVER, GC.FIELD_USER_LOGIN_ID], fleetAssignment);

  if (isNilOrEmpty(loginId)) return '';

  return loginId;
};

const getPrimaryDriverGuid = ({ fleetAssignment }: Object) => {
  if (isNilOrEmpty(fleetAssignment)) return null;

  return R.pathOr('', [GC.FIELD_PRIMARY_DRIVER, GC.FIELD_GUID], fleetAssignment);
};

const getTeamDriverGuid = ({ fleetAssignment }: Object) => {
  if (isNilOrEmpty(fleetAssignment)) return null;

  return R.pathOr('', [GC.FIELD_SECONDARY_DRIVER, GC.FIELD_GUID], fleetAssignment);
};

const getPrimaryDriverPhone = ({ fleetAssignment }: Object) => {
  if (isNilOrEmpty(fleetAssignment)) return '';

  return R.pathOr('', [GC.FIELD_PRIMARY_DRIVER, GC.FIELD_PHONE_NUMBER], fleetAssignment);
};

const getPrimaryDriverEmail = ({ fleetAssignment }: Object) => {
  if (isNilOrEmpty(fleetAssignment)) return '';

  return R.pathOr('', [GC.FIELD_PRIMARY_DRIVER, GC.FIELD_EMAIL], fleetAssignment);
};

const getTeamDriverPhone = ({ fleetAssignment }: Object) => {
  if (isNilOrEmpty(fleetAssignment)) return '';

  return R.pathOr('', [GC.FIELD_SECONDARY_DRIVER, GC.FIELD_PHONE_NUMBER], fleetAssignment);
};

const getTeamDriverEmail = ({ fleetAssignment }: Object) => {
  if (isNilOrEmpty(fleetAssignment)) return '';

  return R.pathOr('', [GC.FIELD_SECONDARY_DRIVER, GC.FIELD_EMAIL], fleetAssignment);
};

const getTrailersInfo = ({ fleetAssignment }: Object) => {
  if (isNilOrEmpty(fleetAssignment)) return '';

  const trailers = R.map((item: Object) => (
    R.prop(GC.FIELD_TRUCK_UNIT_ID, item)
  ), R.pathOr([], [GC.SYSTEM_LIST_TRAILERS], fleetAssignment));

  return createStringFromArray(trailers, ', ');
};

const getTruckInfo = ({ fleetAssignment }: Object) => {
  if (isNilOrEmpty(fleetAssignment)) return '';

  return R.pathOr('', [GC.FIELD_TRUCK, GC.FIELD_TRUCK_UNIT_ID], fleetAssignment);
};

const getFleetProfileRoutePathNameByFleetType = (type: string = 'driver') => {
  const config = getAmousConfigByNameFromWindow(GC.UI_USE_NEW_FLEET_PROFILE);

  if (config) return `fleet${toTitleCase(type)}Profile`;

  const routeNames = {
    truck: 'editTruckPage',
    driver: 'editDriverPage',
    trailer: 'editTrailerPage',
    vendor: 'editFleetVendorPage',
  };

  return R.prop(type, routeNames);
};

export {
  getTruckInfo,
  getTrailersInfo,
  getTeamDriverInfo,
  getTeamDriverGuid,
  getTeamDriverEmail,
  getFirstDriverInfo,
  getTeamDriverPhone,
  getPrimaryDriverGuid,
  getPrimaryDriverEmail,
  getPrimaryDriverPhone,
  getTeamDriverInfoLoginId,
  getFirstDriverInfoLoginId,
  getFleetProfileRoutePathNameByFleetType,
};
