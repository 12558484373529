import * as R from 'ramda';
import * as P from 'plow-js';
import React, { Component } from 'react';
// helpers/constants
import * as G from '../helpers';
import * as GC from '../constants';
// utilities
import { sendRequest } from '../utilities/http';
import endpointsMap from '../utilities/endpoints';
//////////////////////////////////////////////////

class ReferenceTypes extends Component {
  constructor(props: Object) {
    super(props);
    this.state = { referenceTypes: [] };
    this.getReferenceTypesByScopeName = this.getReferenceTypesByScopeName.bind(this);
  }

  async getReferenceTypesByScopeName(scopeName: string, branchGuid: string) {
    const res = await sendRequest('get', endpointsMap.referenceTypeScopes);
    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      const scopeGuid = R.compose(
        R.prop('guid'),
        R.find(R.propEq(scopeName, 'name')),
      )(data);
      const options = { params: { scopeGuid, [GC.FIELD_BRANCH_GUID]: branchGuid } };
      const secondRes = await sendRequest(
        'get',
        endpointsMap.availableReferenceTypesForScope,
        options,
      );
      if (G.isResponseSuccess(secondRes.status)) {
        const newState = P.$set('referenceTypes', secondRes.data, this.state);
        return this.setState(newState);
      }
    }
    return G.handleFailResponse(res, 'withReferenceTypes -> getReferenceTypesByScopeName');
  }

  render() {
    return (
      <div>{this.props.render(this.state, this.getReferenceTypesByScopeName)}</div>
    );
  }
}

export const withReferenceTypes = (Component: any) => {
  return class extends React.Component {
    render() {
      return (
        <ReferenceTypes
          render={(
            parentState: Object,
            getReferenceTypesByScopeName: Function,
          ) => (
            <Component
              {...this.props}
              parentState={parentState}
              getReferenceTypesByScopeName={getReferenceTypesByScopeName} />
          )} />
      );
    }
  };
};
