import * as R from 'ramda';
//////////////////////////////////////////////////

const env = process.env.NODE_ENV;

const apiDomain = process.env.API_DOMAIN;

const isLocalhost = R.equals(env, 'localhost');
const isDevelopment = R.or(isLocalhost, R.equals(env, 'development'));
const isNotLocalDevelopment = R.not(R.equals(apiDomain, 'localDevelopment'));
const isStaging = R.and(R.equals(env, 'production'), R.equals(apiDomain, 'staging'));
const isProduction = R.and(R.equals(env, 'production'), R.equals(apiDomain, 'production'));

const isNotProduction = R.not(isProduction);

export {
  apiDomain,
  isStaging,
  isLocalhost,
  isProduction,
  isDevelopment,
  isNotProduction,
  isNotLocalDevelopment,
};
