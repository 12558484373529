import * as R from 'ramda';
import { connect } from 'react-redux';
import { compose, withState, lifecycle, withHandlers } from 'react-recompose';
// components
import { openLoader, closeLoader } from '../components/loader/actions';
// helpers/constants
import * as G from '../helpers';
import * as GC from '../constants';
// utilities
import { sendRequest } from '../utilities/http';
import endpointsMap from '../utilities/endpoints';
/////////////////////////////////////////////////

export const withAsyncDocumentTemplates = compose(
  connect(null, { openLoader, closeLoader }),
  withState('asyncDocumentTemplates', 'setAsyncDocumentTemplates', null),
  withHandlers({
    getDocumentTemplates: (props: Object) => async () => {
      const { branchGuid, openLoader, closeLoader, setAsyncDocumentTemplates } = props;

      openLoader();

      const currentBranchGuid = G.getCurrentBranchGuid();

      const branchGuidToUse = R.or(branchGuid, currentBranchGuid);

      const options = {
        params: { [GC.FIELD_BRANCH_GUID]: branchGuidToUse },
      };

      const res = await sendRequest('get', endpointsMap.availableDocumentTemplates, options);

      const { data, status } = res;

      if (G.isResponseSuccess(status)) {
        setAsyncDocumentTemplates(R.groupBy(R.prop(GC.FIELD_PRINTABLE_SECTION), data));
      } else {
        G.handleFailResponseSimple(res);
      }
      closeLoader();
    },
  }),
  lifecycle({
    componentDidMount() {
      this.props.getDocumentTemplates();
    },
  }),
);

export const withAsyncDocumentTemplatesByTelGuid = compose(
  withState('asyncDocumentTemplates', 'setAsyncDocumentTemplates', R.path(['telDocumentTemplates'])),
  withHandlers({
    getDocumentTemplates: (props: Object) => async () => {
      const { telGuid, openLoader, closeLoader, setAsyncDocumentTemplates, setDocumentTemplatesToStore } = props;

      openLoader();

      const options = {
        params: { telGuid },
      };

      if (G.isNilOrEmpty(telGuid)) return;

      const res = await sendRequest('get', endpointsMap.getDocumentTemplatesByTelGuid, options);

      const { data, status } = res;

      if (G.isResponseSuccess(status)) {
        const gropedByPrintableSectionDocumentTemplates = R.groupBy(R.prop(GC.FIELD_PRINTABLE_SECTION), data);

        setAsyncDocumentTemplates(gropedByPrintableSectionDocumentTemplates);

        G.callFunctionWithArgs(setDocumentTemplatesToStore, gropedByPrintableSectionDocumentTemplates);
      } else {
        G.handleFailResponseSimple(res);
      }

      closeLoader();
    },
  }),
  lifecycle({
    componentDidMount() {
      const { telDocumentTemplates, getDocumentTemplates } = this.props;

      if (G.isNilOrEmpty(telDocumentTemplates)) getDocumentTemplates();
    },
  }),
);
