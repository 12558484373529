import * as R from 'ramda';
import React from 'react';
import { connect } from 'react-redux';
import { pure, compose, withHandlers } from 'react-recompose';
// common
import { hideListIssuesByListNameRequest } from '../common/actions';
// helpers/constants
import * as G from '../helpers';
import * as GC from '../constants';
// hocs
import { withFixedPopover } from './with-fixed-popover';
// ui
import { Flex, Box, StickedBox } from '../ui';
// //////////////////////////////////////////////////

const getWarningLevelColor = (warningLevel: string) => {
  const colors = {
    [GC.NOTIFICATION_WARNING_LEVEL_TYPE_INFO]: 'colors.light.blue',
    [GC.NOTIFICATION_WARNING_LEVEL_TYPE_WARNING]: 'colors.light.yellow',
    [GC.NOTIFICATION_WARNING_LEVEL_TYPE_CRITICAL]: 'colors.light.mainRed',
  };

  return G.getTheme(R.pathOr('colors.white', [warningLevel], colors));
};

const textStyles = {
  px: 10,
  wordBreak: 'break-all',
};

const IssueItem = ({ type, warningLevel, expirationDate, issueObjectName, issueObjectNames }: Object) => (
  <Flex
    py={18}
    fontSize={12}
    minHeight='60px'
    width='max-content'
    borderBottom='1px solid'
    bg={G.getTheme('colors.bgLightBlue')}
    borderColor={G.getTheme('colors.light.middleGrey')}
  >
    <Box px={10} width={110} fontWeight='bold' color={getWarningLevelColor(warningLevel)}>
      {warningLevel}
    </Box>
    <Box {...textStyles} width={110}>{expirationDate}</Box>
    <Box {...textStyles} width={150}>{G.getEnumLocale(type)}</Box>
    <Box {...textStyles} width={200}>
      {G.isNotNilAndNotEmpty(issueObjectNames) && R.join(', ', issueObjectNames)}
      {G.isNilOrEmpty(issueObjectName) && issueObjectName}
    </Box>
  </Flex>
);

const IssueList = ({ issues, handleHideIssues }: Object) => (
  <Flex
    bg='white'
    width='100%'
    maxHeight={500}
    overflowY='auto'
    height='max-content'
    flexDirection='column'
    alignItems='flex-start'
    borderBottom='1px solid'
    borderColor={G.getTheme('colors.light.middleGrey')}
  >
    <StickedBox
      top='0px'
      width='100%'
      bg={G.getTheme('colors.white')}
    >
      <Box
        cursor='pointer'
        width='max-content'
        m='8px 10px 8px auto'
        color={G.getTheme('colors.dark.blue')}
        onClick={() => handleHideIssues(issues)}
      >
        {
          `${
            G.getWindowLocale('actions:hide', 'Hide')
            } ${G.getWindowLocale('titles:notifications', 'Notifications')}`
        }
      </Box>
      <Flex
        py={16}
        fontSize={12}
        fontWeight='bold'
        color={G.getTheme('colors.dark.blue')}
        bg={G.getTheme('colors.light.lightGrey')}
      >
        <Box px={10} width={110}>{G.getWindowLocale('titles:warning-level', 'Warning Level')}</Box>
        <Box px={10} width={110}>{G.getWindowLocale('titles:expiration-date', 'Expiration Date')}</Box>
        <Box px={10} width={150}>{G.getWindowLocale('titles:issue-type', 'Issue Type')}</Box>
        <Box px={10} width={200}>{G.getWindowLocale('titles:issue-object-name', 'Issue Object Name')}</Box>
      </Flex>
    </StickedBox>
    {issues.map((issue: Object, index: number) => <IssueItem key={index} {...issue} />)}
  </Flex>
);

export const withShowListIssues = ({ listName, getListRequestActionName }: Object) => compose(
  connect(null, { hideListIssuesByListNameRequest }),
  withFixedPopover,
  withHandlers({
    handleHideIssues: (props: Object) => (issues: Array) => {
      const { reportType, closeFixedPopup, resetListAndPagination, hideListIssuesByListNameRequest } = props;

      const callback = () => {
        closeFixedPopup();
        resetListAndPagination();
        G.callFunctionWithArgs(G.getPropFromObject(getListRequestActionName, props), true);
      };
      const elements = R.map(R.prop(GC.FIELD_GUID), issues);

      return hideListIssuesByListNameRequest({ callback, elements, listName: R.or(listName, reportType) });
    },
  }),
  withHandlers({
    handleShowListIssues: (props: Object) => (event: Object, issues: Object) => (
      props.openFixedPopup({
        version: 2,
        position: 'left',
        el: event.currentTarget,
        boxStyles: { zIndex: 10 },
        content: <IssueList issues={issues} handleHideIssues={props.handleHideIssues} />,
      })
    ),
  }),
  pure,
);
