import * as R from 'ramda';
import React from 'react';
import { connect } from 'react-redux';
import { compose, withHandlers } from 'react-recompose';
// components
import { openModal, closeModal } from '../components/modal/actions';
// helpers/constants
import * as G from '../helpers';
import * as GC from '../constants';
// ui
import { Box, Flex } from '../ui';
//////////////////////////////////////////////////

const RateAdjustmentHeader = () => (
  <Flex p={10} fontWeight='bold'>
    <Box width={200}>
      {G.getWindowLocale('titles:clo', 'CLO')}
    </Box>
    <Box width={140}>
      {G.getWindowLocale('titles:adjustments', 'Adjustments')}
    </Box>
    <Box width={130}>
      {G.getWindowLocale('titles:original-total', 'Original Total')}
    </Box>
    <Box width={130}>
      {G.getWindowLocale('titles:result-total', 'Result Total')}
    </Box>
  </Flex>
);

const RateAdjustment = (props: Object) => {
  const { value, index, rateUnit, cloPrimaryReferenceValue, showCustomerRateAdjustments } = props;

  const originalTotal = G.ifElse(
    G.isTrue(showCustomerRateAdjustments),
    R.path(['originalTotal'], props),
    R.path(['originalLineHaul'], props),
  );
  const resultTotal = G.ifElse(
    G.isTrue(showCustomerRateAdjustments),
    R.path(['resultTotal'], props),
    R.path(['resultLineHaul'], props),
  );
  const color = G.ifElse(
    G.isOdd(index),
    G.getTheme('colors.lightGrey'),
    G.getTheme('colors.veryLightGrey'),
  );

  return (
    <Flex p={10} bg={color} color={G.getTheme('colors.light.lightRed')}>
      <Box width={200}>
        {cloPrimaryReferenceValue}
      </Box>
      <Box width={140}>
        {`${value} ${rateUnit}`}
      </Box>
      <Box width={130}>
        {originalTotal}
      </Box>
      <Box width={130}>
        {G.mathRoundNumber(resultTotal)}
      </Box>
    </Flex>
  );
};

const RateAdjustments = (props: Object) => {
  const { charge, staticCharges, cloRateAdjustments } = props;

  const rateType = G.ifElse(
    G.isNotNilAndNotEmpty(charge),
    G.getRateTypeFromCharge(charge),
    R.path([GC.CHARGE_TYPE_MAIN, GC.FIELD_CHARGE_RATE_TYPE], staticCharges),
  );
  const showCustomerRateAdjustments = G.isChargeRateTypeCustomerRate(rateType);

  return (
    <Box>
      <RateAdjustmentHeader />
      {cloRateAdjustments.map((item: Object, index: number) => (
        <RateAdjustment
          key={index}
          {...item}
          index={index}
          showCustomerRateAdjustments={showCustomerRateAdjustments}
        />
      ))}
    </Box>
  );
};


export const withLinehaulAdjustments = compose(
  connect(
    null,
    { openModal, closeModal },
  ),
  withHandlers({
    handleOpenLinehaulAdjustments: (props: Object) => (charge: Object) => {
      const { openModal } = props;

      const modal = {
        p: '0px',
        component: <RateAdjustments {...props} charge={charge} />,
        options: {
          width: 630,
          height: 'auto',
          maxHeight: '95vh',
          outsideCloseButton: true,
          title: G.getWindowLocale('titles:adjustments', 'Adjustments'),
        },
      };
      openModal(modal);
    },
  }),
);

export default withLinehaulAdjustments;
