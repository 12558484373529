import * as R from 'ramda';
import { pure, compose, withState, lifecycle, withHandlers } from 'react-recompose';
// helpers/constants
import * as GC from '../constants';
import * as G from '../helpers';
// utilities
import { sendRequest } from '../utilities/http';
import endpointsMap from '../utilities/endpoints';
//////////////////////////////////////////////////

export const withAsyncGeoFencingZones = compose(
  withState('geoFencingZoneList', 'setGeoFencingZoneList', []),
  withState('indexedGeoFencingZoneList', 'setIndexedGeoFencingZoneList', []),
  withHandlers({
    handleGetGeoFencingZoneList: (props: Object) => async () => {
      const { branchGuid, setGeoFencingZoneList, setIndexedGeoFencingZoneList } = props;
      const options = {
        params: { [GC.BRANCH_GUID]: branchGuid },
      };
      const endpoint = endpointsMap.availableGeoFencingZone;
      const { data, status } = await sendRequest('get', endpoint, options);
      if (G.isResponseSuccess(status)) {
        const mappedOptions = R.map(({ name, guid }: Object) => ({
          [GC.FIELD_LABEL]: name,
          [GC.FIELD_VALUE]: guid,
        }), R.or(data, []));
        const indexedOptions = R.compose(
          R.map(({ name }: Object) => name),
          R.indexBy(R.prop(GC.FIELD_GUID)),
        )(R.or(data, []));
        setGeoFencingZoneList(mappedOptions);
        setIndexedGeoFencingZoneList(indexedOptions);
      } else {
        G.handleException('withAsyncGeoFencingZones exception');
      }
    },
  }),
  lifecycle({
    componentDidMount() {
      this.props.handleGetGeoFencingZoneList();
    },
  }),
  pure,
);
