import * as R from 'ramda';
import * as P from 'plow-js';
import React, { Component } from 'react';
// helpers/constants
import * as GC from '../constants';
import * as G from '../helpers';
// utilities
import { sendRequest } from '../utilities/http';
import endpointsMap from '../utilities/endpoints';
//////////////////////////////////////////////////

class SearchConfigs extends Component {
  constructor(props: Object) {
    super(props);
    this.state = { asyncConfigsByBranches: null };
    this.getConfigsByBranchesRequest = this.getConfigsByBranchesRequest.bind(this);
  }

  componentDidMount() {
    this.getConfigsByBranchesRequest(this.props);
  }

  async getConfigsByBranchesRequest(props: Object, guids: any) {
    const { names, branchGuids } = props;

    const enterpriseGuids = G.ifElse(G.isNotNilAndNotEmpty(guids), guids, branchGuids);

    if (G.isAnyNilOrEmpty([names, enterpriseGuids])) return;

    const options = {
      data: { enterpriseGuids, configNames: names },
    };

    const res = await sendRequest('post', endpointsMap.configsByBranches, options);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      if (G.isNotEmpty(data)) {
        const mapped = G.mapConfigsByBranchesAndNames(data);
        const newState = P.$set('asyncConfigsByBranches', mapped, this.state);

        this.setState(newState);

        return mapped;
      }
    } else {
      G.handleFailResponseSimple(
        res,
        false,
        'withAsyncConfigsByBranches -> getConfigsByBranchesRequest',
      );
    }
  }

  render() {
    return <div>{this.props.render(this.state, this.getConfigsByBranchesRequest)}</div>;
  }
}

export const withAsyncConfigsByBranches = (Component: any) => {
  return class extends React.Component {
    render() {
      return (
        <SearchConfigs
          branchGuids={this.props.branchGuids}
          names={this.props.configNamesArray}
          render={(parentState: Object, getConfigsByBranchesRequest: Function) =>
            <Component
              {...this.props}
              getConfigsByBranchesRequest={getConfigsByBranchesRequest}
              asyncConfigsByBranches={parentState.asyncConfigsByBranches} />
          } />
      );
    }
  };
};
