import * as R from 'ramda';
import { pure, compose, withState, lifecycle, withHandlers } from 'react-recompose';
// helpers/constants
import * as GC from '../constants';
import * as G from '../helpers';
// utilities
import { sendRequest } from '../utilities/http';
import endpointsMap from '../utilities/endpoints';
//////////////////////////////////////////////////

export const withAsyncSharedAccessorials = compose(
  withState('sharedAccessorials', 'setSharedAccessorials', []),
  withHandlers({
    handleGetSharedAccessorials: (props: Object) => async () => {
      const { branchGuid, setSharedAccessorials } = props;

      const options = {
        params: { [GC.BRANCH_GUID]: branchGuid },
      };
      const endpoint = endpointsMap.sharedAccessorialGuidNameMap;

      const { data, status } = await sendRequest('get', endpoint, options);

      if (G.isResponseSuccess(status)) {
        const options = R.map(({ guid, name }: Object) => (
          { [GC.FIELD_VALUE]: guid, [GC.FIELD_LABEL]: name }
        ), R.or(data, []));
        setSharedAccessorials(options);
      } else {
        G.handleException('withAsyncSharedAccessorials exception');
      }
    },
  }),
  lifecycle({
    componentDidMount() {
      this.props.handleGetSharedAccessorials();
    },
  }),
  pure,
);
