import * as R from 'ramda';
import { useState, useEffect } from 'react';
// helpers/constants
import * as G from '../helpers';
import * as GC from '../constants';
// utilities
import { sendRequest } from '../utilities/http';
import endpointsMap from '../utilities/endpoints';
//////////////////////////////////////////////////

const useAvailableDocumentTypes = (props: Object) => {
  const {
    callback,
    branchGuid,
    openLoader,
    closeLoader,
    shouldNotGet,
  } = props;

  const [availableDocumentTypes, setAvailableDocumentTypes] = useState([]);

  useEffect(async () => {
    if (shouldNotGet) return;

    G.callFunction(openLoader);

    const options = {
      params: {
        [GC.FIELD_BRANCH_GUID]: R.or(branchGuid, G.getAmousCurrentBranchGuidFromWindow()),
      },
    };

    const res = await sendRequest('get', endpointsMap.availableDocumentTypes, options);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      const mapped = R.map(({ displayedValue, ...item }: Object) => ({
        ...item,
        displayedValue,
        [GC.FIELD_LABEL]: displayedValue,
        [GC.FIELD_VALUE]: G.getParentGuidOrGuidFromObject(item),
      }), R.or(data, []));

      setAvailableDocumentTypes(mapped);

      G.callFunctionWithArgs(callback, mapped);
    } else {
      G.handleException(res, 'useAvailableDocumentTypes exception');
    }

    G.callFunction(closeLoader);
  }, []);

  return { availableDocumentTypes };
};

const useAsyncDocumentTemplates = (props: Object) => {
  const {
    callback,
    branchGuid,
    openLoader,
    closeLoader,
    shouldNotGet,
  } = props;

  const [asyncDocumentTemplates, setAsyncDocumentTemplates] = useState([]);

  useEffect(async () => {
    if (shouldNotGet) return;

    G.callFunction(openLoader);

    const options = {
      params: {
        [GC.FIELD_BRANCH_GUID]: R.or(branchGuid, G.getAmousCurrentBranchGuidFromWindow()),
      },
    };

    const res = await sendRequest('get', endpointsMap.availableDocumentTemplates, options);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      const grouped = R.groupBy(R.prop(GC.FIELD_PRINTABLE_SECTION), R.or(data, []));

      setAsyncDocumentTemplates(grouped);

      G.callFunctionWithArgs(callback, grouped);
    } else {
      G.handleException(res, 'useAvailableDocumentTypes exception');
    }

    G.callFunction(closeLoader);
  }, []);

  return { asyncDocumentTemplates };
};

export {
  useAvailableDocumentTypes,
  useAsyncDocumentTemplates,
};

