import * as R from 'ramda';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose, withState, withHandlers, lifecycle } from 'react-recompose';
// features
import PC from '../features/permission/role-permission';
import { makeSelectAuthorities } from '../features/permission/selectors';
// helpers/constants
import * as G from '../helpers';
import * as GC from '../constants';
// utilities
import { sendRequest } from '../utilities/http';
import endpointsMap from '../utilities/endpoints';
//////////////////////////////////////////////////

const mapStateToProps = (state: Object) => createStructuredSelector({
  authorities: makeSelectAuthorities(state),
});

export const withAsyncCLODocuments = compose(
  connect(mapStateToProps),
  withState('documentsOptions', 'setDocumentsOptions', []),
  withHandlers({
    handleGetCLODocuments: (props: Object) => async () => {
      const {
        authorities,
        primaryObjectGuid,
        setDocumentsOptions,
      } = props;

      const permissions = [
        PC.CLO_DOCUMENT_READ,
        PC.CLO_DOCUMENT_WRITE,
      ];

      const permission = G.checkStringsContains(permissions, authorities);

      if (R.not(permission)) return;

      const options = {
        params: { [GC.BRANCH_GUID]: R.prop(GC.BRANCH_GUID, props), [GC.FIELD_PRIMARY_OBJECT_GUID]: primaryObjectGuid },
      };

      const res = await sendRequest('get', endpointsMap.cloDocumentList, options);

      const { data, status } = res;

      if (G.isResponseSuccess(status)) {
        const documentsOptions = G.getPrintDocumentOptions(data);

        setDocumentsOptions(documentsOptions);
      } else {
        G.handleFailResponseSimple(res);
      }
    },
  }),
  lifecycle({
    componentDidMount() {
      this.props.handleGetCLODocuments();
    },
  }),
);

export const withAsyncGetVendorDocumentsByCloGuid = compose(
  withState('vendorDocuments', 'setVendorDocuments', []),
  withHandlers({
    handleGetVendorDocumentsByCloGuid: ({ cloGuid, setFieldValue, setVendorDocuments }: Object) => async () => {
      const options = { params: { cloGuid } };

      const res = await sendRequest('get', endpointsMap.cloAttachableVendorDocuments, options);

      const { data, status } = res;

      if (G.isResponseSuccess(status)) {
        if (G.isNotNilAndNotEmpty(data)) {
          const documentsOptions = R.map(({ vendorName, documentGuid, documentName }: Object) => ({
            [GC.FIELD_VALUE]: documentGuid,
            [GC.FIELD_LABEL]: G.ifElse(G.isNilOrEmpty(documentName), vendorName, `${documentName}(${vendorName})`),
          }), data);

          setVendorDocuments(documentsOptions);

          setFieldValue(GC.FIELD_VENDOR_DOCUMENT_GUIDS, R.map(R.prop(GC.FIELD_DOCUMENT_DOCUMENT_GUID), data));
        }
      } else {
        G.handleException('withAsyncGetVendorDocumentsByCloGuid');
      }
    },
  }),
  lifecycle({
    componentDidMount() {
      const { useVendorDocuments, handleGetVendorDocumentsByCloGuid } = this.props;

      if (G.isTrue(useVendorDocuments)) handleGetVendorDocumentsByCloGuid();
    },
  }),
);

export const withAsyncTelDocuments = compose(
  withState('asyncTelDocuments', 'setAsyncTelDocuments', []),
  withState('asyncTelDocumentsOptions', 'setAsyncTelDocumentsOptions', []),
  withHandlers({
    asyncGetDocumentList: (props: Object) => async () => {
      const { tel, telGuid, openLoader, closeLoader, setAsyncTelDocuments, setAsyncTelDocumentsOptions } = props;

      const branchGuid = R.pathOr(G.getAmousCurrentBranchGuidFromWindow(), ['branchGuid'], props);

      const telGuidToUse = R.pathOr(telGuid, [GC.FIELD_GUID], tel);

      if (R.or(G.isNilOrEmpty(telGuidToUse), G.isNilOrEmpty(branchGuid))) return;

      openLoader();

      const options = {
        params: {
          [GC.BRANCH_GUID]: branchGuid,
          [GC.FIELD_PRIMARY_OBJECT_GUID]: telGuidToUse,
        },
      };

      const res = await sendRequest('get', endpointsMap.telDocumentList, options);

      const { data, status } = res;

      if (G.isResponseSuccess(status)) {
        setAsyncTelDocuments(data);
        setAsyncTelDocumentsOptions(G.getPrintDocumentOptions(data));
      } else {
        G.handleFailResponseSimple(res);
      }

      closeLoader();
    },
  }),
  lifecycle({
    componentDidMount() {
      const { asyncGetDocumentList } = this.props;

      asyncGetDocumentList();
    },
  }),
);
