import * as R from 'ramda';
import trim from 'voca/trim';
import { pure, compose, withHandlers } from 'react-recompose';
// helpers/constants
import * as G from '../helpers';
//////////////////////////////////////////////////

export const withGoogleAutocomplete = (options: Object) => compose(
  withHandlers({
    onChangeSearchField: (props: Object) => (
      value: string,
      fieldName: string,
      formSection: string,
    ) =>
    G.changeStoreField(props.change, formSection, trim(fieldName), value, true),
    onSelectSearchValue: (props: Object) => async (
      value: string,
      fieldName: string,
      formSection: string,
    ) => {
      const { change, openLoader, closeLoader, afterSearchCallback } = props;

      const { omitFieldsMap, renameResultsMap, useFormattedAddress } = options;

      if (G.isFunction(openLoader)) openLoader({ showDimmer: true});

      const result = await G.geocodeByPlaceAddress(
        value,
        'hocs -> withGoogleAutocomplete',
        () => closeLoader(),
        { useFormattedAddress },
      );

      const fieldsToPick = R.reduce(
        (acc: Array, item: string) => {
          const omitKeys = R.keys(omitFieldsMap);

          if (G.isNilOrEmpty(omitKeys)) return acc;

          if (R.and(R.equals(item, fieldName), R.includes(item, omitKeys))) {
            return R.omit(R.prop(item, omitFieldsMap), acc);
          }

          return acc;
        },

        result,

        R.keys(result),
      );

      R.compose(
        R.forEachObjIndexed((value: string, key: string) => G.changeStoreField(
          change,
          formSection,
          key,
          value,
          true,
        )),
        G.renameKeys(R.or(renameResultsMap, {})),
      )(fieldsToPick);

      G.callFunction(afterSearchCallback);
      G.callFunction(closeLoader);
    },
  }),
);

export const enhanceLocationWithGoogleAutocomplete = compose(
  withGoogleAutocomplete({
    useFormattedAddress: false,
    omitFieldsMap: { zip: ['address'], city: ['address'] },
  }),
  pure,
);
