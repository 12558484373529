import * as R from 'ramda';
// constants
import * as GC from '../constants';
// helpers
import { getWindowAmousLoadDivision } from './window';
import { isLoadTypeClo, isLoadTypeTel } from './events-load';
import { isNilOrEmpty, isNotNilAndNotEmpty } from './helpers';
//////////////////////////////////////////////////

const getLocationTemplateSearchRequestProps = (props: Object) => {
  const { loadType, loadGuid, branchGuid, divisionGuid, eventCloGuid } = props;

  if (isNilOrEmpty(loadType)) return { branchGuid };

  if (isLoadTypeClo(loadType)) {
    const branchGuidToUse = R.or(divisionGuid, getWindowAmousLoadDivision(loadGuid));

    return {
      customerGuid: branchGuid,
      branchGuid: R.or(branchGuidToUse, branchGuid),
    };
  }

  if (R.and(isNotNilAndNotEmpty(eventCloGuid), isLoadTypeTel(loadType))) {
    const branchGuidToUse = getWindowAmousLoadDivision(eventCloGuid);

    return {
      customerGuid: branchGuid,
      branchGuid: R.or(branchGuidToUse, branchGuid),
    };
  }

  return { branchGuid };
};

const getAsyncSearchTemplateAutocompleteRequestProps = (params: Object) => {
  const { loadType, loadGuid, branchGuid, divisionGuid, eventCloGuid } = params;

  if (isNilOrEmpty(loadType)) return { [GC.FIELD_BRANCH_GUID]: branchGuid };

  if (isLoadTypeClo(loadType)) {
    const branchGuidToUse = R.or(divisionGuid, getWindowAmousLoadDivision(loadGuid));

    return {
      customerGuid: branchGuid,
      [GC.FIELD_BRANCH_GUID]: R.or(branchGuidToUse, branchGuid),
    };
  }
  if (R.and(isNotNilAndNotEmpty(eventCloGuid), isLoadTypeTel(loadType))) {
    const branchGuidToUse = getWindowAmousLoadDivision(eventCloGuid);

    return {
      customerGuid: branchGuid,
      branchGuid: R.or(branchGuidToUse, branchGuid),
    };
  }

  return { [GC.FIELD_BRANCH_GUID]: branchGuid };
};

export {
  getLocationTemplateSearchRequestProps,
  getAsyncSearchTemplateAutocompleteRequestProps,
};
