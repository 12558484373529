import * as R from 'ramda';
import { pure, compose, withState, withHandlers } from 'react-recompose';
// helpers/constants
import * as G from '../helpers';
import * as GC from '../constants';
// utilities
import { sendRequest } from '../utilities/http';
import endpointsMap from '../utilities/endpoints';
//////////////////////////////////////////////////

const CONTACT_FORM_SECTION = 'CONTACT_FORM_SECTION';

const getBranchProps = (mainProps: Object, props: Object) => {
  if (G.isTrue(R.path(['shouldGetLocationTemplateSearchRequestProps'], mainProps))) {
    return G.getAsyncSearchTemplateAutocompleteRequestProps(props);
  }
  const { branchGuid } = props;
  const branchGuidToUse = G.ifElse(
    G.isTrue(R.path(['useCurrentBranch'], mainProps)),
    G.getAmousCurrentBranchGuidFromWindow(),
    branchGuid,
  );

  return {
    [GC.FIELD_BRANCH_GUID]: branchGuidToUse,
  };
};

export const withAsyncSearchTemplateAutocomplete = (mainProps: Object) => compose(
  withState('templateItems', 'setTemplateItems', null),
  withState('templateIdOptions', 'setTemplateIdOptions', []),
  withHandlers({
    handleGetLoadOptions: (props: Object) => async (inputValue: string, callback: Object) => {
      const { setTemplateItems, setTemplateIdOptions } = props;

      if (G.isNotNilAndNotEmpty(inputValue)) {
        const data = {
          ...getBranchProps(mainProps, props),
          groupCriteriaList: [{
            value: inputValue,
            propertyNames: [
              GC.FIELD_TEMPLATE_ID,
              GC.FIELD_LOCATION_NAME,
            ],
          }],
        };
        const result = await sendRequest('post', endpointsMap.listLocations, { data });
        if (G.isResponseSuccess(result.status)) {
          setTemplateItems(R.pathOr([], ['data'], result));
          const options = R.map(({ zip, city, state, guid, address1, templateId, locationName }: string) => (
            {
              templateId,
              value: guid,
              label: `${templateId}, ${locationName} (${R.trim(address1)}, ${city}, ${state}, ${zip})`,
            }
          ), R.pathOr([], ['data'], result));
          setTemplateIdOptions(options);
          callback(options);
        } else {
          G.handleFailResponseSimple(result);
        }
      } else {
        callback([]);
      }
    },
    // NOTE: for redux-form
    handleAsyncCreatableSearchChange: (props: Object) => (field: Object) => {
      const { form, change, withFormName, withContactsAutocomplete, handleCalculateDistances } = props;
      G.changeStoreField(change, form, GC.FIELD_TEMPLATE_ID, field, withFormName);
      const searchedTemplateId = R.filter((item: Object) => (
        R.equals(R.prop(GC.FIELD_GUID, item), R.propOr('', 'value', field))
      ), R.pathOr([], ['templateItems'], props));
      if (R.and(G.isNilOrEmpty(searchedTemplateId), G.isNotNilAndNotEmpty(field))) return;
      R.forEachObjIndexed((templateItem: Object, templateKey: string) => {
        if (R.and(withContactsAutocomplete, R.equals(templateKey, GC.FIELD_CONTACTS))) {
          R.forEachObjIndexed((contactItem: string, contactKey: string) =>
            G.changeStoreField(change, CONTACT_FORM_SECTION, contactKey, contactItem, withFormName),
          R.pathOr(GC.contactDefaultFields, ['0'], templateItem));
        }
        if (R.equals(templateKey, GC.FIELD_LOCATION_TYPE)) {
          const templateType = {
            label: R.propOr(null, GC.FIELD_DISPLAYED_VALUE, templateItem),
            value: R.propOr(null, GC.FIELD_DROPDOWN_OPTION_GUID, templateItem),
          };
          G.changeStoreField(change, form, templateKey, templateType, withFormName);
        }
        G.changeStoreField(change, form, templateKey, templateItem, withFormName);
      }, R.pick(R.keys(GC.locationDefaultFields), R.pathOr([], ['0'], searchedTemplateId)));
      if (G.isFunction(handleCalculateDistances)) {
        handleCalculateDistances();
      }
    },
    // NOTE: for formik
    handleAsyncSearchSelectChange: (props: Object) => (field: Object) => {
      const { values, setValues, templateItems } = props;

      if (G.isNilOrEmpty(field)) return;

      const newValues = R.compose(
        R.mergeRight(values),
        G.mapSearchedLocationWithContactsArr,
        R.assoc(GC.FIELD_TEMPLATE_ID, R.prop(GC.FIELD_TEMPLATE_ID, field)),
        R.find(R.propEq(field.value, GC.FIELD_GUID)),
      )(R.or(templateItems, []));

      const contacts = R.prop(GC.FIELD_CONTACTS, newValues);

      if (G.isNotNilAndNotEmpty(contacts)) {
        const firstContact = R.head(contacts);
        const email = R.prop(GC.FIELD_EMAIL, firstContact);

        const emails = G.ifElse(
          G.isNotNilAndNotEmpty(email),
          R.of([], email),
          [],
        );

        const updatedContact = R.compose(
          R.assoc(GC.FIELD_EMAILS, emails),
          R.omit([GC.FIELD_EMAIL]),
        )(firstContact);

        return setValues(R.mergeRight(newValues, updatedContact));
      }

      return setValues(newValues);
    },
  }),
  pure,
);
