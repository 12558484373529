import * as R from 'ramda';
import { compose, withState, withHandlers } from 'react-recompose';
// helpers/constants
import * as G from '../helpers';
//////////////////////////////////////////////////

export const withComponentHeight = compose(
  withState('componentHeight', 'setHeight', null),
  withHandlers({
    handleSetHeight: (props: Object) => (ref: Object) => {
      if (R.and(
        G.notEquals(props.componentHeight, R.path(['clientHeight'], ref)),
        G.isNotNilAndNotEmpty(R.path(['clientHeight'], ref)),
      )) {
        props.setHeight(ref.clientHeight);
      }
    },
  }),
);
