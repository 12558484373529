import * as R from 'ramda';
import * as P from 'plow-js';
import React, { Component } from 'react';
// helpers/constants
import * as GC from '../constants';
import * as G from '../helpers';
// utilities
import { sendRequest } from '../utilities/http';
import endpointsMap from '../utilities/endpoints';
//////////////////////////////////////////////////

export class SearchAccessorials extends Component {
  constructor(props: Object) {
    super(props);
    this.state = { accessorialsConfigs: null };
    this.getAccessorialsRequest = this.getAccessorialsRequest.bind(this);
  }

  componentDidMount() {
    this.getAccessorialsRequest(this.props);
  }

  async getAccessorialsRequest(props: Object) {
    const branchGuid = R.prop('branchGuid', props);
    if (G.isNilOrEmpty(branchGuid)) return;
    const params = { [GC.FIELD_BRANCH_GUID]: branchGuid };
    const res = await sendRequest('get', endpointsMap.accessorialsListEndpoint, { params });
    const { data, status } = res;
    if (R.and(G.isResponseSuccess(status), G.isNotEmpty(data))) {
      const newState = P.$set('accessorialsConfigs', data, this.state);
      this.setState(newState);
    } else {
      G.handleFailResponseSimple(
        res,
        false,
        'withAsyncStatusMessages -> getAccessorialsRequest',
      );
    }
  }

  render() {
    return <div>{this.props.render(this.state, this.getConfigsRequest)}</div>;
  }
}

export const withAsyncAccessorials = (Component: any) => {
  return class extends React.Component {
    render() {
      return (
        <SearchAccessorials
          branchGuid={this.props.branchGuid}
          render={(parentState: Object, getAccessorialsRequest: Function) =>
            <Component
              {...this.props}
              getAccessorialsRequest={getAccessorialsRequest}
              accessorialsConfigs={parentState.accessorialsConfigs} />
          } />
      );
    }
  };
};
