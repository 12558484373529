import * as R from 'ramda';
// constants
import * as GC from '../constants';
// helpers
import {
  isObject,
  isString,
  isNilOrEmpty,
} from './helpers';
import { getPropFromObject } from './getter';
import { createStringFromArray } from './string';
//////////////////////////////////////////////////

const isItemTypeVehicle = (item: any) => {
  if (isNilOrEmpty(item)) return false;

  if (isString(item)) return R.equals(GC.ITEM_TYPE_VEHICLE, item);

  if (isObject(item)) return R.equals(GC.ITEM_TYPE_VEHICLE, getPropFromObject(GC.FIELD_ITEM_TYPE, item));

  return false;
};

const createSequenceApproxValue = (sequence: Object) => {
  if (isNilOrEmpty(sequence)) return '';

  const arrayOfValues = [sequence.prefix, sequence.approxValue, sequence.suffix];

  return createStringFromArray(arrayOfValues, '');
};

const isChatMessageAuthorTypeCarrier = (authorType: string) =>
  R.equals(authorType, 'CARRIER');

const isChatMessageAuthorTypeDriver = (authorType: string) =>
  R.equals(authorType, 'DRIVER');

const isChatMessageAuthorTypeCarrierOrDriver = (authorType: string = '') =>
  R.or(isChatMessageAuthorTypeCarrier(authorType), isChatMessageAuthorTypeDriver(authorType));

export {
  isItemTypeVehicle,
  createSequenceApproxValue,
  isChatMessageAuthorTypeDriver,
  isChatMessageAuthorTypeCarrier,
  isChatMessageAuthorTypeCarrierOrDriver,
};
