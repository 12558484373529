import * as R from 'ramda';
import React from 'react';
import { withHandlers } from 'react-recompose';
// forms
import { SelectDropdownForm } from '../forms/forms/select-dropdown-form';
// helpers
import * as G from '../helpers';
//////////////////////////////////////////////////

export const withMassActionSubmit = withHandlers({
  handleMassActionSubmit: ({ openModal, closeModal }: Object) => ({ noSelected, formValues, submitAction }: Object) => {
    const currentEnterprise = G.getAmousCurrentBranchGuidFromWindow();

    let data = { ...R.or(formValues, {}), currentEnterprise };

    if (G.isTrue(noSelected)) return submitAction(R.dissoc('guids', data));

    const options = [
      { value: 'BY_REPORT', label: G.getWindowLocale('titles:by-report', 'By Report') },
      { value: 'SELECTED', label: G.getWindowLocale('titles:selected', 'Selected') },
      { value: 'EXCLUDE_SELECTED', label: G.getWindowLocale('titles:exclude-selected', 'Exclude Selected') },
    ];

    const component = (
      <SelectDropdownForm
        options={options}
        cancelAction={closeModal}
        fieldLabel={G.getWindowLocale('titles:select-submit-option', 'Select Submit Option')}
        submitAction={(value: string) => {
          if (R.equals(value, 'BY_REPORT')) {
            data = R.dissoc('guids', data);
          } else if (R.equals(value, 'EXCLUDE_SELECTED')) {
            data = R.compose(
              R.dissoc('guids'),
              R.assoc('excludedGuids', R.prop('guids', data)),
            )(data);
          }

          submitAction(data);
        }}
      />
    );

    const modal = {
      p: 15,
      component,
      options: {},
    };

    openModal(modal);
  },
});
