import * as R from 'ramda';
import * as P from 'plow-js';
import React, { Component } from 'react';
// helpers/constants
import * as G from '../helpers';
import * as GC from '../constants';
// utilities
import { sendRequest } from '../utilities/http';
import endpointsMap from '../utilities/endpoints';
//////////////////////////////////////////////////

export class SearchConfigs extends Component {
  constructor(props: Object) {
    super(props);
    this.state = { asyncConfigs: null, asyncConfigsLoading: false };
    this.getConfigsRequest = this.getConfigsRequest.bind(this);
  }

  componentDidMount() {
    this.getConfigsRequest(this.props);
  }

  async getConfigsRequest(props: Object) {
    const names = R.prop('names', props);
    const branchGuid = R.pathOr(G.getAmousCurrentBranchGuidFromWindow(), ['branchGuid'], props);

    if (R.or(G.isNilOrEmpty(branchGuid), G.isNilOrEmpty(names))) return;

    this.setState(P.$set('asyncConfigsLoading', true, this.state));

    const params = { names: R.join(',', [names]), [GC.FIELD_BRANCH_GUID]: branchGuid };

    const res = await sendRequest('get', endpointsMap.branchConfigsEndpoint, { params });

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      if (G.isNotEmpty(data)) {
        const mapped = G.mapConfigValuesByName(data);
        const newState = P.$all(
          P.$set('asyncConfigs', mapped),
          P.$set('asyncConfigsLoading', false),
          this.state,
        );
        this.setState(newState);
      }
    } else {
      this.setState(P.$set('asyncConfigsLoading', false, this.state));
      G.handleFailResponseSimple(
        res,
        false,
        'withAsyncConfigs -> getConfigsRequest',
      );
    }
  }

  render() {
    return <div>{this.props.render(this.state, this.getConfigsRequest)}</div>;
  }
}

export const withAsyncConfigs = (Component: any) => class extends React.Component {
  render() {
    return (
      <SearchConfigs
        branchGuid={this.props.branchGuid}
        names={this.props.configsNamesArray}
        render={(parentState: Object, getConfigsRequest: Function) =>
          <Component
            {...this.props}
            getConfigsRequest={getConfigsRequest}
            asyncConfigs={parentState.asyncConfigs}
            asyncConfigsLoading={parentState.asyncConfigsLoading}
          />
          }
      />
    );
  }
  };

const getConfigsPropObject = (configsPropName: string, asyncConfigs: any) => G.ifElse(
  G.isNotNilAndNotEmpty(configsPropName),
  { [configsPropName]: asyncConfigs },
  { asyncConfigs },
);

export const withAsyncConfigsNamed = ({ configsPropName }: Object) => (Component: any) => class extends React.Component {
  render() {
    return (
      <SearchConfigs
        branchGuid={this.props.branchGuid}
        names={this.props.configsNamesArray}
        render={(parentState: Object, getConfigsRequest: Function) =>
          <Component
            {...this.props}
            getConfigsRequest={getConfigsRequest}
            {...getConfigsPropObject(configsPropName, parentState.asyncConfigs)}
          />
          }
      />
    );
  }
  };
