import * as R from 'ramda';
import React, { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
// components
import { EditReport } from '../components/edit-report';
import { openModal } from '../components/modal/actions';
// helpers/constants
import * as GC from '../constants';
// report-common
import { getReportSelectors } from '../report-common';
//////////////////////////////////////////////////

const useReport = ({ A, type }: Object) => {
  const dispatch = useDispatch();

  const {
    makeSelectUsedReport,
    makeSelectPagination,
    makeSelectTotalCount,
    makeSelectListLoading,
    makeSelectPageVisited,
    makeSelectReportStatus,
    makeSelectFilterParams,
    makeSelectTitleSortValues,
    makeSelectAvailableReports,
    makeSelectTableTitleFilters,
  } = getReportSelectors(type);

  const loading = useSelector(makeSelectListLoading());
  const totalCount = useSelector(makeSelectTotalCount());
  const pagination = useSelector(makeSelectPagination());
  const pageVisited = useSelector(makeSelectPageVisited());
  const selectedReport = useSelector(makeSelectUsedReport());
  const filterParams = useSelector(makeSelectFilterParams());
  const requestPending = useSelector(makeSelectReportStatus());
  const reportList = useSelector(makeSelectAvailableReports());
  const titleSortValues = useSelector(makeSelectTitleSortValues());
  const tableTitleFilters = useSelector(makeSelectTableTitleFilters());

  const setReports = useCallback((props: Object) => dispatch(A.setReports(props)), []);
  const setUsedReport = useCallback((props: Object) => dispatch(A.setUsedReport(props)), []);
  const cleanQuickFilter = useCallback((props: Object) => dispatch(A.cleanQuickFilter(props)), []);
  const getItemListRequest = useCallback((props: Object) => dispatch(A.getItemListRequest(props)), []);
  const createReportRequest = useCallback((props: Object) => dispatch(A.createReportRequest(props)), []);
  const updateReportRequest = useCallback((props: Object) => dispatch(A.updateReportRequest(props)), []);
  const setQuickFilterParams = useCallback((props: Object) => dispatch(A.setQuickFilterParams(props)), []);
  const resetListAndPagination = useCallback((props: Object) => dispatch(A.resetListAndPagination(props)), []);
  const changeDefaultReportRequest = useCallback((props: Object) => dispatch(A.changeDefaultReportRequest(props)), []);

  const handleSelectReport = useCallback((reportGuid: string) => {
    const selectedReport = R.find(R.propEq(reportGuid, GC.FIELD_GUID), reportList);

    setUsedReport(selectedReport);
    getItemListRequest();
  }, [reportList, setUsedReport, getItemListRequest]);

  const handleEditReport = useCallback((fields: Array) => {
    const modalContent = (
      <EditReport
        fields={fields}
        setReport={setUsedReport}
        usedReport={selectedReport}
        requestPending={requestPending}
        onReportSet={getItemListRequest}
        createReportRequest={createReportRequest}
        updateReportRequest={updateReportRequest}
      />
    );

    const modal = {
      component: modalContent,
      options: {
        version: 2,
        height: 'auto',
        maxWidth: '98vw',
        width: 'fit-content',
      },
    };

    dispatch(openModal(modal));
  }, [
    selectedReport,
    requestPending,
    setUsedReport,
    getItemListRequest,
    createReportRequest,
    updateReportRequest,
  ]);

  return {
    // data
    loading,
    totalCount,
    pagination,
    reportList,
    pageVisited,
    filterParams,
    selectedReport,
    requestPending,
    titleSortValues,
    tableTitleFilters,
    // actions
    setReports,
    setUsedReport,
    cleanQuickFilter,
    handleEditReport,
    handleSelectReport,
    getItemListRequest,
    createReportRequest,
    updateReportRequest,
    setQuickFilterParams,
    resetListAndPagination,
    changeDefaultReportRequest,
  };
};

export default useReport;
