import * as R from 'ramda';
import React from 'react';
import { compose, withHandlers } from 'react-recompose';
// features
import { AsyncEditServiceVendorInvoiceForm } from '../features/invoice/components/service-vendor-form';
// helpers/constants
import * as G from '../helpers';
import * as GC from '../constants';
// utilities
import endpointsMap from '../utilities/endpoints';
//////////////////////////////////////////////////

export const withAsyncEditServiceVendorInvoice = (options: any) => compose(
  withHandlers({
    handleUpdateServiceVendorInvoice: (props: Object) => (values: Object) => {
      const { updateReconciliationRequest, updateServiceVendorInvoiceRequest } = props;

      const updateFrom = G.getPropFromObject('updateFrom', options);

      if (R.and(
        R.equals(updateFrom, GC.PAGE_DISPATCH_DETAILS_NEW_LOAD),
        G.isFunction(updateServiceVendorInvoiceRequest),
      )) {
        return updateServiceVendorInvoiceRequest(values);
      }

      updateReconciliationRequest(values);
    },
  }),
  withHandlers({
    handleEditServiceVendorInvoice: (props: Object) => (invoice: Object) => {
      const { load, openModal, closeModal, handleUpdateServiceVendorInvoice } = props;

      const guid = G.getGuidFromObject(invoice);
      const updateFrom = G.getPropFromObject('updateFrom', options);
      const serviceVendorGuid = G.getPropFromObject(GC.FIELD_SERVICE_VENDOR_GUID, invoice);

      const totalCustomersRate = G.getTotalCustomersRate(props, updateFrom);
      const stopsQuantity = R.length(R.pathOr([], [GC.FIELD_LOAD_STOPS], load));

      const branchGuid = R.or(
        R.pathOr(G.getBranchGuidFromObject(invoice), [GC.FIELD_BRANCH_GUID], props),
        G.getBranchGuidFromObject(load),
      );

      const totalWeight = R.pick(
        [GC.FIELD_TOTAL_TRIP_WEIGHT, GC.FIELD_TOTAL_TRIP_WEIGHT_UOM],
        invoice,
      );

      const totalDistance = R.pick(
        [GC.FIELD_TOTAL_TRIP_DISTANCE, GC.FIELD_TOTAL_TRIP_DISTANCE_UOM],
        invoice,
      );

      const modalContent = (
        <AsyncEditServiceVendorInvoiceForm
          tel={load}
          initialValues={invoice}
          closeModal={closeModal}
          branchGuid={branchGuid}
          totalWeight={totalWeight}
          totalDistance={totalDistance}
          stopsQuantity={stopsQuantity}
          serviceVendorGuid={serviceVendorGuid}
          totalCustomersRate={totalCustomersRate}
          handleSendFleetInvoice={handleUpdateServiceVendorInvoice}
          asyncEndpoint={endpointsMap.getServiceVendorInvoice(guid)}
        />
      );

      const modal = {
        p: '0',
        component: modalContent,
        options: {
          width: 1100,
          height: 'auto',
          overflow: 'auto',
          maxHeight: '90vh',
        },
      };

      openModal(modal);
    },
  }),
);
