import * as R from 'ramda';
import * as P from 'plow-js';
import React, { Component } from 'react';
// helpers/constants
import * as GC from '../constants';
import * as G from '../helpers';
// utilities
import { sendRequest } from '../utilities/http';
import endpointsMap from '../utilities/endpoints';
//////////////////////////////////////////////////

export class InvoiceStatusConfigs extends Component {
  constructor(props: Object) {
    super(props);
    this.state = {
      CIStatusConfig: null,
      DPStatusConfig: null,
      CarrierIStatusConfig: null,
      FleetServiceIStatusConfig: null,
    };
    this.getCIStatusConfigRequest = this.getCIStatusConfigRequest.bind(this);
    this.getDPStatusConfigRequest = this.getDPStatusConfigRequest.bind(this);
    this.getCarrierIStatusConfigRequest = this.getCarrierIStatusConfigRequest.bind(this);
    this.getFleetServiceIStatusConfigRequest = this.getFleetServiceIStatusConfigRequest.bind(this);
  }

  componentDidMount() {
    if (R.propEq('customer', 'type', this.props)) {
      return this.getCIStatusConfigRequest(this.props);
    }

    if (R.propEq('driver', 'type', this.props)) {
      return this.getDPStatusConfigRequest(this.props);
    }

    if (R.propEq('carrier', 'type', this.props)) {
      return this.getCarrierIStatusConfigRequest(this.props);
    }

    if (R.propEq('fleetService', 'type', this.props)) {
      return this.getFleetServiceIStatusConfigRequest(this.props);
    }

    this.getCIStatusConfigRequest(this.props);
    this.getDPStatusConfigRequest(this.props);
    this.getCarrierIStatusConfigRequest(this.props);
  }

  async getCIStatusConfigRequest(props: Object) {
    const { branchGuid } = props;
    if (G.isNilOrEmpty(branchGuid)) return;
    const params = { [GC.FIELD_BRANCH_GUID]: branchGuid };
    const res = await sendRequest('get', endpointsMap.customerInvoiceStatuses, { params });
    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      if (G.isNotEmpty(data)) {
        const newState = P.$set('CIStatusConfig', data, this.state);
        this.setState(newState);
      }
    } else {
      G.handleFailResponseSimple(
        res,
        false,
        'withAsyncInvoiceStatusConfigs -> getCIStatusConfigRequest',
      );
    }
  }

  async getDPStatusConfigRequest(props: Object) {
    const { branchGuid } = props;
    if (G.isNilOrEmpty(branchGuid)) return;
    const params = { [GC.FIELD_BRANCH_GUID]: branchGuid };
    const res = await sendRequest('get', endpointsMap.driverPayrollStatuses, { params });
    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      if (G.isNotEmpty(data)) {
        const newState = P.$set('DPStatusConfig', data, this.state);
        this.setState(newState);
      }
    } else {
      G.handleFailResponseSimple(
        res,
        false,
        'withAsyncInvoiceStatusConfigs -> getDPStatusConfigRequest',
      );
    }
  }

  async getCarrierIStatusConfigRequest(props: Object) {
    const { branchGuid } = props;
    if (G.isNilOrEmpty(branchGuid)) return;
    const params = { [GC.FIELD_BRANCH_GUID]: branchGuid };
    const res = await sendRequest('get', endpointsMap.carrierInvoiceStatuses, { params });
    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      if (G.isNotEmpty(data)) {
        const newState = P.$set('CarrierIStatusConfig', data, this.state);
        this.setState(newState);
      }
    } else {
      G.handleFailResponseSimple(
        res,
        false,
        'withAsyncInvoiceStatusConfigs -> getCarrierIStatusConfigRequest',
      );
    }
  }

  async getFleetServiceIStatusConfigRequest(props: Object) {
    const { branchGuid } = props;

    if (G.isNilOrEmpty(branchGuid)) return;

    const params = { [GC.FIELD_BRANCH_GUID]: branchGuid };

    const res = await sendRequest('get', endpointsMap.fleetServiceInvoiceStatuses, { params });

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      if (G.isNotEmpty(data)) {
        const newState = P.$set('FleetServiceIStatusConfig', data, this.state);

        this.setState(newState);
      }
    } else {
      G.handleFailResponseSimple(
        res,
        false,
        'getFleetServiceIStatusConfigRequest -> getFleetServiceIStatusConfigRequest',
      );
    }
  }

  render() {
    return (
      <div>
        {this.props.render(
          this.state,
          this.getCIStatusConfigRequest,
          this.getDPStatusConfigRequest,
          this.getCarrierIStatusConfigRequest,
          this.getFleetServiceIStatusConfigRequest,
        )}
      </div>
    );
  }
}

export const withAsyncInvoiceStatusConfigs = (Component: any) => {
  return class extends React.Component {
    render() {
      return (
        <InvoiceStatusConfigs
          type={this.props.type}
          branchGuid={this.props.branchGuid}
          render={(
            parentState: Object,
            getCIStatusConfigRequest: Function,
            getDPStatusConfigRequest: Function,
            getCarrierIStatusConfigRequest: Function,
          ) => (
            <Component
              {...this.props}
              CIStatusConfig={parentState.CIStatusConfig}
              DPStatusConfig={parentState.DPStatusConfig}
              getCIStatusConfigRequest={getCIStatusConfigRequest}
              getDPStatusConfigRequest={getDPStatusConfigRequest}
              CarrierIStatusConfig={parentState.CarrierIStatusConfig}
              getCarrierIStatusConfigRequest={getCarrierIStatusConfigRequest}
              FleetServiceIStatusConfig={parentState.FleetServiceIStatusConfig}
              getFleetServiceIStatusConfigRequest={parentState.getFleetServiceIStatusConfigRequest}
            />
          )}
        />
      );
    }
  };
};
