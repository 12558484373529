import * as R from 'ramda';
// constants
import * as GC from '../constants';
//////////////////////////////////////////////////

const getGuidFromObject = (item: Object = {}) => R.path([GC.FIELD_GUID], item);

const getPrimaryReferenceValueFromObject = (item: Object = {}) => R.path(
  [GC.FIELD_PRIMARY_REFERENCE_VALUE],
  item,
);

const getIdFromObject = (item: Object = {}) => R.path([GC.FIELD_ID], item);

const getLocationFromObject = (item: Object = {}) => R.path([GC.FIELD_LOCATION], item);

const getIdOrGuidFromObject = (item: Object = {}) => R.pathOr(
  R.path([GC.FIELD_GUID], item),
  [GC.FIELD_ID],
  item,
);

const getDataFromObject = (item: Object = {}) => R.path(['data'], item);

const getDisplayedValueFromObject = (item: Object = {}) => R.path([GC.FIELD_DISPLAYED_VALUE], item);

const getDropdownOptionGuidFromObject = (item: Object = {}) => R.path([GC.FIELD_DROPDOWN_OPTION_GUID], item);

const getBranchGuidFromObject = (item: Object = {}) => R.path([GC.FIELD_BRANCH_GUID], item);

const getValueFromObject = (item: Object = {}) => R.path([GC.FIELD_VALUE], item);

const getNameFromObject = (item: Object = {}) => R.path([GC.FIELD_NAME], item);

const getPropFromObject = (propName: string, item: Object = {}) => R.path([propName], item);

const getPropFromObject2 = (propName: string, item: Object = {}, defaultValue: any = null) => R.pathOr(
  defaultValue,
  [propName],
  item,
);

const getPropFromObjectsLeft = (propName: string, obj1: Object, obj2: Object) => {
  const prop1 = getPropFromObject(propName, obj1);
  const prop2 = getPropFromObject(propName, obj2);

  return R.or(prop1, prop2);
};

const getEventTargetValue = (event: Object) => R.path(['target', 'value'], event);

const getEventTargetName = (event: Object) => R.path(['target', 'name'], event);

const getEventTargetChecked = (event: Object) => R.path(['target', 'checked'], event);

export {
  getIdFromObject,
  getDataFromObject,
  getGuidFromObject,
  getPropFromObject,
  getNameFromObject,
  getValueFromObject,
  getPropFromObject2,
  getEventTargetName,
  getEventTargetValue,
  getEventTargetChecked,
  getLocationFromObject,
  getIdOrGuidFromObject,
  getPropFromObjectsLeft,
  getBranchGuidFromObject,
  getDisplayedValueFromObject,
  getDropdownOptionGuidFromObject,
  getPrimaryReferenceValueFromObject,
};
