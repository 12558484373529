import * as R from 'ramda';
import { pure, compose, withState, withHandlers, lifecycle } from 'react-recompose';
// helpers/constants
import * as G from '../helpers';
import * as GC from '../constants';
import { sendRequest } from '../utilities/http';
import endpointsMap from '../utilities/endpoints';
//////////////////////////////////////////////////

export const withAsyncRolesByUserType = ({ roleType }: Object) => compose(
  withState('rolesGuids', 'setRolesGuids', []),
  withHandlers({
    handleGetRolesByUserType: (props: Object) => async () => {
      const { setRolesGuids, currentBranchGuid } = props;
      const params = {
        [GC.BRANCH_GUID]: currentBranchGuid,
        [GC.FIELD_ROLE_ROLE_TYPE]: R.pathOr(roleType, [GC.FIELD_ROLE_ROLE_TYPE], props),
      };
      const res = await sendRequest('get', endpointsMap.availableRolesByType, { params });
      const { data, status } = res;
      if (G.isResponseSuccess(status)) {
        const options = R.map((item: Object) => ({
          value: R.prop(GC.FIELD_GUID, item),
          label: R.prop(GC.FIELD_NAME, item),
        }), R.or(data, []));
        setRolesGuids(options);
      } else {
        G.handleException('error', 'withAsyncRolesByUserType exception');
      }
    },
  }),
  lifecycle({
    componentDidMount() {
      this.props.handleGetRolesByUserType();
    },
  }),
  pure,
);
