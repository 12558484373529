import * as R from 'ramda';
//////////////////////////////////////////////////

/*
  This helper can measure execution time for running scripts
  It shows the calculated time after the batch of scripts execution was finished
  and the next task is taken from the queue.
*/
const checkPerformance = (
  identifier: string = '...',
  color: string = 'blue', //'DodgerBlue',
  fontSize: number = 15,
) => {
  // eslint-disable-next-line no-undef
  const beginTime = performance.now();

  setTimeout(() => {
    // eslint-disable-next-line no-undef
    const executionTime = R.subtract(performance.now(), beginTime);
    const message = `| Execution time for the <${identifier}>: ${executionTime.toFixed(0)} ms |`;

    const styles = `
      color: ${color};
      font-family: monospace;
      font-size: ${fontSize}px;
    `;

    const consoleMessage = `
      =============================================\n
      ${message}\n
      =============================================
    `;

    // eslint-disable-next-line no-console
    console.log(`%c${consoleMessage}`, styles);
  }, 0);
};

const startPerformanceLog = (name: string) => {
  console.log(`start ${name}`); // eslint-disable-line

  return performance.now(); // eslint-disable-line
};

const finishPerformanceLog = (name: string, startTime: number) => {
  console.log(`finish ${name}`); // eslint-disable-line

  const finishTime = performance.now(); // eslint-disable-line

  console.log(`Call took ${finishTime - startTime} milliseconds.`); // eslint-disable-line
};

export {
  checkPerformance,
  startPerformanceLog,
  finishPerformanceLog,
};
