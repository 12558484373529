import * as R from 'ramda';
import { connect } from 'react-redux';
import { pure, compose, withHandlers } from 'react-recompose';
// components
import { openLoader, closeLoader } from '../components/loader/actions';
// helpers/constants
import * as G from '../helpers';
import * as GC from '../constants';
import { asyncGetVinLookupConfig } from '../helpers/api-async';
//////////////////////////////////////////////////

const fieldsToPick1 = [
  'VIN',
  'Manufacturer',
  'Model',
  'ModelID',
  'ModelYear',
  'Note',
  'Make',
  'CurbWeightLB',
];

const fieldsToPick2 = [
  'year',
  'make',
  'model',
];

const mergeLookupResultWithValues = (item: Object, result: Object, props: Object) => {
  if (G.isNilOrEmpty(result)) return item;

  const { values, itemIndex, setFieldValue, updateItemDetails } = props;

  const resultValues = G.renameKeys({
    'Make': GC.FIELD_MAKE,
    'VIN': GC.FIELD_ITEM_ID,
    'Model': GC.FIELD_MODEL,
    'ModelYear': GC.FIELD_YEAR,
    'Note': GC.FIELD_DESCRIPTION,
    'ModelID': GC.FIELD_SUB_MODEL,
    'CurbWeightLB': GC.FIELD_ITEM_WEIGHT,
    'Manufacturer': GC.FIELD_MANUFACTURER,
  }, R.pick(fieldsToPick1, result));

  if (G.isFunction(updateItemDetails)) return updateItemDetails(resultValues);

  const newItem = R.mergeRight(item, resultValues);
  const items = R.prop(GC.FIELD_LOAD_ITEMS, values);
  const updatedItems = R.update(itemIndex, newItem, items);

  setFieldValue(GC.FIELD_LOAD_ITEMS, updatedItems);

  return newItem;
};

const mergeLookupResultWithValues2 = (item: Object, result: Object, props: Object) => {
  if (G.isNilOrEmpty(result)) return item;

  const { values, itemIndex, setFieldValue, updateItemDetails } = props;
  const { vin } = item;
  const { error, attributes } = result;

  if (G.isNotNilAndNotEmpty(error)) return G.showToastrMessageSimple('error', error);

  const weight = R.trim(R.head(R.split(' ', R.pathOr('', ['curb_weight'], attributes))));
  const wheelbase = R.trim(R.head(R.split(' ', R.pathOr('', ['wheelbase_length'], attributes))));
  const resultValues = R.compose(
    R.mergeRight({
      [GC.FIELD_ITEM_ID]: vin,
      [GC.FIELD_ITEM_WEIGHT]: weight,
      [GC.FIELD_WHEELBASE]: wheelbase,
      [GC.FIELD_WHEELBASE_UOM]: GC.UOM_INCH,
      [GC.FIELD_ITEM_WEIGHT_TYPE]: GC.UOM_POUND,
    }),
    G.renameKeys({
      'make': GC.FIELD_MAKE,
      'model': GC.FIELD_MODEL,
    }),
  )(R.pick(fieldsToPick2, attributes));

  if (G.isFunction(updateItemDetails)) return updateItemDetails(resultValues);

  const newItem = R.mergeRight(item, resultValues);
  const items = R.prop(GC.FIELD_LOAD_ITEMS, values);
  const updatedItems = R.update(itemIndex, newItem, items);

  setFieldValue(GC.FIELD_LOAD_ITEMS, updatedItems);

  return newItem;
};

export const withVinLookup = () => compose(
  connect(null, { openLoader, closeLoader }),
  withHandlers({
    handleVinLookup: (props: Object) => async (item: Object, withOrders: any) => {
      const { openLoader, closeLoader, branchGuid, handleGetOrdersByVin } = props;

      try {
        const { vin } = item;

        if (G.isNilOrEmpty(vin)) {
          return G.showToastrMessageSimple(
            'info',
            G.getWindowLocale('messages:fill-vin', 'Please, fill the vin field'),
          );
        }

        openLoader();

        const branchGuidToUse = R.or(branchGuid, G.getAmousCurrentUserBranchGuidFromWindow());

        const vinLookupConfig = await asyncGetVinLookupConfig(branchGuidToUse);

        const apiKey = G.getPropFromObject(GC.FIELD_API_KEY, vinLookupConfig);

        if (G.isNotNilAndNotEmpty(apiKey)) {
          const endpoint = `https://specifications.vinaudit.com/v3/specifications?key=${apiKey}&vin=${vin}`;

          fetch(endpoint) // eslint-disable-line
            .then((res: Object) => res.json())
            .then((data: Object) => {
              const newItem = mergeLookupResultWithValues2(item, data, props);

              if (G.isTrue(withOrders)) handleGetOrdersByVin(newItem);

              closeLoader();
            });

          return;
        }

        const endpoint = `https://vpic.nhtsa.dot.gov/api/vehicles/DecodeVinValuesExtended/${vin}?format=json`;
        fetch(endpoint) // eslint-disable-line
          .then((res: Object) => res.json())
          .then((data: Object) => {
            const result = R.path(['Results', 0], data);

            const newItem = mergeLookupResultWithValues(item, result, props);

            if (G.isTrue(withOrders)) handleGetOrdersByVin(newItem);

            closeLoader();
          });
      } catch (error) {
        closeLoader();
        G.handleException(error, 'withVinLookup -> handleVinLookup exception');
        G.showToastrMessageSimple('error', G.getWindowLocale('messages:check-vin', 'Please, check the vin field'));
      }
    },
  }),
  pure,
);

const pushLookupResultToItems1 = (result: Object, props: Object) => {
  const { vin, push, getNewItemFields } = props;

  if (G.isNilOrEmpty(result)) return;

  const resultValues = R.compose(
    R.mergeRight({
      [GC.FIELD_VIN]: vin,
    }),
    G.renameKeys({
      'Make': GC.FIELD_MAKE,
      'VIN': GC.FIELD_ITEM_ID,
      'Model': GC.FIELD_MODEL,
      'ModelYear': GC.FIELD_YEAR,
      'Note': GC.FIELD_DESCRIPTION,
      'ModelID': GC.FIELD_SUB_MODEL,
      'CurbWeightLB': GC.FIELD_ITEM_WEIGHT,
      'Manufacturer': GC.FIELD_MANUFACTURER,
    }),
  )(R.pick(fieldsToPick1, result));

  const newItem = R.mergeRight(getNewItemFields(G.getItemFromWindow('amousNewDoBranchConfigs')), resultValues);

  push(newItem);
};

const pushLookupResultToItems2 = (result: Object, props: Object) => {
  const { vin, push, getNewItemFields } = props;
  const { attributes } = result;

  if (G.isNilOrEmpty(attributes)) return;

  const weight = R.trim(R.head(R.split(' ', R.pathOr('', ['curb_weight'], attributes))));
  const wheelbase = R.trim(R.head(R.split(' ', R.pathOr('', ['wheelbase_length'], attributes))));
  const resultValues = R.compose(
    R.mergeRight({
      [GC.FIELD_VIN]: vin,
      [GC.FIELD_ITEM_ID]: vin,
      [GC.FIELD_ITEM_WEIGHT]: weight,
      [GC.FIELD_WHEELBASE]: wheelbase,
      [GC.FIELD_WHEELBASE_UOM]: GC.UOM_INCH,
      [GC.FIELD_ITEM_WEIGHT_TYPE]: GC.UOM_POUND,
    }),
    G.renameKeys({
      'make': GC.FIELD_MAKE,
      'model': GC.FIELD_MODEL,
    }),
  )(R.pick(fieldsToPick2, attributes));

  const newItem = R.mergeRight(getNewItemFields(G.getItemFromWindow('amousNewDoBranchConfigs')), resultValues);

  push(newItem);
};

export const handleMultiItemsVinLookup = async (props: any) => {
  const { vin, branchGuid } = props;

  try {
    if (G.isNilOrEmpty(vin)) {
      return G.showToastrMessageSimple(
        'info',
        G.getWindowLocale('messages:fill-vin', 'Please, fill the vin field'),
      );
    }

    const branchGuidToUse = R.or(branchGuid, G.getAmousCurrentUserBranchGuidFromWindow());

    const vinLookupConfig = await asyncGetVinLookupConfig(branchGuidToUse);

    const apiKey = G.getPropFromObject(GC.FIELD_API_KEY, vinLookupConfig);

    if (G.isNotNilAndNotEmpty(apiKey)) {
      const endpoint = `https://specifications.vinaudit.com/v3/specifications?key=${apiKey}&vin=${vin}`;

      fetch(endpoint) // eslint-disable-line
        .then((res: Object) => res.json())
        .then((data: Object) => {
          pushLookupResultToItems2(data, props);
        });

      return;
    }

    const endpoint = `https://vpic.nhtsa.dot.gov/api/vehicles/DecodeVinValuesExtended/${vin}?format=json`;
    fetch(endpoint) // eslint-disable-line
      .then((res: Object) => res.json())
      .then((data: Object) => {
        const result = R.path(['Results', 0], data);
        pushLookupResultToItems1(result, props);
      });
  } catch (error) {
    G.handleException(error, 'withVinLookup -> handleMultiItemsVinLookup exception');
    G.showToastrMessageSimple('error', G.getWindowLocale('messages:check-vins', 'Please, check the vins'));
  }
};
