const toLocaleStringSupportsLocales = () => (
  typeof Intl === 'object' &&
  !!Intl &&
  typeof Intl.NumberFormat === 'function'
);

const toLocaleString = (value: any) => {
  if (toLocaleStringSupportsLocales()) return value.toLocaleString();

  return value;
};

const fromUndefinedToNull = (value: any) => {
  if (value === undefined) return null;

  return value;
};

// math

const roundToNearest = (number: number, nearest: number) => Math.round(number / nearest) * nearest;

const getOrderOfMagnitude = (number: number) => {
  if (number === 0) return 0;

  const absNumber = Math.abs(number);

  return Math.floor(Math.log10(absNumber));
};

//

const isNullUndefinedOrEmptyString = (value: any) => value === null || value === undefined || value === '';

export {
  toLocaleString,
  fromUndefinedToNull,
  //
  roundToNearest,
  getOrderOfMagnitude,
  //
  isNullUndefinedOrEmptyString,
};
