import { connect } from 'react-redux';
import { pure, compose, withProps } from 'react-recompose';
// components
import { openModal, closeModal } from '../components/modal/actions';
import { openLoader, closeLoader } from '../components/loader/actions';
// helpers/constants
import * as G from '../helpers';
import * as GC from '../constants';
// hocs
import { withAsyncConfigs } from './with-async-configs';
//////////////////////////////////////////////////

export const withReasonCodeConfigs = compose(
  connect(null, { openLoader, closeLoader, openModal, closeModal }),
  withProps(({ branchGuid }: Object) => {
    const configsNamesArray = [
      GC.COMMUNICATION_REASON_CODE,
      GC.COMMUNICATION_DISPATCH_DECLINE_REASON_CODE,
    ];
    return {
      branchGuid,
      configsNamesArray,
    };
  }),
  withAsyncConfigs,
  withProps(({ asyncConfigs }: Object) => {
    if (G.isNilOrEmpty(asyncConfigs)) return;
    const declineLoadReasons = G.createOptionsFromDropdownConfigWithGuidOrParentGuid(
      asyncConfigs,
      GC.COMMUNICATION_DISPATCH_DECLINE_REASON_CODE,
    );
    return {
      declineLoadReasons,
    };
  }),
  pure,
);
