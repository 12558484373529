import htmlToDraft from 'html-to-draftjs';
import draftToHtml from 'draftjs-to-html';
import { EditorState, ContentState, convertToRaw } from 'draft-js';
// helpers
import { isNilOrEmpty } from './helpers';
//////////////////////////////////////////////////

const createEditorState = (value: string) => {
  if (isNilOrEmpty(value)) return EditorState.createEmpty();

  const blocksFromHTML = htmlToDraft(value);

  const { entityMap, contentBlocks } = blocksFromHTML;

  const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);

  return EditorState.createWithContent(contentState);
};

const convertHtmlToString = (editorState: Object) => draftToHtml(convertToRaw(editorState.getCurrentContent()));

const moveFocusToEnd = (editorState: Object) => {
  const newEditorState = EditorState.moveSelectionToEnd(editorState);

  return EditorState.forceSelection(newEditorState, newEditorState.getSelection());
};

export {
  moveFocusToEnd,
  createEditorState,
  convertHtmlToString,
};
