import * as R from 'ramda';
import * as P from 'plow-js';
import { connect } from 'react-redux';
import React, { Component } from 'react';
// components
import { closeModal } from '../components/modal/actions';
import { openLoader, closeLoader } from '../components/loader/actions';
// helpers/constants
import * as G from '../helpers';
// utilities
import { sendRequest } from '../utilities/http';
//////////////////////////////////////////////////

type asyncRequestOptions = {
  data?: Object,
  params?: Object,
  method?: string,
  successMsg?: string,
  showFailMsg?: boolean,
  successCallback?: void,
  shouldCloseModal?: boolean,
  shouldOpenLoader?: boolean,
};

const defaultOptions = {
  method: 'post',
  successMsg: null,
  showFailMsg: true,
  shouldCloseModal: true,
  shouldOpenLoader: true,
};

class AsyncRequest extends Component {
  constructor(props: Object) {
    super(props);
    this.state = { data: null, error: null, loading: true };
    this.makeAsyncRequest = this.makeAsyncRequest.bind(this);
  }
  async makeAsyncRequest(endpoint: string, options: asyncRequestOptions) {
    if (G.isNilOrEmpty(endpoint)) return;
    const { closeModal, openLoader, closeLoader } = this.props;
    const mergedOptions = R.mergeRight(defaultOptions, options);
    const { method, successMsg, showFailMsg, successCallback, shouldCloseModal, shouldOpenLoader } = mergedOptions;
    if (G.isTrue(shouldOpenLoader)) openLoader();
    try {
      const res = await sendRequest(method, endpoint, R.pick(['data', 'params'], mergedOptions));
      const { data, status } = res;
      if (G.isResponseSuccess(status, data, successMsg)) {
        if (G.isNotNil(data)) {
          const newState = P.$all(
            P.$set('data', data),
            P.$set('loading', false),
            this.state,
          );
          this.setState(newState);
          if (G.isFunction(successCallback)) successCallback(res);
        }
        if (G.isTrue(shouldCloseModal)) closeModal();
      } else {
        G.handleFailResponseSimple(
          res,
          showFailMsg,
          'withAsyncRequest -> withAsyncRequest',
        );
      }
      if (G.isTrue(shouldOpenLoader)) closeLoader();
    } catch (error) {
      if (G.isTrue(shouldOpenLoader)) closeLoader();
      G.handleException(error, 'withAsyncRequest');
    }
  }
  render() {
    return <div>{this.props.render(this.state, this.makeAsyncRequest)}</div>;
  }
}

const AsyncRequestWithStore = connect(null, { closeModal, openLoader, closeLoader })(AsyncRequest);

export const withAsyncRequest = (Component: any) => (
  class extends React.Component {
    render() {
      return (
        <AsyncRequestWithStore
          openLoader={this.props.openLoader}
          closeModal={this.props.closeModal}
          closeLoader={this.props.closeLoader}
          render={(parentState: Object, makeAsyncRequest: Function) =>
            <Component {...this.props} asyncRequestState={parentState} makeAsyncRequest={makeAsyncRequest} />
          } />
      );
    }
  }
);
