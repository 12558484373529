import * as R from 'ramda';
// constants
import * as GC from '../constants';
// helpers
import * as V from './validators';
import { toNumber } from './calc';
import { getWindowLocale } from './locale';
import { getPropFromObject } from './getter';
import { createLocalDateTimeString } from './date';
import { showToastrMessageSimple } from './toastr';
import {
  isTrue,
  ifElse,
  isFalse,
  isString,
  isObject,
  isAllTrue,
  isAllNilOrEmpty,
  isAnyNilOrEmpty,
  isNotNilAndNotEmpty,
} from './helpers';
//////////////////////////////////////////////////

// TODO: we do not import all as V
// helper for creating form Field validate prop from strings array
const createFieldValidate = (validators: Array) => {
  const validate = [];

  if (Array.isArray(validators)) {
    R.forEach((item: string) => {
      validate.push(V[item]);
    }, validators);
  }

  return validate;
};

// helper for change redux-form store field
const changeStoreField = (
  change: Function,
  formSection: string,
  fieldName: string,
  value: string,
  withFormName: boolean = false,
) => {
  const fieldToSet = ifElse(
    R.and(formSection, withFormName),
    `${formSection}.${fieldName}`,
    fieldName,
  );

  return change(fieldToSet, value);
};

// helper for set form field disabled
const showFormField = (condition: any, args: any) => {
  if (R.is(Function, condition)) {
    return condition(args);
  }

  return true;
};

const setInitialFormikValues = (
  defaultValues: any,
  initialValues: any,
  searchedValues: any,
) => {
  let values = defaultValues;

  if (isNotNilAndNotEmpty(initialValues)) {
    values = R.mergeRight(values, initialValues);
  }

  if (isNotNilAndNotEmpty(searchedValues)) {
    values = R.mergeRight(values, searchedValues);
  }

  return values;
};

const getFormikPropsToFieldset = (props: Object) => R.pick([
  'values',
  'errors',
  'touched',
  'setValues',
  'handleBlur',
  'handleChange',
  'setFieldError',
  'setFieldValue',
  'setFieldTouched',
], props);

const getFormikProps = (props: Object) => (
  R.pick(
    [
      'dirty',
      'status',
      'errors',
      'values',
      'touched',
      'isValid',
      'setError',
      'setStatus',
      'setErrors',
      'setValues',
      'resetForm',
      'submitForm',
      'handleBlur',
      'setTouched',
      'handleReset',
      'handleSubmit',
      'handleChange',
      'isSubmitting',
      'validateForm',
      'initialValues',
      'setFieldError',
      'setFieldValue',
      'setSubmitting',
      'validateOnBlur',
      'setFormikState',
      'setFieldTouched',
      'validateOnChange',
    ],
    props,
  )
);

const getArrayFormikProps = R.compose(
  R.assoc('fieldsetType', 'array'),
  R.pick(
    [
      'itemIndex',
      'arrayLength',
      // formik array props
      'pop',
      'form',
      'move',
      'push',
      'swap',
      'insert',
      'remove',
      'unshift',
    ],
  ),
);

const setDefaultFieldsToRateInvoiceFormik = (options: Object) => {
  const {
    mode,
    serviceType,
    customerTotal,
    defaultStatus,
    defaultCurrency,
    defaultUomFields,
    formikInitFields,
  } = options;

  let fields = R.pick([GC.FIELD_TOTAL_TRIP_DISTANCE_UOM, GC.FIELD_TOTAL_TRIP_WEIGHT_UOM], defaultUomFields);

  const totals = R.values(
    R.pick(['totalVolume', 'totalWeight', 'totalDistance', 'totalPickupQuantity'],
    options,
  ));

  R.forEach(
    (value: Object) => {
      if (isNotNilAndNotEmpty(value)) fields = R.mergeRight(fields, value);
    },
    totals,
  );

  return R.mergeAll([
    formikInitFields,
    fields,
    {
      [GC.FIELD_MODE]: mode,
      [GC.FIELD_CURRENCY]: defaultCurrency,
      [GC.FIELD_SERVICE_TYPE]: serviceType,
      [GC.FIELD_INVOICE_STATUS]: defaultStatus,
      [GC.FIELD_CUSTOMER_TOTAL]: customerTotal,
    },
  ]);
};

const setDefaultFieldsToRateInvoiceFormik2 = (options: Object) => {
  const {
    totalWeight,
    totalVolume,
    totalDistance,
    defaultStatus,
    defaultCurrency,
    defaultUomFields,
    formikInitFields,
  } = options;

  let fields = R.pick([GC.FIELD_TOTAL_TRIP_DISTANCE_UOM, GC.FIELD_TOTAL_TRIP_WEIGHT_UOM], defaultUomFields);

  if (isNotNilAndNotEmpty(totalDistance)) {
    fields = R.mergeRight(fields, totalDistance);
  }

  if (isNotNilAndNotEmpty(totalWeight)) {
    fields = R.mergeRight(fields, totalWeight);
  }

  if (isNotNilAndNotEmpty(totalVolume)) {
    fields = R.mergeRight(fields, totalVolume);
  }

  return R.mergeAll([
    formikInitFields,
    fields,
    {
      [GC.FIELD_CURRENCY]: defaultCurrency,
      [GC.FIELD_INVOICE_STATUS]: defaultStatus,
    },
  ]);
};

const setDefaultFieldsToRateInvoiceAsyncFormik = (options: Object) => {
  const {
    mode,
    services,
    currency,
    equipment,
    serviceType,
    defaultUomFields,
    formikInitFields,
  } = options;

  let fields = R.pick([GC.FIELD_TOTAL_TRIP_DISTANCE_UOM, GC.FIELD_TOTAL_TRIP_WEIGHT_UOM], defaultUomFields);

  const totals = R.values(R.pick(['totalVolume', 'totalWeight', 'totalDistance', 'totalPickupQuantity'], options));

  R.forEach(
    (value: Object) => {
      if (isNotNilAndNotEmpty(value)) fields = R.mergeRight(fields, value);
    },
    totals,
  );

  const mergedValues = R.mergeRight(formikInitFields, fields);

  return {
    ...mergedValues,
    services,
    equipment,
    [GC.FIELD_MODE]: mode,
    [GC.FIELD_CURRENCY]: currency,
    [GC.FIELD_SERVICE_TYPE]: serviceType,
  };
};

const setInitialLocationFormikValues = (defaultVal: any, initialVal: any, searchedVal: any) => {
  if (isNotNilAndNotEmpty(searchedVal)) {
    if (isNotNilAndNotEmpty(initialVal)) {
      return R.mergeRight(R.mergeRight(defaultVal, initialVal), searchedVal);
    }

    return R.mergeRight(defaultVal, searchedVal);
  }

  if (isNotNilAndNotEmpty(initialVal)) {
    return R.mergeRight(defaultVal, initialVal);
  }

  return defaultVal;
};

const setFieldsGroupDisplay = (values: Object, fieldName: string, initValues: Object, options: Object) => {
  let display = R.pathOr('flex', ['defaultDisplay'], options);

  if (isNotNilAndNotEmpty(values[fieldName])) display = 'none';

  if (isNotNilAndNotEmpty(initValues[fieldName])) display = 'none';

  return display;
};

const setFieldType = (value: string) => {
  let type = value;

  if (R.equals(value, 'toggle')) {
    type = 'checkbox';
  } else if (R.equals(value, 'calendar')) {
    type = 'text';
  }
  return type;
};

const shouldMapValue = (key: any, value: any, fieldsToMap: Array) => isAllTrue(
  R.includes(key, fieldsToMap),
  isString(value),
  R.isEmpty(value),
);

const mapFormFieldsToNull = (fieldsToMap: Array) => R.mapObjIndexed(
  (value: any, key: string) => ifElse(
    shouldMapValue(key, value, fieldsToMap),
    null,
    value,
  ),
);

// TODO: move to feature or validator; use this validation on dispatch board and tel details
const validateAppointmentsFormValues = (values: Object) => {
  const timeFormat = 'h:mm A';

  const fieldsArr = R.compose(
    R.values(),
    R.pickAll([
      GC.FIELD_LOAD_APPOINTMENT_DATE,
      GC.FIELD_LOAD_APPOINTMENT_EARLY_TIME,
      GC.FIELD_LOAD_APPOINTMENT_LATE_TIME,
    ]),
  )(values);

  const someNil = isAnyNilOrEmpty(fieldsArr);
  const allNil = isAllNilOrEmpty(fieldsArr);

  if (R.and(isFalse(allNil), isTrue(someNil))) {
    showToastrMessageSimple(
      'info',
      getWindowLocale(
        'messages:warning-message-3',
        'Please, check appointments datetime fields. If you fill one of them another should be filled',
      ),
    );

    return false;
  }

  const data = { ...values };

  if (isObject(R.prop(GC.FIELD_LOAD_APPOINTMENT_EARLY_TIME, data))) {
    data.appointmentEarlyTime = createLocalDateTimeString(
      R.prop(GC.FIELD_LOAD_APPOINTMENT_EARLY_TIME, data), timeFormat,
    );
  }

  if (isObject(R.prop(GC.FIELD_LOAD_APPOINTMENT_LATE_TIME, data))) {
    data.appointmentLateTime = createLocalDateTimeString(
      R.prop(GC.FIELD_LOAD_APPOINTMENT_LATE_TIME, data),
      timeFormat,
    );
  }

  return {
    ...data,
    [GC.FIELD_LOAD_TYPE]: R.toUpper(values.loadType),
    [GC.FIELD_LOAD_APPOINTMENT_LATE_TIME]: R.toUpper(R.pathOr(
      '',
      [GC.FIELD_LOAD_APPOINTMENT_LATE_TIME],
      data,
    )),
    [GC.FIELD_LOAD_APPOINTMENT_EARLY_TIME]: R.toUpper(R.pathOr(
      '',
      [GC.FIELD_LOAD_APPOINTMENT_EARLY_TIME],
      data,
    )),
  };
};

const setCorrectTimeInitialValue = (value: any) => {
  if (isNotNilAndNotEmpty(value)) {
    let newValue = value;
    const splittedHead = R.head(R.split(':', value));

    if (R.equals(2, R.length(splittedHead))) return value;

    const splittedHeadNumber = toNumber(splittedHead);

    if (R.lt(splittedHeadNumber, 10)) {
      newValue = R.replace(splittedHead, `${0}${splittedHead}`, value);
    }

    return R.toUpper(newValue);
  }

  return value;
};

export {
  setFieldType,
  showFormField,
  getFormikProps,
  changeStoreField,
  mapFormFieldsToNull,
  createFieldValidate,
  getArrayFormikProps,
  setFieldsGroupDisplay,
  setInitialFormikValues,
  getFormikPropsToFieldset,
  setCorrectTimeInitialValue,
  setInitialLocationFormikValues,
  validateAppointmentsFormValues,
  setDefaultFieldsToRateInvoiceFormik,
  setDefaultFieldsToRateInvoiceFormik2,
  setDefaultFieldsToRateInvoiceAsyncFormik,
};
