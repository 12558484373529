import * as R from 'ramda';
import React from 'react';
import { compose, withHandlers } from 'react-recompose';
// features
import { AsyncEditCarrierInvoiceForm } from '../features/invoice/components/carrier-form';
// helpers/constants
import * as G from '../helpers';
import * as GC from '../constants';
// utilities
import endpointsMap from '../utilities/endpoints';
//////////////////////////////////////////////////

export const withAsyncEditCarrierInvoice = (options: any) => compose(
  withHandlers({
    handleUpdateCarrierInvoice: (props: Object) => (values: Object) => {
      const {
        entity,
        updateCarrierInvoiceRequest,
        updateCIReconciliationRequest,
        updateLoadCarrierInvoiceRequest,
      } = props;

      const updateFrom = G.getPropFromObject('updateFrom', options);

      if (R.and(
        R.equals(updateFrom, GC.FIELD_CARRIER_EDI),
        G.isFunction(updateCarrierInvoiceRequest),
      )) {
        return updateCarrierInvoiceRequest({ entity, invoice: values });
      }

      if (R.and(
        R.equals(updateFrom, GC.PAGE_DISPATCH_DETAILS_NEW_LOAD),
        G.isFunction(updateLoadCarrierInvoiceRequest),
      )) {
        return updateLoadCarrierInvoiceRequest(values);
      }

      updateCIReconciliationRequest(values);
    },
  }),
  withHandlers({
    handleEditCarrierInvoice: (props: Object) => (invoice: Object) => {
      const { load, openModal, closeModal, handleUpdateCarrierInvoice } = props;

      const guid = R.or(
        G.getPropFromObject(GC.GRC.CREATED_INVOICE_INVOICE_GUID, invoice),
        G.getGuidFromObject(invoice),
      );

      const updateFrom = G.getPropFromObject('updateFrom', options);

      const totalQuantities = 0;
      const totalCustomersRate = G.getTotalCustomersRate(props, updateFrom);
      const stopsQuantity = R.length(R.pathOr([], [GC.FIELD_LOAD_STOPS], load));

      const branchGuid = R.or(
        R.pathOr(G.getBranchGuidFromObject(invoice), [GC.FIELD_BRANCH_GUID], props),
        G.getBranchGuidFromObject(load),
      );

      const totalWeight = R.pick(
        [GC.FIELD_TOTAL_TRIP_WEIGHT, GC.FIELD_TOTAL_TRIP_WEIGHT_UOM],
        invoice,
      );

      const totalDistance = R.pick(
        [GC.FIELD_TOTAL_TRIP_DISTANCE, GC.FIELD_TOTAL_TRIP_DISTANCE_UOM],
        invoice,
      );

      const modalContent = (
        <AsyncEditCarrierInvoiceForm
          tel={load}
          initialValues={invoice}
          closeModal={closeModal}
          branchGuid={branchGuid}
          totalWeight={totalWeight}
          totalDistance={totalDistance}
          stopsQuantity={stopsQuantity}
          totalQuantities={totalQuantities}
          totalCustomersRate={totalCustomersRate}
          asyncEndpoint={endpointsMap.getCarrierInvoice(guid)}
          handleSendCarrierInvoice={handleUpdateCarrierInvoice}
        />
      );

      const modal = {
        p: '0',
        component: modalContent,
        options: {
          width: 1100,
          height: 'auto',
          overflow: 'auto',
          maxHeight: '90vh',
        },
      };

      openModal(modal);
    },
  }),
);
