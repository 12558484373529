import * as R from 'ramda';
// constants
import * as GC from '../constants';
// helpers
import {
  isObject,
  isString,
  isFunction,
  isNilOrEmpty,
  isNotNilAndNotEmpty,
} from './helpers';
import { getPropFromObject } from './getter';
//////////////////////////////////////////////////

const getConfigValueFromStore = (
  configName: string, configStore: Object, orValue: string = '',
) => {
  const value = R.path(['configs', configName, 'value'], configStore);

  if (isNotNilAndNotEmpty(value)) return value;

  return R.pathOr(
    orValue,
    ['configs', configName, 'inheritedValue'],
    configStore,
  );
};

const quickClone = (obj: Object) => {
  // Try to clone object with JSON.parse
  // because for complicated objects Ramda.clone
  // can take few seconds
  let clone;

  try {
    clone = JSON.parse(JSON.stringify(obj));
  } catch (error) {
    clone = R.clone(obj);
  }

  return clone;
};

function setDebounce(func: Function, wait: number) {
  let timeout;

  return function(...args) { // eslint-disable-line
    const context = this;

    clearTimeout(timeout);

    timeout = setTimeout(() => func.apply(context, args), wait);
  };
}

const deepEquals = (obj1: Object, obj2: Object) => {
  let areEqual = false;

  try {
    areEqual = R.equals(JSON.stringify(obj1), JSON.stringify(obj2));
  } catch (error) {
    console.error(`Can't compare equality! Arguments are too complicated. \n${error}`);
  }

  return areEqual;
};

const stopPropagation = (event: Event) => {
  if (isNotNilAndNotEmpty(event) && isFunction(event.stopPropagation)) {
    event.stopPropagation();
  }
};

const getKeysWithEmptyValues = (itemList: Array) => R.compose(
  (itemKeys: Array) => R.reduce(R.intersection, R.head(itemKeys), itemKeys),
  R.map(R.compose(R.keys, R.pickBy(isNilOrEmpty))),
)(itemList);

export {
  deepEquals,
  quickClone,
  setDebounce,
  stopPropagation,
  getKeysWithEmptyValues,
  getConfigValueFromStore,
};
