import * as R from 'ramda';
import { connect } from 'react-redux';
import { pure, compose, withState, lifecycle, withHandlers } from 'react-recompose';
// components
import { openLoader, closeLoader } from '../components/loader/actions';
// helpers/constants
import * as G from '../helpers';
import * as GC from '../constants';
// utilities
import { sendRequest } from '../utilities/http';
import endpointsMap from '../utilities/endpoints';
//////////////////////////////////////////////////

// TODO: check branchGuid from props, check set state, check cache and sync
export const withAsyncBranchConfigsByNames = (settings: Object) => compose(
  connect(null, { openLoader, closeLoader }),
  withState('asyncBranchConfigs', 'setAsyncBranchConfigs', null),
  withHandlers({
    getAsyncBranchConfigsByNames: (props: Object) => async () => {
      const { branchGuid, setAsyncBranchConfigs } = props;
      const { configs, withLoader, omitReceived } = settings;

      const currentBranchGuid = G.getAmousCurrentBranchGuidFromWindow();
      const branchGuidToUse = R.or(branchGuid, currentBranchGuid);

      if (G.isNilOrEmpty(branchGuidToUse)) return;

      const branchReceivedConfigs = G.getAmousBranchReceivedConfigsFromWindow(branchGuidToUse);
      const configsToUse = G.ifElse(
        G.isTrue(omitReceived),
        configs,
        R.without(branchReceivedConfigs, configs),
      );
      const names = R.join(',', configsToUse);

      if (G.isNilOrEmpty(configsToUse)) return;

      if (G.isTrue(withLoader)) openLoader();

      const options = {
        params: {
          names,
          [GC.FIELD_BRANCH_GUID]: branchGuidToUse,
        },
      };

      const res = await sendRequest('get', endpointsMap.branchConfigsEndpoint, options);

      const { data, status } = res;

      if (G.isResponseSuccess(status)) {
        const mappedConfigs = G.mapConfigValuesByName(data);
        setAsyncBranchConfigs(mappedConfigs);
        G.setAmousBranchConfigsToWindow(branchGuidToUse, { mappedConfigs, receivedConfigs: configsToUse });

        if (G.notEquals(branchGuid, currentBranchGuid)) {
          G.setAmousBranchConfigsToWindow(currentBranchGuid, { mappedConfigs, receivedConfigs: configsToUse });
        }
      } else {
        G.handleException('withAsyncBranchConfigsByNames exception');
      }

      if (G.isTrue(withLoader)) closeLoader();
    },
  }),
  lifecycle({
    componentDidMount() {
      this.props.getAsyncBranchConfigsByNames();
    },
  }),
  pure,
);
