import * as R from 'ramda';
import {
  pure,
  compose,
  lifecycle,
  withState,
  withHandlers,
} from 'react-recompose';
// helpers/constants
import * as G from '../helpers';
//////////////////////////////////////////////////

const withLoadingState = compose(
  withState('loadingState', 'setLoadingState', false),
  withHandlers({
    openLoadingState: ({ setLoadingState }: Object) => () => setLoadingState(true),
    closeLoadingState: ({ setLoadingState }: Object) => () => setLoadingState(false),
  }),
);

const withComponentDidUpdatePropCallback = ({ propName, callbackName }: Object) => compose(
  lifecycle({
    componentDidUpdate(prevProps: Object) {
      if (G.isNilOrEmpty(propName)) return;

      const props = this.props;
      const callback = R.path([callbackName], props);

      if (R.or(G.isNilOrEmpty(callbackName), R.not(G.isFunction(callback)))) return;

      const currentProp = R.path([propName], props);
      const prevProp = R.path([propName], prevProps);

      if (G.notEquals(currentProp, prevProp)) {
        callback(propName, props, prevProps);
      }
    },
  }),
  pure,
);

export {
  withLoadingState,
  withComponentDidUpdatePropCallback,
};
